import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";
import { all_routes } from "../../../router/all_routes";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactApexChart from "react-apexcharts";
import CircleProgressSmall from "../../shared/employeeeDashboardUI/circleProgressSmall";
import CircleProgress from "../../shared/employeeeDashboardUI/circleProgress";
import { Calendar } from 'primereact/calendar';
import { DatePicker, message, Modal, Button, Result, Spin } from "antd";
import CommonSelect from "../../../core/common/commonSelect";
import CollapseHeader from "../../../core/common/collapse-header/collapse-header";
import dailyRecordService from "../../../services/dailyRecord/dailyRecord.service";
import { DailyRecordControl } from "../../../types/attendanceDto.types";
import Swal from 'sweetalert2';
import excuseService from "../../../services/excuse/excuse.service";
import breakService from "../../../services/break/break.service";
import { BreakType } from "../../../types/break.types";
import leaveService from "../../../services/leave/leave.service";
import { LeaveUserUpdateDTO, UserYearlyLeaveSummary } from "../../../types/leaves.types";
import { User, WorkScheduleResponseDto, YearlyDetailsForEmployeeResponseDto, YearlyWorkStatsResponseDto } from "../../../types/user.types";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";
import userService from "../../../services/user/user.service";
import { CheckCircleFilled, CloseCircleFilled, ExclamationCircleFilled, ClockCircleFilled } from '@ant-design/icons';
import "./style/style.css";
import { ApiError } from "../../../types/error.types";
import { formatDate } from "../../../utils/date";
import EmployeeLeaveModal from "../../shared/modals/employeeLeaveModal2";
import { handleApiError } from "../../../utils/errorHandler";
import { useTranslation } from "react-i18next";
import FooterSection from "../../shared/components/footerSection";
const EmployeeDashboard = () => {
  const {t} = useTranslation();

  const routes = all_routes;
  const [leavesChart, setLeavesChart] = useState<any>({
    series: [0, 0, 0, 0],
    chart: {
      height: 165,
      type: 'donut',
      toolbar: {
        show: false,
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50%'
      },
    },
    dataLabels: {
      enabled: false
    },
    colors: ['#F26522', '#2f09d6', '#E70D0D', '#03C95A'],
    labels: [t('employeeDashboard.workDays'), t('employeeDashboard.absentDays'), t('employeeDashboard.lateDays'), t('employeeDashboard.leaveDays')],
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          show: false
        }
      }
    }],
    legend: {
      show: false
    }
  });
  const { checkIn, checkOut, getDailyRecordControl, getLateIn } = dailyRecordService;
  const { createExcuse } = excuseService;
  const [dailyRecordControl, setDailyRecordControl] = useState<DailyRecordControl>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);  
  const [errorMessage, setErrorMessage] = useState("");
  const { startBreak, endBreak } = breakService;
  const {getYearlyDetails,getYearlyWorkStats,getWorkSchedule,getUserById} = userService
  const {getUserYearlyLeaveDetails} = leaveService;
  const [leaveSummary, setLeaveSummary] = useState<UserYearlyLeaveSummary>();
  const [yearlyDetails, setYearlyDetails] = useState<YearlyDetailsForEmployeeResponseDto>();
  const [isBreakActive, setIsBreakActive] = useState(false);
  const [breakDuration, setBreakDuration] = useState<number | null>(0);
  const [breakStartTime, setBreakStartTime] = useState<string | null>(null);
  const [breakRemaining, setBreakRemaining] = useState<number | null>(null);
  const [workTime, setWorkTime] = useState<number>(0);
  const [officeEntryTime, setOfficeEntryTime] = useState<string | null>(null);
  const {user} = useSelector((state: RootState) => state.auth);
  const [yearlyWorkStats, setYearlyWorkStats] = useState<YearlyWorkStatsResponseDto>();
  const [workSchedule, setWorkSchedule] = useState<WorkScheduleResponseDto>();
  const [userInfo, setUserInfo] = useState<User>();
  const [showBreakTypeModal, setShowBreakTypeModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // Modal state'leri
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showResultModal, setShowResultModal] = useState(false);
  const [showExcuseModal, setShowExcuseModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalAction, setModalAction] = useState<'checkIn' | 'checkOut' | 'startBreak' | 'endBreak' | null>(null);
  const [modalResult, setModalResult] = useState<'success' | 'error'>('success');
  const [modalMessage, setModalMessage] = useState('');
  const [excuseReason, setExcuseReason] = useState('');
  const [workProgress, setWorkProgress] = useState(0);
  const [breakProgress, setBreakProgress] = useState(0);
  const [currentWorkTime, setCurrentWorkTime] = useState('00:00:00');
  const [currentBreakTime, setCurrentBreakTime] = useState('00:00');
  const [totalBreakTime, setTotalBreakTime] = useState('00:00');
  const handleStartEndBreak = async () => {
    if (isBreakActive && dailyRecordControl?.isPresent) {
      // Mola bitirme için onay modalı
      setModalAction('endBreak');
      setModalMessage(t('employeeDashboard.endBreak'));
      setShowConfirmModal(true);
    } else if (dailyRecordControl?.isPresent) {
      // Mola başlatma için mola türü modalı
      setModalAction('startBreak');
      setShowBreakTypeModal(true);
    } else {
      showErrorResult(t('employeeDashboard.startBreakError'));
    }
  };

  const handleStartBreak = async (breakType: BreakType = BreakType.BREAK) => {  
    try {
      const response = await startBreak({ type: breakType });
      if(response){
        setIsBreakActive(true);
        await fetchDailyRecordControl(); // Güncel durumu almak için
        return true;
      }
    } catch (error) {
      handleApiError(error,t);
      return false;
    }
  };

  const handleEndBreak = async () => {
    try {
      const response = await endBreak();
      if(response){
        setIsBreakActive(false);
        setBreakDuration(response.breakDuration);
        setBreakStartTime(null);
        setBreakRemaining(null);
        await fetchDailyRecordControl(); // Güncel durumu almak için
        return true;
      }
      else{
        return false;
      }
    } catch (error) {
      handleApiError(error,t);
      return false;
    }
  };

  const fetchYearlyDetails = async () => {
    try {
      const responseLeaveSummary = await getUserYearlyLeaveDetails(Number(user?.id));      
      const responseYearlyDetails = await getYearlyDetails(Number(user?.id));
      const responseYearlyWorkStats = await getYearlyWorkStats(Number(user?.id));
      const responseWorkSchedule = await getWorkSchedule(Number(user?.id));
      
      setLeaveSummary(responseLeaveSummary);
      setYearlyDetails(responseYearlyDetails);
      setYearlyWorkStats(responseYearlyWorkStats);
      setWorkSchedule(responseWorkSchedule);
      
      setLeavesChart((prevState: any) => ({
        ...prevState,
        series: [
          responseYearlyDetails?.workDays,
          responseYearlyDetails?.absentDays,
          responseYearlyDetails?.lateDays,
          responseYearlyDetails?.leaveDays,
          
        ]
      }));
    } catch (error) {
      setIsError(true);
      handleApiError(error,t);
    }
  };
  const fetchUserById = async () => {
    try {
      setIsLoading(true);
      const response = await getUserById(Number(user?.id));
      
      setUserInfo(response);
    } catch (error) {
      setIsError(true);
      handleApiError(error,t);
    } finally {
      setIsLoading(false);
    }
  }
  useEffect(() => {
    fetchYearlyDetails();
    fetchUserById();
  }, []);
  const fetchDailyRecordControl = async () => {
    try {
      setIsLoading(true);
      const response = await getDailyRecordControl();
      setDailyRecordControl(response);
      
      // Mola ve çalışma durumlarını güncelle
      setIsBreakActive(response.isOnBreak || false);
      setBreakStartTime(response.breakStartTime || null);
      setBreakDuration(response.breakDuration || 0);
      setBreakRemaining(response.breakRemaining || null);
      setWorkTime(response.workTime || 0);
      setOfficeEntryTime(response.officeEntryTime || null);
    } catch (error) {
      setIsError(true);
      handleApiError(error,t);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheckInOut = async () => {
    if (dailyRecordControl?.isPresent) {
      // Çıkış için onay modalı
      setModalAction('checkOut');
      setModalMessage(t('employeeDashboard.checkOutQuestion'));
      setShowConfirmModal(true);
    } else {
      // Giriş için kontrol
      try {
        const lateIn = await getLateIn();
        if (lateIn.isLate) {
          // Geç kalma durumunda mazeret modalı
          setModalAction('checkIn');
          setShowExcuseModal(true);
        } else {
          // Normal giriş için onay modalı
          setModalAction('checkIn');
          setModalMessage(t('employeeDashboard.checkInQuestion'));
          setShowConfirmModal(true);
        }
      } catch (error) {
        handleApiError(error,t);
      }
    }
  };
  const handleModalSuccess = () => {
    setIsModalOpen(false);
    fetchYearlyDetails();
  };
  
  useEffect(() => {
    fetchDailyRecordControl();
  }, []);

  // Örnek veri (gerçek API'den gelecek)
  const [workData, setWorkData] = useState({
    workIntervals: [
      {
        start: "06:19",
        end: "15:26"
      }
    ],
    breakIntervals: [
      {
        start: "10:30",
        end: "10:45"
      },
      {
        start: "12:00",
        end: "12:30"
      }
    ],
    overtimeIntervals: [
      {
        start: "14:00",
        end: "15:26"
      }
    ]
  });

  // Zaman çubuğu için gerekli hesaplamaları yapan fonksiyon
  const calculateTimelineData = (data: any) => {
    // Zaman aralığı: 06:00 - 18:00 (12 saat)
    const startHour = parseInt(data.workIntervals[0].start.split(':')[0]) - 1;
    const endHour = parseInt(data.workIntervals[0].end.split(':')[0])+1;
    const totalMinutes = (endHour - startHour) * 60;
    
    // Çalışma aralıklarını yüzdeye çevir
    const workSegments = data.workIntervals.map((interval: any) => {
      const startParts = interval.start.split(':');
      const endParts = interval.end.split(':');
      
      const startMinutes = (parseInt(startParts[0]) - startHour) * 60 + parseInt(startParts[1]);
      const endMinutes = (parseInt(endParts[0]) - startHour) * 60 + parseInt(endParts[1]);
      
      const startPercent = (startMinutes / totalMinutes) * 100;
      const width = ((endMinutes - startMinutes) / totalMinutes) * 100;
      
      return { startPercent, width, type: 'work' };
    });
    
    // Mola aralıklarını yüzdeye çevir
    const breakSegments = data.breakIntervals.map((interval: any) => {
      const startParts = interval.start.split(':');
      const endParts = interval.end.split(':');
      
      const startMinutes = (parseInt(startParts[0]) - startHour) * 60 + parseInt(startParts[1]);
      const endMinutes = (parseInt(endParts[0]) - startHour) * 60 + parseInt(endParts[1]);
      
      const startPercent = (startMinutes / totalMinutes) * 100;
      const width = ((endMinutes - startMinutes) / totalMinutes) * 100;
      
      return { startPercent, width, type: 'break' };
    });
    
    // Mesai aralıklarını yüzdeye çevir
    const overtimeSegments = data.overtimeIntervals.map((interval: any) => {
      const startParts = interval.start.split(':');
      const endParts = interval.end.split(':');
      
      const startMinutes = (parseInt(startParts[0]) - startHour) * 60 + parseInt(startParts[1]);
      const endMinutes = (parseInt(endParts[0]) - startHour) * 60 + parseInt(endParts[1]);
      
      const startPercent = (startMinutes / totalMinutes) * 100;
      const width = ((endMinutes - startMinutes) / totalMinutes) * 100;
      
      return { startPercent, width, type: 'overtime' };
    });
    
    // Saat etiketleri oluştur
    const hourLabels = [];
    for (let i = startHour; i <= endHour; i++) {
      const hour = i > 12 ? (i - 12) : i;
      const ampm = i >= 12 ? 'PM' : 'AM';
      hourLabels.push(`${hour}:00 ${ampm}`);
    }
    
    return {
      segments: [...workSegments, ...breakSegments, ...overtimeSegments],
      hourLabels
    };
  };

  // Zaman çubuğu bileşeni
  const TimelineBar = ({ data }: { data: any }) => {
    const { segments, hourLabels } = calculateTimelineData(data);
    
    return (
      <div className="mb-4">
        <div className="position-relative" style={{ height: '30px', backgroundColor: '#f0f0f0', borderRadius: '4px' }}>
          {segments.map((segment, index) => (
            <div 
              key={index}
              className={`position-absolute ${
                segment.type === 'work' ? 'bg-success' : 
                segment.type === 'break' ? 'bg-warning' : 'bg-info'
              }`}
              style={{
                left: `${segment.startPercent}%`,
                width: `${segment.width}%`,
                height: '100%',
                borderRadius: '4px',
                opacity: segment.type === 'overtime' ? 0.7 : 1
              }}
            />
          ))}
        </div>
        <div className="d-flex justify-content-between mt-2">
          {hourLabels.map((label, index) => (
            <span key={index} className="fs-10">{label}</span>
          ))}
        </div>
        <div className="d-flex mt-3 justify-content-center">
          <div className="d-flex align-items-center me-3">
            <div className="bg-success" style={{ width: '12px', height: '12px', borderRadius: '2px' }}></div>
            <span className="ms-1 fs-12">{t('employeeDashboard.work')}</span>
          </div>
          <div className="d-flex align-items-center me-3">
            <div className="bg-warning" style={{ width: '12px', height: '12px', borderRadius: '2px' }}></div>
            <span className="ms-1 fs-12">{t('employeeDashboard.break')}</span>
          </div>
          <div className="d-flex align-items-center">
            <div className="bg-info" style={{ width: '12px', height: '12px', borderRadius: '2px' }}></div>
            <span className="ms-1 fs-12">{t('employeeDashboard.overtime')}</span>
          </div>
        </div>
      </div>
    );
  };

  // Çalışma süresini formatlayan yardımcı fonksiyon
  const formatWorkTime = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours} ${t('employeeDashboard.hours')} ${mins} ${t('employeeDashboard.minutes')}`;
  };

  // Kalan mola süresini formatlayan yardımcı fonksiyon
  const formatBreakRemaining = (seconds: number | null) => {
    if (seconds === null) return "--:--";
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  // Mola türünü gösteren yardımcı fonksiyon
  const getBreakTypeName = (type: BreakType | null): string => {
    if (!type) return t('employeeDashboard.break');
    
    switch (type) {
      case BreakType.BREAK:
        return t('employeeDashboard.shortBreak');
      case BreakType.LUNCH:
        return t('employeeDashboard.lunchBreak');
      case BreakType.PERSONAL:
        return t('employeeDashboard.personalBreak');
      case BreakType.OTHER:
        return t('employeeDashboard.otherBreak');
      default:
        return t('employeeDashboard.break');
    }
  };

  // Mola türü seçildiğinde çağrılacak fonksiyon
  const handleBreakTypeSelect = async (breakType: BreakType) => {
    setShowBreakTypeModal(false);
    setModalLoading(true);
    
    try {
      const success = await handleStartBreak(breakType);
      if (success) {
        showSuccessResult(t('employeeDashboard.breakStarted'));
      }
    } catch (error) {
      handleApiError(error,t);
    } finally {
      setModalLoading(false);
    }
  };

  // Onay modalında işlem gerçekleştirme
  const handleConfirmAction = async () => {
    setModalLoading(true);
    
    try {
      switch (modalAction) {
        case 'checkIn':
          await checkIn();
          await fetchDailyRecordControl();
          showSuccessResult(t('employeeDashboard.checkInSuccess'));
          break;
          
        case 'checkOut':
          try {
            await checkOut();
            await fetchDailyRecordControl();
            showSuccessResult(t('employeeDashboard.checkOutSuccess'));
          } catch (error: any) {
            handleApiError(error,t);
          }
          break;
          
        case 'endBreak':
          try {
            const success = await handleEndBreak();
            if (success) {
              showSuccessResult(t('employeeDashboard.breakEnded'));
            } else {
              showErrorResult(t('employeeDashboard.breakEndError'));
            }
          } catch (error: any) {
            handleApiError(error,t);
          }
          break;
      }
    } catch (error: any) {
      // Genel hata yakalama
      handleApiError(error,t);
    } finally {
      setModalLoading(false);
      setShowConfirmModal(false);
    }
  };

  const validateExcuse = (excuse: string): { isValid: boolean; message: string } => {
    const MIN_LENGTH = 10;
    const MAX_LENGTH = 100;
    const MIN_LETTERS = 8; // En az 8 normal harf olmalı
    const trimmedExcuse = excuse.trim();

    // Boş kontrol
    if (!trimmedExcuse) {
      return { isValid: false, message: t('employeeDashboard.excuseEmpty') };
    }

    // Minimum uzunluk kontrolü
    if (trimmedExcuse.length < MIN_LENGTH) {
      return { 
        isValid: false, 
        message: `${t('employeeDashboard.excuseMinLength', { min: MIN_LENGTH })}`//(Şu an: ${trimmedExcuse.length})
      };
    }

    // Maksimum uzunluk kontrolü
    if (trimmedExcuse.length > MAX_LENGTH) {
      return { 
        isValid: false, 
        message: `${t('employeeDashboard.excuseMaxLength', { max: MAX_LENGTH })}`//(Şu an: ${trimmedExcuse.length})
      };
    }

    // Sadece boşluk karakteri kontrolü
    if (trimmedExcuse.replace(/\s/g, '').length === 0) {
      return { isValid: false, message: t('employeeDashboard.excuseSpace') };
    }

    // Normal harf sayısı kontrolü
    const letterCount = (trimmedExcuse.match(/[a-zA-ZğüşıöçĞÜŞİÖÇ]/g) || []).length;
    if (letterCount < MIN_LETTERS) {
      return {
        isValid: false,
        message: `${t('employeeDashboard.excuseMinLetters', { min: MIN_LETTERS })}`//(Şu an: ${letterCount} karakter)
      };
    }

    // Tekrar eden karakter kontrolü (örn: .........)
    const repeatingChars = /(.)\1{4,}/;
    if (repeatingChars.test(trimmedExcuse)) {
      return {
        isValid: false,
        message: t('employeeDashboard.excuseRepeating')
      };
    }

    // Özel karakter kontrolü
    const specialChars = /[<>{}]/;
    if (specialChars.test(trimmedExcuse)) {
      return { isValid: false, message: t('employeeDashboard.excuseInvalid') };
    }

    return { isValid: true, message: '' };
  };

  const handleExcuseSubmit = async () => {
    const validation = validateExcuse(excuseReason);
    
    if (!validation.isValid) {
      Swal.fire({
        title: t('employeeDashboard.error'),
        text: validation.message,
        icon: 'error',
        confirmButtonText: t('employeeDashboard.ok')
      });
      return;
    }
    
    setModalLoading(true);
    
    try {
      // Mazeret kaydı
      await createExcuse({
        reason: excuseReason.trim()
      });
      
      // Giriş yapma
      await checkIn();
      await fetchDailyRecordControl();
      
      showSuccessResult(t('employeeDashboard.excuseSaved'));
    } catch (error) {
      handleApiError(error,t);
    } finally {
      setModalLoading(false);
      setShowExcuseModal(false);
      setExcuseReason('');
    }
  };

  // Başarılı sonuç modalı gösterme
  const showSuccessResult = (message: string) => {
    setModalResult('success');
    setModalMessage(message);
    setShowResultModal(true);
  };

  // Hata sonuç modalı gösterme
  const showErrorResult = (message: string) => {
    setModalResult('error');
    setModalMessage(message);
    setShowResultModal(true);
  };

  useEffect(() => {
    let workTimer: NodeJS.Timeout;
    let breakTimer: NodeJS.Timeout;

    if (dailyRecordControl?.isPresent) {
      workTimer = setInterval(() => {
        // Bugünün tarihini al ve officeEntryTime ile birleştir
        const today = new Date();
        const [entryHours, entryMinutes, entrySeconds] = (officeEntryTime || '').split(':').map(Number);
        
        const startTime = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          entryHours,
          entryMinutes,
          entrySeconds
        ).getTime();
    
        const now = new Date().getTime();
        const diff = now - startTime;
        
        // API'den gelen beklenen çalışma süresi (dakika cinsinden)
        const totalWorkMinutes = dailyRecordControl?.expectedWorkMinutes || 480; // varsayılan 8 saat
        const totalWorkTime = totalWorkMinutes * 60 * 1000; // milisaniyeye çevir
        
        // Kalan süre
        const remainingTime = totalWorkTime - diff;
        
        // Kalan süreyi saat, dakika, saniye olarak hesapla
        const remainingHours = Math.max(0, Math.floor(remainingTime / (1000 * 60 * 60)));
        const remainingMinutes = Math.max(0, Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60)));
        const remainingSeconds = Math.max(0, Math.floor((remainingTime % (1000 * 60)) / 1000));
        
        setCurrentWorkTime(
          `${remainingHours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`
        );
        
        // Progress bar için çalışılan süreyi kullan (API'den gelen workTime)
        const progress = Math.min((dailyRecordControl.workTime / totalWorkMinutes) * 100, 100);
        setWorkProgress(progress);
      }, 1000);
    }

    if (isBreakActive && breakStartTime) {
      breakTimer = setInterval(() => {

        const today = new Date();
        // breakStartTime'ı parçalara ayır
        const [hours, minutes, seconds] = breakStartTime.split(':').map(Number);
        const startTime = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          hours,
          minutes,
          seconds
        ).getTime();
        
        const now = new Date().getTime();
        const diff = now - startTime;
        
        const diffMinutes = Math.floor(diff / (1000 * 60));
        const diffSeconds = Math.floor((diff % (1000 * 60)) / 1000);
        
        setCurrentBreakTime(`${diffMinutes.toString().padStart(2, '0')}:${diffSeconds.toString().padStart(2, '0')}`);
    
        // Mola süresi için breakDuration'ı kullan (saniye cinsinden)
        const progress = Math.min((diff / ((breakDuration || 0) * 1000)) * 100, 100);
        setBreakProgress(progress);
      }, 1000);
    }

    return () => {
      if (workTimer) clearInterval(workTimer);
      if (breakTimer) clearInterval(breakTimer);
    };
  }, [dailyRecordControl?.isPresent, isBreakActive, officeEntryTime, breakStartTime]);

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          {/* Breadcrumb */}
          <div className="d-md-flex d-block align-items-center justify-content-between page-breadcrumb mb-3">
            <div className="my-auto mb-2">
              <h2 className="mb-1">{t('employeeDashboard.title')}</h2>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={routes.adminDashboard}>
                      <i className="ti ti-smart-home" />
                    </Link>
                  </li>
                  <li className="breadcrumb-item">{t('employeeDashboard.dashboard')}</li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {t('employeeDashboard.title')}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          
          <div className="row">

          <div className="col-xl-4 d-flex">
              <div className="card flex-fill attendance-bg">
                <div className="card-body d-flex flex-column">
                  <div className="mb-4 text-center">
                    <h6 className="fw-medium text-gray-5 mb-1">{t('employeeDashboard.attendance')}</h6>
                    <h4>{workTime > 0 ? formatWorkTime(workTime) : t('employeeDashboard.noAttendance')}</h4>
                  </div>
                  <div className="flex-grow-1">
                    <CircleProgress 
                      value={dailyRecordControl?.expectedWorkMinutes ? (workTime / dailyRecordControl.expectedWorkMinutes) * 100 : 0}
                      type="attendance" 
                      totalHours={dailyRecordControl?.shiftEndTime}
                      currentTime={currentWorkTime}
                    />
                    <div className="text-center">
                      <h6 className="fw-medium d-flex align-items-center justify-content-center mb-4">
                        <i className="ti ti-fingerprint text-primary me-1" />
                        {dailyRecordControl?.isPresent 
                          ? `${t('employeeDashboard.loginStatusPos')}: ${officeEntryTime}` 
                          : `${t('employeeDashboard.loginStatusNeg')}`}
                      </h6>
                    </div>
                  </div>
                  {/* <div className="mt-auto">
                    <button 
                      className={`btn w-100 ${dailyRecordControl?.isPresent ? 'btn-danger' : 'btn-primary'}`} 
                      onClick={handleCheckInOut}
                    >
                      {dailyRecordControl?.isPresent ? t('employeeDashboard.checkOut') : t('employeeDashboard.checkIn')}
                    </button>
                  </div> */}
                </div>
              </div>
            </div>  

            <div className="col-xl-4 d-flex">
              <div className="card flex-fill">
                <div className="card-body d-flex flex-column">
                  <div className="mb-4">
                    <div className="d-flex align-items-center justify-content-center">
                      <h6 className="fw-medium text-gray-5">{t('employeeDashboard.break')}</h6>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <div className="d-flex justify-content-center mb-4">
                      <div className="break-timer-circle">
                        <CircleProgress 
                          value={dailyRecordControl ? ((dailyRecordControl?.breakDuration - dailyRecordControl?.breakRemaining) / dailyRecordControl?.breakDuration) * 100 : 0}
                          type="break"
                          currentTime={currentBreakTime}
                          remainingTime={breakRemaining ? formatBreakRemaining(breakRemaining) : '--:--'}
                          totalBreakTime={breakDuration ? `${Math.floor(breakDuration / 60)}:${(breakDuration % 60).toString().padStart(2, '0')}` : '00:00'}
                        />
                      </div>
                    </div>
                    <div className="text-center">
                      <div className="d-flex justify-content-between mb-3">
                        <span>{t('employeeDashboard.breakStart')}:</span>
                        <span className="text-primary">{breakStartTime || "--:--"}</span>
                      </div>
                      <div className="d-flex justify-content-between mb-4">
                        <span>{t('employeeDashboard.breakRemaining')}:</span>
                        <span className="text-danger">{breakRemaining ? formatBreakRemaining(breakRemaining) : "--:--"}</span>
                      </div>
                    </div>
                  </div>
                  <div className="mt-auto">
                    {/* <button 
                      className={`btn w-100 ${isBreakActive ? 'btn-outline-danger' : 'btn-outline-primary'}`} 
                      onClick={handleStartEndBreak}
                      disabled={!dailyRecordControl?.isPresent}
                    >
                      {isBreakActive ? t('employeeDashboard.breakEnd') : t('employeeDashboard.breakStartt')}
                    </button> */}
                  </div>
                </div>
              </div>
            </div>  

            <div className="col-xl-4 d-flex">
              <div className="card flex-fill">
                <div className="card-header">
                  <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-2">
                    <h5>{t('employeeDashboard.leaveSummary')}</h5>

                    <Link
                    to={routes.leaveemployee}
                    className="btn btn-light btn-md mb-2"
                  >
                    {t('employeeDashboard.viewAll')}
                  </Link>
                   
                  </div>
                </div>  
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col-sm-6">
                      <div className="mb-3">
                        <span className="d-block mb-1">{t('employeeDashboard.totalLeaveLimit')}</span>
                        <h4>{leaveSummary?.totalAnnualLeaveLimit}</h4>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb-3">
                        <span className="d-block mb-1">{t('employeeDashboard.totalUsedLeave')}</span>
                        <h4>{leaveSummary?.usedAnnualLeaveDays}</h4>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb-3">
                        <span className="d-block mb-1">{t('employeeDashboard.totalLeaveRemaining')}</span>
                        <h4>{leaveSummary?.remainingAnnualLeaveDays}</h4>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb-3">
                        <span className="d-block mb-1">{t('employeeDashboard.totalLeaveRequests')}</span>
                        <h4>{leaveSummary?.totalLeaveRequests}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <div className="row">
                    <div className="col-sm-12">
                      <div>
                        <Link
                          to="#"
                          className="btn btn-dark w-100"
                          onClick={() => setIsModalOpen(true)}
                        >
                          {t('employeeDashboard.createLeaveRequest')}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className="row">

          <div className="col-xl-6 d-flex">
              <div className="card position-relative flex-fill">
                <div className="card-header bg-dark">
                  <div className="d-flex align-items-center">
                    <span className="avatar avatar-lg avatar-rounded border border-white border-2 flex-shrink-0 me-2">
                      <ImageWithBasePath src="assets/img/users/user-01.jpg" alt="Img" />
                    </span>
                    <div>
                      <h5 className="text-white mb-1">{userInfo?.firstName} {userInfo?.lastName}</h5>
                      <div className="d-flex align-items-center">
                        <p className="text-white fs-12 mb-0">
                          {userInfo?.title}
                        </p>
                        <span className="mx-1">
                          <i className="ti ti-point-filled text-primary" />
                        </span>
                        <p className="fs-12">{userInfo?.department?.name}</p>
                      </div>
                    </div>
                  </div>
                  {/* <Link
                    to="#"
                    className="btn btn-icon btn-sm text-white rounded-circle edit-top"
                  >
                    <i className="ti ti-edit" />
                  </Link> */}
                </div>
                
                <div className="card-body">
                  <div className="mb-3">
                    <span className="d-block mb-1 fs-13">{t('employeeDashboard.phoneNumber')}</span>
                    <p className="text-gray-9">{userInfo?.phone}</p>
                  </div>
                  <div className="mb-3">
                    <span className="d-block mb-1 fs-13">{t('employeeDashboard.emailAddress')}</span>
                    <p className="text-gray-9">{userInfo?.email}</p>
                  </div>
                  <div className="mb-3">
                    <span className="d-block mb-1 fs-13">{t('employeeDashboard.office')}</span>
                    <p className="text-gray-9">{userInfo?.branch?.name}</p>
                  </div>
                  <div>
                    <span className="d-block mb-1 fs-13">{t('employeeDashboard.joinedOn')}</span>
                    <p className="text-gray-9">{userInfo?.hireDate ? formatDate(userInfo.hireDate) : ''}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-6 d-flex">
              <div className="card flex-fill">
                <div className="card-header">
                  <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-2">
                    <h5>{t('employeeDashboard.yearlySummary')}</h5>
               
                  </div>
                </div>
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <div className="mb-4">
                        <div className="mb-3">
                          <p className="d-flex align-items-center">
                            <i className="ti ti-circle-filled fs-8 text-dark me-1" />
                            <span className="text-gray-9 fw-semibold me-1">
                              {yearlyDetails?.workDays}
                            </span>
                            {t('employeeDashboard.workDays')}
                          </p>
                        </div>
                        <div className="mb-3">
                          <p className="d-flex align-items-center">
                            <i className="ti ti-circle-filled fs-8 text-success me-1" />
                            <span className="text-gray-9 fw-semibold me-1">
                              {yearlyDetails?.leaveDays}
                            </span>
                            {t('employeeDashboard.leaveDays')}
                          </p>
                        </div>
                        <div className="mb-3">
                          <p className="d-flex align-items-center">
                            <i className="ti ti-circle-filled fs-8 text-primary me-1" />
                            <span className="text-gray-9 fw-semibold me-1">
                              {yearlyDetails?.absentDays}
                            </span>
                            {t('employeeDashboard.absentDays')}
                          </p>
                        </div>
                        <div className="mb-3">
                          <p className="d-flex align-items-center">
                            <i className="ti ti-circle-filled fs-8 text-danger me-1" />
                            <span className="text-gray-9 fw-semibold me-1">
                              {yearlyDetails?.lateDays}
                            </span>
                            {t('employeeDashboard.lateDays')}
                          </p>
                        </div>
                        
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-4 d-flex justify-content-md-end">
                        <ReactApexChart
                          id="leaves_chart"
                          options={leavesChart}
                          series={leavesChart.series}
                          type="donut"
                          height={165}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
                     
            

            <div className="col-xl-12 d-flex">
              <div className="row flex-fill">
                <div className="col-xl-3 col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="border-bottom mb-3 pb-2">
                        <span className="avatar avatar-sm bg-primary mb-2">
                          <i className="ti ti-clock-stop" />
                        </span>
                        <h2 className="mb-2">
                          {yearlyWorkStats?.todayWorkHours}
                        </h2>
                        <p className="fw-medium text-truncate">{t('employeeDashboard.todayWorkHours')}</p>
                      </div>
                      <div>
                        <p className="d-flex align-items-center fs-13">
                          <span className="avatar avatar-xs rounded-circle bg-success flex-shrink-0 me-2">
                            <i className="ti ti-arrow-up fs-12" />
                          </span>
                          <span>{yearlyWorkStats?.todayVsWeekChange}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="border-bottom mb-3 pb-2">
                        <span className="avatar avatar-sm bg-dark mb-2">
                          <i className="ti ti-clock-up" />
                        </span>
                        <h2 className="mb-2">
                          {yearlyWorkStats?.totalWeekHours}
                        </h2>
                        <p className="fw-medium text-truncate">{t('employeeDashboard.totalWeekHours')}</p>
                      </div>
                      <div>
                        <p className="d-flex align-items-center fs-13">
                          <span className="avatar avatar-xs rounded-circle bg-success flex-shrink-0 me-2">
                            <i className="ti ti-arrow-up fs-12" />
                          </span>
                          <span>{yearlyWorkStats?.weekChange}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="border-bottom mb-3 pb-2">
                        <span className="avatar avatar-sm bg-info mb-2">
                          <i className="ti ti-calendar-up" />
                        </span>
                        <h2 className="mb-2">
                          {yearlyWorkStats?.totalMonthHours}
                        </h2>
                        <p className="fw-medium text-truncate">{t('employeeDashboard.totalMonthHours')}</p>
                      </div>
                      <div>
                        <p className="d-flex align-items-center fs-13 text-truncate">
                          <span className="avatar avatar-xs rounded-circle bg-danger flex-shrink-0 me-2">
                            <i className="ti ti-arrow-down fs-12" />
                          </span>
                          <span>{yearlyWorkStats?.monthChange}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="border-bottom mb-3 pb-2">
                        <span className="avatar avatar-sm bg-pink mb-2">
                          <i className="ti ti-calendar-star" />
                        </span>
                        <h2 className="mb-2">
                          16 
                        </h2>
                        <p className="fw-medium text-truncate">
                          {t('employeeDashboard.totalMonthHours')}
                        </p>
                      </div>
                      <div>
                        <p className="d-flex align-items-center fs-13 text-truncate">
                          <span className="avatar avatar-xs rounded-circle bg-danger flex-shrink-0 me-2">
                            <i className="ti ti-arrow-down fs-12" />
                          </span>
                          <span>6% Last Month</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-xl-3">
                          <div className="mb-4">
                            <p className="d-flex align-items-center mb-1">
                              <i className="ti ti-point-filled text-dark-transparent me-1" />
                              Total Working hours
                            </p>
                            <h3>12h 36m</h3>
                          </div>
                        </div>
                        <div className="col-xl-3">
                          <div className="mb-4">
                            <p className="d-flex align-items-center mb-1">
                              <i className="ti ti-point-filled text-success me-1" />
                              Productive Hours
                            </p>
                            <h3>08h 36m</h3>
                          </div>
                        </div>
                        <div className="col-xl-3">
                          <div className="mb-4">
                            <p className="d-flex align-items-center mb-1">
                              <i className="ti ti-point-filled text-warning me-1" />
                              Break hours
                            </p>
                            <h3>22m 15s</h3>
                          </div>
                        </div>
                        <div className="col-xl-3">
                          <div className="mb-4">
                            <p className="d-flex align-items-center mb-1">
                              <i className="ti ti-point-filled text-info me-1" />
                              Overtime
                            </p>
                            <h3>02h 15m</h3>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <h6 className="mb-3">Günlük Çalışma Zaman Çizelgesi</h6>
                          <TimelineBar data={workData} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer d-sm-flex align-items-center justify-content-between border-top bg-white p-3">
            <FooterSection />
        </div>
      </div>
      {/* /Page Wrapper */}
 
      <EmployeeLeaveModal
          leaveDataForEdit={null}
          onSuccess={handleModalSuccess}
          open={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
        />

      {/* Onay Modalı */}
      <Modal
        title={
          <div className="d-flex align-items-center">
            <ExclamationCircleFilled className="text-warning me-2" style={{ fontSize: '26px' }} />
            <span className="modal-title">İşlem Onayı</span>
          </div>
        }
        open={showConfirmModal}
        onCancel={() => !modalLoading && setShowConfirmModal(false)}
        footer={null}
        centered
        maskClosable={!modalLoading}
        closable={!modalLoading}
        className="custom-modal confirm-modal"
        width={450}
      >
        <div className="py-4">
          <p className="mb-4 text-center fs-16 confirm-message">{modalMessage}</p>
          
          <div className="d-flex justify-content-center">
            {modalLoading ? (
              <div className="text-center py-3">
                <Spin size="large" />
                <p className="mt-3 mb-0 loading-text">İşleminiz gerçekleştiriliyor...</p>
              </div>
            ) : (
              <div className="modal-button-group">
                <Button 
                  className="modal-cancel-button" 
                  onClick={() => setShowConfirmModal(false)}
                  size="large"
                >
                  <i className="ti ti-x me-2"></i>
                  İptal
                </Button>
                <Button 
                  type="primary" 
                  className="modal-confirm-button" 
                  onClick={handleConfirmAction}
                  size="large"
                >
                  <i className="ti ti-check me-2"></i>
                  Onaylıyorum
                </Button>
              </div>
            )}
          </div>
        </div>
      </Modal>

      {/* Sonuç Modalı */}
      <Modal
        open={showResultModal}
        footer={null}
        onCancel={() => setShowResultModal(false)}
        centered
        className="custom-modal"
        width={450}
      >
        <Result
          status={modalResult === 'success' ? 'success' : 'error'}
          title={modalResult === 'success' ? 'İşlem Başarılı' : 'İşlem Başarısız'}
          subTitle={modalMessage}
          extra={[
            <Button 
              type="primary" 
              key="ok" 
              onClick={() => setShowResultModal(false)}
              className="modal-confirm-button"
              size="large"
            >
              Tamam
            </Button>,
          ]}
        />
      </Modal>

      {/* Mazeret Modalı */}
      <Modal
        title={
          <div className="d-flex align-items-center">
            <ClockCircleFilled className="text-warning me-2" style={{ fontSize: '26px' }} />
            <span className="modal-title">Geç Kalma Mazereti</span>
          </div>
        }
        open={showExcuseModal}
        onCancel={() => !modalLoading && setShowExcuseModal(false)}
        footer={null}
        centered
        maskClosable={!modalLoading}
        closable={!modalLoading}
        className="custom-modal confirm-modal"
        width={500}
      >
        <div className="py-3">
          <p className="mb-4 confirm-message">Geç kaldığınız için mazeret sunmanız gerekiyor.</p>
          
          <div className="form-group mb-4">
            <label className="form-label">Mazeret</label>
            <textarea 
              className="form-control" 
              rows={4} 
              placeholder="Mazeretinizi giriniz"
              value={excuseReason}
              onChange={(e) => setExcuseReason(e.target.value)}
              disabled={modalLoading}
            />
            {excuseReason.trim() === '' && (
              <small className="text-danger mt-2 d-block">Mazeret girmeniz zorunludur</small>
            )}
          </div>
          
          <div className="d-flex justify-content-end">
            {modalLoading ? (
              <div className="text-center py-2 w-100">
                <Spin size="large" />
                <p className="mt-3 mb-0 loading-text">İşleminiz gerçekleştiriliyor...</p>
              </div>
            ) : (
              <div className="modal-button-group justify-content-end">
                <Button 
                  className="modal-cancel-button" 
                  onClick={() => setShowExcuseModal(false)}
                  size="large"
                >
                  <i className="ti ti-x me-2"></i>
                  İptal
                </Button>
                <Button 
                  type="primary" 
                  className="modal-confirm-button"
                  onClick={handleExcuseSubmit}
                  disabled={excuseReason.trim() === ''}
                  size="large"
                >
                  <i className="ti ti-send me-2"></i>
                  Gönder
                </Button>
              </div>
            )}
          </div>
        </div>
      </Modal>

      {/* Mola Türü Seçme Modalı */}
      <Modal
        title={
          <div className="d-flex align-items-center">
            <i className="ti ti-coffee me-2 text-primary" style={{ fontSize: '26px' }}></i>
            <span className="modal-title">Mola Türü Seçin</span>
          </div>
        }
        open={showBreakTypeModal}
        onCancel={() => !modalLoading && setShowBreakTypeModal(false)}
        footer={null}
        centered
        width={380}
        maskClosable={!modalLoading}
        closable={!modalLoading}
        className="custom-modal confirm-modal break-modal"
      >
        {modalLoading ? (
          <div className="text-center py-4">
            <Spin size="large" />
            <p className="mt-3 mb-0 loading-text">Mola başlatılıyor...</p>
          </div>
        ) : (
          <div className="break-type-options py-2">
            <div className="row g-3">
              <div className="col-6">
                <button 
                  className="btn btn-outline-primary w-100 p-3 h-100 d-flex flex-column align-items-center justify-content-center break-btn"
                  onClick={() => handleBreakTypeSelect(BreakType.BREAK)}
                >
                  <div className="break-icon-wrapper">
                    <i className="ti ti-coffee mb-2"></i>
                  </div>
                  <span>Kısa Mola</span>
                </button>
              </div>
              <div className="col-6">
                <button 
                  className="btn btn-outline-primary w-100 p-3 h-100 d-flex flex-column align-items-center justify-content-center break-btn"
                  onClick={() => handleBreakTypeSelect(BreakType.LUNCH)}
                >
                  <div className="break-icon-wrapper">
                    <i className="ti ti-bowl mb-2"></i>
                  </div>
                  <span>Yemek Molası</span>
                </button>
              </div>
              <div className="col-6">
                <button 
                  className="btn btn-outline-primary w-100 p-3 h-100 d-flex flex-column align-items-center justify-content-center break-btn"
                  onClick={() => handleBreakTypeSelect(BreakType.PERSONAL)}
                >
                  <div className="break-icon-wrapper">
                    <i className="ti ti-user mb-2"></i>
                  </div>
                  <span>Kişisel İhtiyaç</span>
                </button>
              </div>
              <div className="col-6">
                <button 
                  className="btn btn-outline-primary w-100 p-3 h-100 d-flex flex-column align-items-center justify-content-center break-btn"
                  onClick={() => handleBreakTypeSelect(BreakType.OTHER)}
                >
                  <div className="break-icon-wrapper">
                    <i className="ti ti-dots mb-2"></i>
                  </div>
                  <span>Diğer</span>
                </button>
              </div>
            </div>
            <div className="mt-4 text-end">
             </div>
             <button 
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                }}
                className="btn modal-cancel-button"
                onClick={() => setShowBreakTypeModal(false)}
              >
                <i className="ti ti-x me-2"></i>
                İptal
              </button>
             <div>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default EmployeeDashboard;



