export interface CompanySettings {
  workOnHolidays: boolean;
  workOnWeekends: boolean;
  includeManagerInReports: boolean;
  maxDailyWorkHours: boolean;
  lateTolerance: boolean;
  lateTolerationMinutes: number;
  earlyLeave: boolean;
  includeWeekendsInAnnualLeave: boolean;
  sendLeaveRequestEmails: boolean;
  requirePreApprovalForLeave: boolean;
  annualLeaveLimit: number;
  shiftMode: 'fixed' | 'assignment' | 'both';
  fixedShiftStartTime?: string;
  fixedShiftEndTime?: string;
  fixedShiftDays?: string[];
  ipControl: boolean;
  useFixedShift: boolean;
}

export interface UpdateCompanySettingsDTO {
  settings: CompanySettings;
}

export interface AnnualLeaveLimitResponse {
  annualLeaveLimit: number;
}

export enum CompanyStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  SUSPENDED = "SUSPENDED"
}

export interface Company {
  id: number;
  name: string;
  address?: string;
  phone?: string;
  email?: string;
  taxNumber: string;
  taxOffice: string;
  defaultBreakDuration: number;
  status: CompanyStatus;
  settings?: CompanySettings;
  users?: any[];
  branches?: any[];
  departments?: any[];
  createdAt: Date;
  updatedAt: Date;
}

export interface CreateCompanyDto {
  name: string;
  address?: string;
  phone?: string;
  email?: string;
  taxNumber: string;
  taxOffice: string;
  defaultBreakDuration: number;
  status: CompanyStatus;
  settings?: CompanySettings;
}

export interface UpdateCompanyDto {
  name?: string;
  address?: string;
  phone?: string;
  email?: string;
  taxNumber?: string;
  taxOffice?: string;
  defaultBreakDuration?: number;
  status?: CompanyStatus;
  settings?: CompanySettings;
}

export interface SuperSettings {
  id: number;
  name: string;
  settings: CompanySettings;
}

export interface AboutUs {
  content: string;
}