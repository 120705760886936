import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { publicRoutes, authRoutes } from './router.link';
import Feature from '../components/features/feature';
import AuthFeature from '../components/features/authFeature';
import { all_routes } from './all_routes';
import ProtectedRoute from './protectedRoute';
import { RolesEnum } from '../types/route.types';
import { PermissionType } from '../types/user.types';

const AppRouter: React.FC = () => {
    const { isAuthenticated,user } = useSelector((state: RootState) => state.auth);
    const routes = all_routes;
    
    if (!isAuthenticated) {
        return (
            <Routes>
                <Route path="/" element={<Navigate to={routes.login2} replace />} />
                <Route path="/login-2" element={<Navigate to={routes.login2} replace />} />
                <Route path="/login" element={<AuthFeature />} />
                {authRoutes.map((route, idx) => (
                    <Route path={route.path} element={route.element} key={idx} />
                ))}
                <Route path="*" element={<Navigate to={routes.login2} replace />} />
            </Routes>
        );
    }

    return (
        <Routes>
            {user?.role===RolesEnum.SUPERADMIN&&
                <Route path="/" element={<Navigate to={routes.superAdminCompanies} replace />} />
            }
            {user?.role===RolesEnum.ADMIN&&
                <Route path="/" element={<Navigate to={routes.adminDashboard} replace />} />
            }
            {user?.role===RolesEnum.USER || user?.role === RolesEnum.MANAGER&&
                <Route path="/" element={<Navigate to={routes.employeeDashboard} replace />} />
            }
            <Route element={<Feature />}>
            {publicRoutes.map((route, idx) => (
            <Route
                path={route.path}
                key={idx}
                element={
                    route.roles ? (
                        <ProtectedRoute
                        element={route.element}
                        allowedRoles={route.roles as unknown as PermissionType[]}
                        userRole={user?.permissions as PermissionType[]}
                        />
                    ):(
                        <Navigate to='/' replace/>
                    )
                }
            />
            ))}

            </Route>
            {user?.role===RolesEnum.ADMIN&&
            <Route path="*" element={<Navigate to={routes.adminDashboard} replace />} />
            }
            {user?.role===RolesEnum.USER&&
            <Route path="*" element={<Navigate to={routes.employeeDashboard} replace />} />
            }
        </Routes>
    );
};

export default AppRouter;