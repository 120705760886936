import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import {
  Card,
  Button,
  Spin,
  Alert,
  Empty,
  message,
  Modal,
  Form,
  Input
} from "antd";
import {
  EditOutlined,
  SaveOutlined,
  ExclamationCircleOutlined,
  ArrowLeftOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import companyService from "../../../services/company/company.service";
import { AboutUs } from "../../../types/company.types";
import { handleApiError } from "../../../utils/errorHandler";
import { useTranslation } from "react-i18next";
import FooterSection from "../../shared/components/footerSection";

const { confirm } = Modal;
const { TextArea } = Input;

const AboutUsPage: React.FC = () => {
  const navigate = useNavigate();
  const [aboutUs, setAboutUs] = useState<AboutUs | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    fetchAboutUs();
  }, []);

  const fetchAboutUs = async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await companyService.getAboutUs();
      setAboutUs(data);
      form.setFieldsValue(data);
    } catch (error: any) {
      handleApiError(error, t);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      setSaving(true);
      await companyService.updateAboutUs(values);
      setAboutUs(values);
      setIsEditing(false);
      message.success("Hakkımızda bilgisi başarıyla güncellendi.");
    } catch (error: any) {
      handleApiError(error, t);
    } finally {
      setSaving(false);
    }
  };

  const confirmSave = () => {
    confirm({
      title: "Değişiklikleri kaydetmek istediğinize emin misiniz?",
      icon: <ExclamationCircleOutlined />,
      content: "Bu işlem sonucu Hakkımızda sayfası güncellenecektir.",
      okText: "Evet",
      okType: "primary",
      cancelText: "Hayır",
      onOk: handleSave,
    });
  };

  const handleCancel = () => {
    confirm({
      title: "Değişiklikleri iptal etmek istediğinize emin misiniz?",
      icon: <ExclamationCircleOutlined />,
      content: "Tüm değişiklikler kaybolacaktır.",
      okText: "Evet",
      okType: "danger",
      cancelText: "Hayır",
      onOk: () => {
        form.setFieldsValue(aboutUs || {});
        setIsEditing(false);
      },
    });
  };

  if (loading) {
    return (
      <div className="page-wrapper vh-100 d-flex flex-column justify-content-center align-items-center">
        <Spin size="large" />
        <p className="mt-3">Hakkımızda bilgisi yükleniyor...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="page-wrapper vh-100 d-flex flex-column justify-content-center align-items-center">
        <Alert
          message="Hata"
          description={error}
          type="error"
          showIcon
          action={
            <Button type="primary" onClick={() => navigate(all_routes.superAdminDashboard)}>
              Ana Sayfaya Dön
            </Button>
          }
        />
      </div>
    );
  }

  return (
    <>
      <div className="page-wrapper vh-100 d-flex flex-column justify-content-between">
        <div className="content flex-fill h-100">
          {/* Breadcrumb */}
          <div className="d-md-flex d-block align-items-center justify-content-between page-breadcrumb mb-3">
            <div className="my-auto mb-2">
              <h2 className="mb-1">Hakkımızda</h2>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={all_routes.superAdminDashboard}>
                      <i className="ti ti-smart-home" />
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Hakkımızda
                  </li>
                </ol>
              </nav>
            </div>
            <div className="ms-2">
              <Link
                to={all_routes.superAdminDashboard}
                className="btn btn-outline-primary me-2"
              >
                <ArrowLeftOutlined /> Geri
              </Link>
              {!isEditing ? (
                <Button
                  type="primary"
                  icon={<EditOutlined />}
                  onClick={() => setIsEditing(true)}
                >
                  Düzenle
                </Button>
              ) : (
                <>
                  <Button
                    type="primary"
                    icon={<SaveOutlined />}
                    onClick={confirmSave}
                    loading={saving}
                    className="me-2"
                  >
                    Kaydet
                  </Button>
                  <Button
                    type="default"
                    onClick={handleCancel}
                  >
                    İptal
                  </Button>
                </>
              )}
            </div>
          </div>

          {/* Hakkımızda Kartı */}
          <Card className="about-us-card mb-4">
            <div className="about-us-header mb-4">
              <div className="d-flex align-items-center">
                <div className="about-us-icon d-flex align-items-center justify-content-center bg-primary-light text-primary me-3" style={{ width: 50, height: 50, borderRadius: '50%' }}>
                  <InfoCircleOutlined style={{ fontSize: 24 }} />
                </div>
                <div>
                  <h3 className="mb-1">Şirket Tanıtımı</h3>
                  <p className="text-muted mb-0">Şirket hakkında temel bilgiler ve tanıtım</p>
                </div>
              </div>
            </div>

            {!aboutUs ? (
              <Empty
                description="Hakkımızda bilgisi bulunamadı"
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            ) : (
              <div className="about-us-content">
                {isEditing ? (
                  <Form form={form} layout="vertical">
                    <Form.Item 
                      name="content" 
                      label="Hakkımızda İçeriği"
                      rules={[{ required: true, message: 'Lütfen hakkımızda içeriğini giriniz!' }]}
                    >
                      <TextArea 
                        rows={10} 
                        placeholder="Şirket hakkında bilgileri buraya giriniz..." 
                        style={{ resize: 'vertical' }}
                      />
                    </Form.Item>
                  </Form>
                ) : (
                  <div className="about-us-view p-3 border rounded bg-light">
                    <pre style={{ whiteSpace: 'pre-wrap', fontFamily: 'inherit' }}>{aboutUs.content}</pre>
                  </div>
                )}
              </div>
            )}
          </Card>

          {isEditing && (
            <div className="alert alert-info">
              <div className="d-flex">
                <ExclamationCircleOutlined style={{ fontSize: '18px', marginRight: '8px' }} />
                <div>
                  <strong>Bilgi:</strong> Metin içeriğini düzenleyebilirsiniz. Değişiklikleri kaydetmek için "Kaydet" butonuna tıklayın.
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="footer d-sm-flex align-items-center justify-content-between border-top bg-white p-3">
          <FooterSection />
        </div>
      </div>
    </>
  );
};

export default AboutUsPage;
