import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import {
  Card,
  Row,
  Col,
  Button,
  Statistic,
  Table,
  Tag,
  Badge,
  Spin,
  Alert,
  Empty,
  Avatar,
  Progress,
  Typography,
  Tooltip,
  Skeleton,
  DatePicker,
  Select
} from "antd";
import {
  TeamOutlined,
  BankOutlined,
  BuildOutlined,
  ClockCircleOutlined,
  UserOutlined,
  HomeOutlined,
  DashboardOutlined,
  PlusOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  ExclamationCircleOutlined,
  ApartmentOutlined,
  SettingOutlined,
  BarChartOutlined,
  CalendarOutlined,
  RiseOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  PauseCircleOutlined,
  PhoneOutlined,
  MailOutlined
} from "@ant-design/icons";
import companyService from "../../../services/company/company.service";
import { Company, CompanyStatus } from "../../../types/company.types";
import moment from "moment";
import { handleApiError } from "../../../utils/errorHandler";
import { useTranslation } from "react-i18next";
import FooterSection from "../../shared/components/footerSection";
import "./styles.css";
// Grafikler için recharts kütüphanesini kullanacağız
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  LineChart,
  Line,
  Legend
} from 'recharts';

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

interface DashboardStats {
  totalCompanies: number;
  activeCompanies: number;
  inactiveCompanies: number;
  suspendedCompanies: number;
  totalEmployees: number;
  totalBranches: number;
  totalDepartments: number;
}

const SuperAdminDashboard: React.FC = () => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [recentCompanies, setRecentCompanies] = useState<Company[]>([]);
  const [stats, setStats] = useState<DashboardStats>({
    totalCompanies: 0,
    activeCompanies: 0,
    inactiveCompanies: 0,
    suspendedCompanies: 0,
    totalEmployees: 0,
    totalBranches: 0,
    totalDepartments: 0
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [timeRange, setTimeRange] = useState<string>('month');
  const { t } = useTranslation();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      setError(null);

      // Şirketleri getir
      const companiesData = await companyService.getAllCompanies();
      setCompanies(companiesData);

      // Son 5 şirketi al
      const sortedCompanies = [...companiesData].sort((a, b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      });
      setRecentCompanies(sortedCompanies.slice(0, 5));

      // İstatistikleri hesapla
      calculateStats(companiesData);
    } catch (error: any) {
      handleApiError(error, t);
      setError("Veriler yüklenirken bir hata oluştu.");
    } finally {
      setLoading(false);
    }
  };

  const calculateStats = (companies: Company[]) => {
    const newStats = {
      totalCompanies: companies.length,
      activeCompanies: companies.filter(c => c.status === CompanyStatus.ACTIVE).length,
      inactiveCompanies: companies.filter(c => c.status === CompanyStatus.INACTIVE).length,
      suspendedCompanies: companies.filter(c => c.status === CompanyStatus.SUSPENDED).length,
      totalEmployees: 0,
      totalBranches: 0,
      totalDepartments: 0
    };

    // Toplam çalışan, şube ve departman sayılarını hesapla
    companies.forEach(company => {
      newStats.totalEmployees += company.users?.length || 0;
      newStats.totalBranches += company.branches?.length || 0;
      newStats.totalDepartments += company.departments?.length || 0;
    });

    setStats(newStats);
  };

  const getStatusBadge = (status: CompanyStatus) => {
    switch (status) {
      case CompanyStatus.ACTIVE:
        return <Badge status="success" text="Aktif" />;
      case CompanyStatus.INACTIVE:
        return <Badge status="default" text="Pasif" />;
      case CompanyStatus.SUSPENDED:
        return <Badge status="error" text="Askıya Alınmış" />;
      default:
        return <Badge status="processing" text="Bilinmiyor" />;
    }
  };

  // Tarih aralığı değişimi için handler
  const handleTimeRangeChange = (value: string) => {
    setTimeRange(value);
    // Gerçek uygulamada burada seçilen aralığa göre veri çekilebilir
  };

  // Şirket durumu pasta grafik verileri
  const pieChartData = [
    { name: 'Aktif', value: stats.activeCompanies, color: '#52c41a' },
    { name: 'Pasif', value: stats.inactiveCompanies, color: '#8c8c8c' },
    { name: 'Askıya Alınmış', value: stats.suspendedCompanies, color: '#ff4d4f' },
  ];

  // Çalışan/Şube/Departman sayısı grafik verileri
  const barChartData = [
    { name: 'Çalışanlar', value: stats.totalEmployees, color: '#1890ff' },
    { name: 'Şubeler', value: stats.totalBranches, color: '#faad14' },
    { name: 'Departmanlar', value: stats.totalDepartments, color: '#722ed1' },
  ];

  // Aylık şirket artışı grafik verileri (örnek veri)
  const lineChartData = [
    { name: 'Oca', Aktif: 4, Pasif: 1, Total: 5 },
    { name: 'Şub', Aktif: 6, Pasif: 2, Total: 8 },
    { name: 'Mar', Aktif: 8, Pasif: 3, Total: 11 },
    { name: 'Nis', Aktif: 10, Pasif: 2, Total: 12 },
    { name: 'May', Aktif: 12, Pasif: 3, Total: 15 },
    { name: 'Haz', Aktif: 14, Pasif: 2, Total: 16 },
  ];

  const recentCompaniesColumns = [
    {
      title: 'Şirket',
      key: 'company',
      render: (text: string, record: Company) => (
        <div className="d-flex align-items-center">
          <Avatar 
            style={{ backgroundColor: colorFromString(record.name), marginRight: '10px' }}
            icon={<BuildOutlined />}
          />
          <div>
            <Link to={all_routes.superAdminCompanyDetails.replace(':id', record.id.toString())} className="fw-bold">
              {record.name}
            </Link>
            <div className="text-muted small">{record.taxNumber}</div>
          </div>
        </div>
      ),
    },
    {
      title: 'İletişim',
      key: 'contact',
      render: (text: string, record: Company) => (
        <div>
          {record.email && <div><MailOutlined className="me-1" /> {record.email}</div>}
          {record.phone && <div><PhoneOutlined className="me-1" /> {record.phone}</div>}
        </div>
      ),
    },
    {
      title: 'Durum',
      dataIndex: 'status',
      key: 'status',
      render: (status: CompanyStatus) => {
        const statusInfo = {
          [CompanyStatus.ACTIVE]: { icon: <CheckCircleOutlined />, text: 'Aktif', color: 'success' },
          [CompanyStatus.INACTIVE]: { icon: <CloseCircleOutlined />, text: 'Pasif', color: 'default' },
          [CompanyStatus.SUSPENDED]: { icon: <PauseCircleOutlined />, text: 'Askıya Alınmış', color: 'error' },
        };

        const info = statusInfo[status] || { icon: <ExclamationCircleOutlined />, text: 'Bilinmiyor', color: 'processing' };
        
        return (
          <Tag icon={info.icon} color={info.color as any}>
            {info.text}
          </Tag>
        );
      },
    },
    {
      title: 'Çalışan/Şube',
      key: 'stats',
      render: (text: string, record: Company) => (
        <div>
          <div><TeamOutlined className="me-1" /> {record.users?.length || 0} Çalışan</div>
          <div><HomeOutlined className="me-1" /> {record.branches?.length || 0} Şube</div>
        </div>
      ),
    },
    {
      title: 'Kayıt Tarihi',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date: Date) => (
        <div>
          <CalendarOutlined className="me-1" /> 
          {moment(date).format('DD.MM.YYYY')}
          <div className="text-muted small">{moment(date).fromNow()}</div>
        </div>
      ),
    },
  ];

  // String'den renk oluştur
  const colorFromString = (str: string): string => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    
    const c = (hash & 0x00FFFFFF)
      .toString(16)
      .toUpperCase();
    
    return '#' + '00000'.substring(0, 6 - c.length) + c;
  };

  if (loading) {
    return (
      <div className="page-wrapper vh-100 d-flex flex-column justify-content-center align-items-center">
        <Spin size="large" />
        <p className="mt-3">Veriler yükleniyor...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="page-wrapper vh-100 d-flex flex-column justify-content-center align-items-center">
        <Alert
          message="Hata"
          description={error}
          type="error"
          showIcon
        />
      </div>
    );
  }

  return (
    <>
      <div className="page-wrapper vh-100 d-flex flex-column justify-content-between">
        <div className="content flex-fill h-100">
          {/* Üst Başlık ve Filtre Bölümü */}
          <div className="dashboard-header mb-4">
            <div className="d-md-flex d-block align-items-center justify-content-between page-breadcrumb">
              <div className="my-auto mb-2">
                <Title level={3} className="mb-1">Süper Admin Paneli</Title>
                <Text type="secondary">
                  Hoş geldiniz! İşte şirketlerinizin genel durumu.
                </Text>
              </div>
              <div className="d-flex my-xl-auto right-content align-items-center">
                <Select 
                  defaultValue="month" 
                  style={{ width: 120 }} 
                  onChange={handleTimeRangeChange}
                  className="me-2"
                >
                  <Option value="week">Haftalık</Option>
                  <Option value="month">Aylık</Option>
                  <Option value="quarter">Çeyrek</Option>
                  <Option value="year">Yıllık</Option>
                </Select>
                <Link to={all_routes.superAdminCompanyCreate} className="btn btn-primary d-inline-flex align-items-center">
                  <PlusOutlined className="me-1" /> Yeni Şirket Ekle
                </Link>
              </div>
            </div>
          </div>

          {/* Özet İstatistik Kartları */}
          <Row gutter={16} className="mb-4">
            <Col xs={24} sm={12} md={8} lg={6}>
              <Card className="dashboard-stat-card" bordered={false}>
                <Statistic
                  title={<Text strong>Toplam Şirket</Text>}
                  value={stats.totalCompanies}
                  valueStyle={{ color: '#1890ff' }}
                  prefix={<BuildOutlined className="stats-icon bg-primary-light text-primary" />}
                  suffix={
                    <Tooltip title="Şirket başına ortalama çalışan sayısı">
                      <Tag color="blue">
                        {stats.totalCompanies > 0 
                          ? `${(stats.totalEmployees / stats.totalCompanies).toFixed(1)} çalışan/şirket` 
                          : '0'}
                      </Tag>
                    </Tooltip>
                  }
                />
                <div className="stat-footer">
                  <Progress 
                    percent={100} 
                    showInfo={false} 
                    strokeColor="#1890ff" 
                    size="small" 
                    status="active" 
                  />
                  <div className="d-flex justify-content-between align-items-center mt-2">
                    <Link to={all_routes.superAdminCompanies}>Tüm Şirketler</Link>
                    <RiseOutlined style={{ color: '#52c41a' }} />
                  </div>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Card className="dashboard-stat-card" bordered={false}>
                <Statistic
                  title={<Text strong>Aktif Şirketler</Text>}
                  value={stats.activeCompanies}
                  valueStyle={{ color: '#52c41a' }}
                  prefix={<CheckCircleOutlined className="stats-icon bg-success-light text-success" />}
                  suffix={
                    <Tooltip title="Aktif şirket oranı">
                      <Tag color="success">
                        {Math.round((stats.activeCompanies / stats.totalCompanies) * 100) || 0}%
                      </Tag>
                    </Tooltip>
                  }
                />
                <div className="stat-footer">
                  <Progress 
                    percent={stats.totalCompanies ? (stats.activeCompanies / stats.totalCompanies) * 100 : 0} 
                    showInfo={false} 
                    strokeColor="#52c41a"
                    size="small"
                  />
                  <div className="d-flex justify-content-between align-items-center mt-2">
                    <Text type="secondary">Aktif Durum</Text>
                    <ArrowUpOutlined style={{ color: '#52c41a' }} />
                  </div>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Card className="dashboard-stat-card" bordered={false}>
                <Statistic
                  title={<Text strong>Toplam Çalışan</Text>}
                  value={stats.totalEmployees}
                  valueStyle={{ color: '#722ed1' }}
                  prefix={<TeamOutlined className="stats-icon bg-purple-light text-purple" />}
                />
                <div className="stat-footer">
                  <Progress 
                    percent={100} 
                    showInfo={false} 
                    strokeColor="#722ed1" 
                    size="small" 
                  />
                  <div className="d-flex justify-content-between align-items-center mt-2">
                    <Text type="secondary">Tüm Şirketlerde</Text>
                    <Tooltip title="Aktif olarak çalışan personel sayısı">
                      <Tag color="purple">Aktif Çalışanlar</Tag>
                    </Tooltip>
                  </div>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Card className="dashboard-stat-card" bordered={false}>
                <Statistic
                  title={<Text strong>Toplam Şube</Text>}
                  value={stats.totalBranches}
                  valueStyle={{ color: '#faad14' }}
                  prefix={<HomeOutlined className="stats-icon bg-warning-light text-warning" />}
                />
                <div className="stat-footer">
                  <Progress 
                    percent={100} 
                    showInfo={false} 
                    strokeColor="#faad14" 
                    size="small" 
                  />
                  <div className="d-flex justify-content-between align-items-center mt-2">
                    <Text type="secondary">Tüm Şirketlerde</Text>
                    <Tooltip title="Sistemdeki toplam şube sayısı">
                      <Tag color="warning">Aktif Şubeler</Tag>
                    </Tooltip>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>

          {/* Grafik Bölümü */}
          <Row gutter={16} className="mb-4">
            <Col xs={24} lg={12}>
              <Card 
                title={<Title level={5}><BarChartOutlined /> Şirket Durumları</Title>} 
                className="dashboard-chart-card"
                bordered={false}
              >
                <div className="text-center mb-3">
                  <Text type="secondary">Toplam {stats.totalCompanies} şirket</Text>
                </div>
                <div className="chart-container" style={{ height: '250px' }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        dataKey="value"
                        data={pieChartData}
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={90}
                        paddingAngle={5}
                        label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                      >
                        {pieChartData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                      </Pie>
                      <RechartsTooltip formatter={(value) => [`${value} şirket`, '']} />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
                <div className="chart-legend mt-3">
                  <div className="d-flex justify-content-center">
                    {pieChartData.map((entry, index) => (
                      <div key={index} className="legend-item mx-2">
                        <div className="d-flex align-items-center">
                          <div 
                            className="legend-color me-1" 
                            style={{ backgroundColor: entry.color, width: 10, height: 10, borderRadius: '50%' }}
                          ></div>
                          <span>{entry.name}: {entry.value}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </Card>
            </Col>

            <Col xs={24} lg={12}>
              <Card 
                title={<Title level={5}><BarChartOutlined /> Şirket İstatistikleri</Title>} 
                className="dashboard-chart-card"
                bordered={false}
              >
                <div className="text-center mb-3">
                  <Text type="secondary">Toplam sayılar</Text>
                </div>
                <div className="chart-container" style={{ height: '250px' }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={barChartData}>
                      <CartesianGrid strokeDasharray="3 3" vertical={false} />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <RechartsTooltip formatter={(value) => [`${value}`, '']} />
                      <Bar dataKey="value" radius={[4, 4, 0, 0]}>
                        {barChartData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </Card>
            </Col>
          </Row>

          {/* Büyüme Grafiği */}
          <Row gutter={16} className="mb-4">
            <Col span={24}>
              <Card 
                title={<Title level={5}><BarChartOutlined /> Şirket Büyüme Grafiği</Title>}
                className="dashboard-chart-card"
                bordered={false}
                extra={
                  <div className="d-flex align-items-center">
                    <Text type="secondary" className="me-2">Periyot:</Text>
                    <Select defaultValue="month" style={{ width: 100 }}>
                      <Option value="month">Aylık</Option>
                      <Option value="quarter">Çeyrek</Option>
                      <Option value="year">Yıllık</Option>
                    </Select>
                  </div>
                }
              >
                <div className="chart-container" style={{ height: '250px' }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={lineChartData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <RechartsTooltip />
                      <Legend />
                      <Line type="monotone" dataKey="Total" stroke="#8884d8" activeDot={{ r: 8 }} strokeWidth={2} />
                      <Line type="monotone" dataKey="Aktif" stroke="#52c41a" />
                      <Line type="monotone" dataKey="Pasif" stroke="#ff4d4f" />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </Card>
            </Col>
          </Row>

          {/* Hızlı Erişim Kartları */}
          <Row gutter={16} className="mb-4">
            <Col span={24}>
              <Card 
                title={<Title level={5}>Hızlı Erişim</Title>} 
                className="quick-access-card"
                bordered={false}
              >
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={12} md={6}>
                    <Link to={all_routes.superAdminCompanies} className="quick-link-card">
                      <BuildOutlined className="quick-link-icon" />
                      <span>Şirketler</span>
                      <div className="quick-link-count">{stats.totalCompanies}</div>
                    </Link>
                  </Col>
                  <Col xs={24} sm={12} md={6}>
                    <Link to={all_routes.superAdminCompanyCreate} className="quick-link-card">
                      <PlusOutlined className="quick-link-icon" />
                      <span>Yeni Şirket</span>
                      <div className="quick-link-hint">Şirket Ekle</div>
                    </Link>
                  </Col>
                  <Col xs={24} sm={12} md={6}>
                    <Link to={all_routes.superAdminAboutUs} className="quick-link-card">
                      <DashboardOutlined className="quick-link-icon" />
                      <span>Hakkımızda</span>
                      <div className="quick-link-hint">İçerik Düzenle</div>
                    </Link>
                  </Col>
                  <Col xs={24} sm={12} md={6}>
                    <Link to="#" className="quick-link-card">
                      <SettingOutlined className="quick-link-icon" />
                      <span>Sistem Ayarları</span>
                      <div className="quick-link-hint">Ayarları Yönet</div>
                    </Link>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          {/* Son Eklenen Şirketler */}
          <Row gutter={16}>
            <Col span={24}>
              <Card 
                title={<Title level={5}>Son Eklenen Şirketler</Title>} 
                extra={<Link to={all_routes.superAdminCompanies}>Tümünü Gör</Link>}
                className="recent-companies-card"
                bordered={false}
              >
                {recentCompanies.length > 0 ? (
                  <Table 
                    dataSource={recentCompanies} 
                    columns={recentCompaniesColumns} 
                    rowKey="id" 
                    pagination={false}
                    className="modern-table"
                    rowClassName="table-row-hover"
                  />
                ) : (
                  <Empty description="Henüz şirket bulunmamaktadır" />
                )}
              </Card>
            </Col>
          </Row>
        </div>
        <div className="footer d-sm-flex align-items-center justify-content-between border-top bg-white p-3">
          <FooterSection />
        </div>
      </div>
    </>
  );
};

export default SuperAdminDashboard; 