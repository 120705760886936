import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { all_routes } from '../../../../router/all_routes';
import { useEmployeeLeave } from '../../../../hooks/useEmployeeLeave';
import { LeaveDto, LeaveStatus, LeaveUserUpdateDTO } from '../../../../types/leaves.types';
import { EmployeeLeaveStats } from '../components/EmployeeLeaveStats';
import { EmployeeLeaveTable } from '../components/EmployeeLeaveTable';
import EmployeeLeaveFilters from '../components/EmployeeLeaveFilter';
import EmployeeLeaveModal from '../../../shared/modals/employeeLeaveModal2';
import { exportToPdf, exportToExcel } from '../../../../utils/exportUtils';
import LoadingSpinner from '../../../shared/uiInterface/base-ui/LoadingSpinner';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import FooterSection from '../../../shared/components/footerSection';
const LeaveEmployee2 = () => {
  const {
    leaves,
    loading,
    error,
    stats,
    selectedType,
    selectedStatus,
    dateRange,
    setSelectedType,
    setSelectedStatus,
    setDateRange,
    fetchLeaves,
    deleteLeave
  } = useEmployeeLeave();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLeave, setSelectedLeave] = useState<LeaveDto | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    fetchLeaves();
  }, [fetchLeaves]);

  const handleModalSuccess = () => {
    setIsModalOpen(false);
    fetchLeaves();
  };

  const handleReset = () => {
    setSelectedType('all');
    setSelectedStatus('all');
    setDateRange(null);
    fetchLeaves();
  };

  const handleDateRangeChange = (dates: [string, string] | null) => {
    setDateRange(dates);
    if (dates) {
      fetchLeaves(dates[0], dates[1]);
    } else {
      fetchLeaves();
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        {/* Header */}
        <div className="d-md-flex d-block align-items-center justify-content-between page-breadcrumb mb-3">
          <div className="my-auto mb-2">
            <h2 className="mb-1">{t('leaveEmployee.pageTitle')}</h2>
            <nav>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to={all_routes.employeeDashboard}>
                    <i className="ti ti-smart-home" />
                  </Link>
                </li>
                <li className="breadcrumb-item">{t('leaveEmployee.breadcrumb.employee')}</li>
                <li className="breadcrumb-item active" aria-current="page">
                  {t('leaveEmployee.breadcrumb.leaves')}
                </li>
              </ol>
            </nav>
          </div>
          <div className="d-flex my-xl-auto right-content align-items-center">
            <button
              className="btn btn-sm btn-primary d-inline-flex align-items-center me-2"
              onClick={() => {
                setSelectedLeave(null);
                setIsModalOpen(true);
              }}
            >
              <i className="ti ti-plus me-1" />
              {t('leaveEmployee.newLeaveRequest')}
            </button>
            <div className="dropdown">
              <Link
                to="#"
                className="dropdown-toggle btn btn-sm btn-white d-inline-flex align-items-center"
                data-bs-toggle="dropdown"
              >
                <i className="ti ti-file-export me-1" />
                {t('common.buttons.export')}
              </Link>
              <ul className="dropdown-menu dropdown-menu-end p-3">
                <li>
                  <Link
                    to="#"
                    className="dropdown-item rounded-1"
                    onClick={() => {
                      // İzinler için sütun tanımları
                      type LeaveDataType = {
                        leaveType: string;
                        startDate: string;
                        endDate: string;
                        duration: string;
                        status: string;
                        reason: string;
                      };

                      const columnsForPdf = [
                        { header: 'Izin Turu', property: 'leaveType' as keyof LeaveDataType, width: 40 },
                        { header: 'Baslangic Tarihi', property: 'startDate' as keyof LeaveDataType, width: 30 },
                        { header: 'Bitis Tarihi', property: 'endDate' as keyof LeaveDataType, width: 30 },
                        { header: 'Sure', property: 'duration' as keyof LeaveDataType, width: 35 },
                        { header: 'Durum', property: 'status' as keyof LeaveDataType, width: 55 },
                        { header: 'Aciklama', property: 'reason' as keyof LeaveDataType, width: 90 }
                      ];

                      // İzin durumunu Türkçe olarak almak için yardımcı fonksiyon
                      const getStatusText = (status: LeaveStatus): string => {
                        switch (status) {
                          case LeaveStatus.APPROVED:
                            return 'Onaylandi';
                          case LeaveStatus.PENDING:
                            return 'Beklemede';
                          case LeaveStatus.REJECTED:
                            return 'Reddedildi';
                          // CANCELLED durumu LeaveStatus enum'ında yoksa kullanmıyoruz
                          // case LeaveStatus.CANCELLED:
                          //   return 'İptal Edildi';
                          default:
                            return 'Bilinmiyor';
                        }
                      };

                      // PDF için formatlanmış veri
                      const formattedData = leaves.map(leave => {
                        // İki tarih arasındaki gün sayısını hesapla
                        const startDate = dayjs(leave.startDate);
                        const endDate = dayjs(leave.endDate);
                        const daysDiff = endDate.diff(startDate, 'day') + 1; // Bitiş günü dahil
                        
                        return {
                          leaveType: leave.type || '-',
                          startDate: startDate.format('DD.MM.YYYY'),
                          endDate: endDate.format('DD.MM.YYYY'),
                          duration: `${daysDiff} gun`,
                          status: getStatusText(leave.status),
                          reason: leave.reason || '-'
                        };
                      });

                      // İstatistik bilgileri
                      const additionalInfo = [
                        { label: 'Toplam Izin', value: leaves.length.toString() },
                        { label: 'Onaylanan', value: leaves.filter(l => l.status === LeaveStatus.APPROVED).length.toString() },
                        { label: 'Bekleyen', value: leaves.filter(l => l.status === LeaveStatus.PENDING).length.toString() },
                        { label: 'Reddedilen', value: leaves.filter(l => l.status === LeaveStatus.REJECTED).length.toString() }
                      ];

                      // Filtre bilgisini içeren açıklama metni
                      let description = 'Izin listesi';
                      
                      if (selectedType !== 'all') {
                        // Seçili tipteki ilk iznin type değerini al
                        const typeText = leaves.find(l => l.type && l.type.toString() === selectedType)?.type;
                        if (typeText) description += ` - Tur: ${typeText}`;
                      }
                      
                      if (selectedStatus !== 'all') {
                        const statusText = getStatusText(selectedStatus as LeaveStatus);
                        description += ` - Durum: ${statusText}`;
                      }

                      if (dateRange) {
                        description += ` - Tarih: ${dayjs(dateRange[0]).format('DD.MM.YYYY')} - ${dayjs(dateRange[1]).format('DD.MM.YYYY')}`;
                      }

                      // Yeni formatla exportToPdf fonksiyonunu çağır
                      exportToPdf(
                        formattedData, 
                        'Izin Listesi', 
                        {
                          columns: columnsForPdf,
                          description: description,
                          headerColor: '#9933ff', // açık mor
                          accentColor: '#9933ff', // açık mor
                          companyName: 'DINOX',
                          showDate: true,
                          additionalInfo: additionalInfo
                        }
                      );
                    }}
                  >
                    <i className="ti ti-file-type-pdf me-1" />
                    {t('common.buttons.pdf')}
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className="dropdown-item rounded-1"
                    onClick={() => {
                      // Excel için sütun tanımları
                      type LeaveExcelType = {
                        id: number;
                        leaveType: string;
                        startDate: string;
                        endDate: string;
                        duration: string;
                        status: string;
                        reason: string;
                        createdAt: string;
                      };

                      const columnsForExcel = [
                        { header: 'İzin No', property: 'id' as keyof LeaveExcelType, width: 10 },
                        { header: 'İzin Türü', property: 'leaveType' as keyof LeaveExcelType, width: 25 },
                        { header: 'Başlangıç Tarihi', property: 'startDate' as keyof LeaveExcelType, width: 20 },
                        { header: 'Bitiş Tarihi', property: 'endDate' as keyof LeaveExcelType, width: 20 },
                        { header: 'Süre', property: 'duration' as keyof LeaveExcelType, width: 15 },
                        { header: 'Durum', property: 'status' as keyof LeaveExcelType, width: 15 },
                        { header: 'Açıklama', property: 'reason' as keyof LeaveExcelType, width: 40 },
                        { header: 'Oluşturulma Tarihi', property: 'createdAt' as keyof LeaveExcelType, width: 20 }
                      ];

                      // İzin durumunu Türkçe olarak almak için yardımcı fonksiyon
                      const getStatusText = (status: LeaveStatus): string => {
                        switch (status) {
                          case LeaveStatus.APPROVED:
                            return 'Onaylandı';
                          case LeaveStatus.PENDING:
                            return 'Beklemede';
                          case LeaveStatus.REJECTED:
                            return 'Reddedildi';
                          default:
                            return 'Bilinmiyor';
                        }
                      };

                      // Excel için formatlanmış veri
                      const formattedData = leaves.map(leave => {
                        // İki tarih arasındaki gün sayısını hesapla
                        const startDate = dayjs(leave.startDate);
                        const endDate = dayjs(leave.endDate);
                        const daysDiff = endDate.diff(startDate, 'day') + 1; // Bitiş günü dahil
                        
                        return {
                          id: leave.id,
                          leaveType: leave.type || '-',
                          startDate: startDate.format('DD.MM.YYYY'),
                          endDate: endDate.format('DD.MM.YYYY'),
                          duration: `${daysDiff} gün`,
                          status: getStatusText(leave.status),
                          reason: leave.reason || '-',
                          createdAt: leave.createdAt ? dayjs(leave.createdAt).format('DD.MM.YYYY HH:mm') : '-'
                        };
                      });

                      // Excel dosyasını oluştur
                      exportToExcel(formattedData, 'İzin Listesi', columnsForExcel);
                    }}
                  >
                    <i className="ti ti-file-type-xls me-1" />
                    {t('common.buttons.excel')}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Stats */}
        <EmployeeLeaveStats stats={stats} />

        {/* Table Card */}
        <div className="card">
          {dateRange ? (
            <div className="card-body border-bottom pt-2 pb-2 bg-light">
              <div className="d-flex align-items-center">
                <i className="ti ti-calendar-event fs-16 me-2 text-primary"></i>
                <span className="fw-semibold text-muted me-1">{t('common.labels.dateRange')}:</span>
                <span className="text-dark fw-bold">
                  {dayjs(dateRange[0]).format('DD MMMM YYYY')} - {dayjs(dateRange[1]).format('DD MMMM YYYY')}
                </span>
              </div>
            </div>
          ) : (
            <div className="card-body border-bottom pt-2 pb-2 bg-light">
              <div className="d-flex align-items-center">
                <i className="ti ti-calendar-event fs-16 me-2 text-primary"></i>
                <span className="fw-semibold text-muted me-1">{t('common.labels.dateRange')}:</span>
                <span className="text-dark fw-bold">
                  {dayjs().subtract(2, 'month').startOf('day').format('DD MMMM YYYY')} - {dayjs().add(2, 'month').endOf('day').format('DD MMMM YYYY')}
                </span>
              </div>
            </div>
          )}
          <div className="card-header d-flex align-items-center justify-content-end flex-wrap row-gap-3">
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap row-gap-3">
              <EmployeeLeaveFilters
                selectedType={selectedType}
                selectedStatus={selectedStatus}
                selectedDateRange={dateRange}
                onTypeChange={setSelectedType}
                onStatusChange={setSelectedStatus}
                onDateRangeChange={handleDateRangeChange}
                onReset={handleReset}
              />
            </div>
          </div>

          <div className="card-body">
            {loading ? (
              <LoadingSpinner size="lg" color="primary" centered />
            ) : (
              <EmployeeLeaveTable
                leaves={leaves}
                loading={loading}
                onEdit={(leave) => {
                  setSelectedLeave(leave);
                  setIsModalOpen(true);
                }}
                onDelete={deleteLeave}
              />
            )}
          </div>
        </div>
        <div className="footer d-sm-flex align-items-center justify-content-between border-top bg-white p-3">
            <FooterSection />
        </div>
        {/* Modal */}
        <EmployeeLeaveModal
          leaveDataForEdit={selectedLeave as LeaveUserUpdateDTO | null}
          onSuccess={handleModalSuccess}
          open={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
        />
      </div>
    </div>
  );
};

export default LeaveEmployee2;