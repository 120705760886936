import React, { useEffect, useState } from 'react';
import { 
  Card, Form, Switch, InputNumber, Button, message, Skeleton, 
  Row, Col, TimePicker, Checkbox, Tabs, Space,
  Typography
} from 'antd';
import { 
  SaveOutlined, 
  CalendarOutlined,
  UserOutlined,
  BellOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { CompanySettings } from '../../../types/company.types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import CommonSelect, { Option } from '../../../core/common/commonSelect';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import './settings.css';
import companyService from '../../../services/company/company.service';
import { handleApiError } from '../../../utils/errorHandler';
import FooterSection from '../../shared/components/footerSection';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
const { Title, Text } = Typography;
const { TabPane } = Tabs;

const CompanySettingsPage: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [shiftMode, setShiftMode] = useState<string>('all');
  const { user } = useSelector((state: RootState) => state.auth);
  const [lateTolerance, setLateTolerance] = useState(false);
  const [fixedShiftDays, setFixedShiftDays] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState('shift-settings');
  const { t } = useTranslation();

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      if (!user?.companyId) return;
      const settings = await companyService.getCompanySettings(user.companyId);

      const formattedSettings = {
        ...settings,
        fixedShiftStartTime: settings.fixedShiftStartTime ? moment(settings.fixedShiftStartTime, 'HH:mm') : null,
        fixedShiftEndTime: settings.fixedShiftEndTime ? moment(settings.fixedShiftEndTime, 'HH:mm') : null,
        fixedShiftDays: settings.fixedShiftDays || ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
      };
      
      form.setFieldsValue(formattedSettings);
      if (settings.shiftMode) {
        setShiftMode(settings.shiftMode);
      }
      
      // Gün seçimi state'ini ayarla
      if (settings.fixedShiftDays) {
        setFixedShiftDays(settings.fixedShiftDays);
      } else {
        // Varsayılan olarak hafta içi günleri seçili getir
        setFixedShiftDays(['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']);
      }
      
      // lateTolerance değerini de ayarla
      if (settings.lateTolerance) {
        setLateTolerance(settings.lateTolerance);
      }
    } catch (error: any) {
      handleApiError(error,t);
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (values: CompanySettings) => {
    try {
      setSaving(true);
      if (!user?.companyId) return;
      
      // Form verilerini API'ye göndermeden önce düzenliyoruz
      const formattedValues = {
        ...values,
        fixedShiftStartTime: values.fixedShiftStartTime && moment.isMoment(values.fixedShiftStartTime) 
          ? values.fixedShiftStartTime.format('HH:mm') 
          : values.fixedShiftStartTime,
        fixedShiftEndTime: values.fixedShiftEndTime && moment.isMoment(values.fixedShiftEndTime) 
          ? values.fixedShiftEndTime.format('HH:mm') 
          : values.fixedShiftEndTime,
      };
      
      await companyService.updateCompanySettings(user.companyId, formattedValues);
      message.success(t('companySettings.saveSettingsSuccess'));
    } catch (error: any) {
      handleApiError(error,t);
    } finally {
      setSaving(false);
    }
  };

  const handleShiftModeChange = (selected: Option) => {
    setShiftMode(selected.value as string);
    // Vardiya tipi değiştiğinde ilgili alanları sıfırla
    if (selected.value === 'fixed' || selected.value === 'both') {
      form.setFieldsValue({
        fixedShiftStartTime: null,
        fixedShiftEndTime: null
      });
    }
  };

  if (loading) {
    return (
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Card>
            <Skeleton active paragraph={{ rows: 10 }} />
          </Card>
        </div>
      </div>
    );
  }

  const renderFormActions = () => (
    <div className="settings-actions">
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={saving}
          icon={<SaveOutlined />}
          size="large"
          className="save-settings-btn"
        >
          {t('companySettings.saveSettings')}
        </Button>
      </Form.Item>
    </div>
  );

  const renderTooltip = (text: string) => (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={`tooltip-${text.substring(0, 10)}`}>{text}</Tooltip>}
    >
      <QuestionCircleOutlined className="info-icon" style={{ marginLeft: 4, cursor: 'pointer' }} />
    </OverlayTrigger>
  );

  return (
    <div className="page-wrapper settings-page">
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center mb-3">
            <div className="col">
              <Title level={3} className="m-0">
                <Space>
                  {t('companySettings.pageTitle')}
                  {/* <Badge count={Object.keys(form.getFieldsValue()).length} color="blue" /> */}
                </Space>
              </Title>
              <div className="breadcrumb-container mt-2">
                <Text className="breadcrumb-text">{t('companySettings.breadcrumb.management')} / </Text>
                <Text className="breadcrumb-text active">{t('companySettings.breadcrumb.companySettings')}</Text>
              </div>
            </div>
          </div>
        </div>

        <Card className="settings-card">
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={{
              workOnHolidays: false,
              workOnWeekends: false,
              includeManagerInReports: false,
              maxDailyWorkHours: true,
              lateTolerance: false,
              lateTolerationMinutes: 5,
              earlyLeave: true,
              includeWeekendsInAnnualLeave: true,
              sendLeaveRequestEmails: false,
              requirePreApprovalForLeave: true,
              annualLeaveLimit: 23,
              shiftMode: 'fixed',
              ipControl: false
            }}
            className="settings-form"
          >
            <Tabs 
              activeKey={activeTab} 
              onChange={setActiveTab} 
              tabPosition="left" 
              size="large"
              className="settings-tabs"
            >
              {/* <TabPane 
                tab={
                  <Space>
                    <ClockCircleOutlined />
                    {t('companySettings.workingTimeSettings')}
                  </Space>
                } 
                key="working-time"
              >
                <div className="tab-content">
                  <Title level={4} className="section-title">
                    <ClockCircleOutlined /> {t('companySettings.workingTimeSettings')}
                  </Title>
                  <Text type="secondary" className="section-description">
                    {t('companySettings.workingTimeSettingsDescription')}
                  </Text>
                  
                  <div className="settings-section">
                    <Row gutter={[24, 24]} className="mt-4">
                      <Col xs={24} sm={12} md={8}>
                        <Card className="setting-card">
                          <Form.Item
                            label={
                              <Space>
                                {t('companySettings.workOnHolidays')}
                                {renderTooltip(t('companySettings.workOnHolidaysHelp'))}
                              </Space>
                            }
                            name="workOnHolidays"
                            valuePropName="checked"
                          >
                            <Switch />
                          </Form.Item>
                        </Card>
                      </Col>

                      <Col xs={24} sm={12} md={8}>
                        <Card className="setting-card">
                          <Form.Item
                            label={
                              <Space>
                                {t('companySettings.workOnWeekends')}
                                {renderTooltip(t('companySettings.workOnWeekendsHelp'))}
                              </Space>
                            }
                            name="workOnWeekends"
                            valuePropName="checked"
                          >
                            <Switch />
                          </Form.Item>
                        </Card>
                      </Col>

                      <Col xs={24} sm={12} md={8}>
                        <Card className="setting-card">
                          <Form.Item
                            label={
                              <Space>
                                {t('companySettings.maxDailyHoursControl')}
                                {renderTooltip(t('companySettings.maxDailyHoursControlHelp'))}
                              </Space>
                            }
                            name="maxDailyWorkHours"
                            valuePropName="checked"
                          >
                            <Switch />
                          </Form.Item>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </div>
              </TabPane> */}

              <TabPane 
                tab={
                  <Space>
                    <CalendarOutlined />
                    {t('companySettings.fixedShiftSettings')}
                  </Space>
                } 
                key="shift-settings"
              >
                <div className="tab-content">
                  <Title level={4} className="section-title">
                    <CalendarOutlined /> {t('companySettings.fixedShiftSettings')}
                  </Title>
                  <Text type="secondary" className="section-description">
                    {t('companySettings.fixedShiftSettingsDescription')}
                  </Text>
                  
                  <div className="settings-section">
                    <Row gutter={[24, 24]} className="mt-4">
                      <Col xs={24} sm={12} md={8}>
                        <Card className="setting-card shift-type-card">
                          <Form.Item
                            label={
                              <Space>
                                {t('companySettings.shiftType')}
                                {renderTooltip(t('companySettings.shiftTypeHelp'))}
                              </Space>
                            }
                            name="shiftMode"
                            rules={[{ required: true, message: t('companySettings.shiftTypeError') }]}
                          >
                            <CommonSelect
                              options={[
                                { value: 'fixed', label: t('companySettings.days.fixed') },
                                { value: 'assignment', label: t('companySettings.days.assignment') },
                                { value: 'both', label: t('companySettings.days.both') }
                              ]}
                              onChange={handleShiftModeChange}
                            />
                          </Form.Item>
                        </Card>
                      </Col>

                      {(shiftMode === 'fixed' || shiftMode === 'both') && (
                        <>
                          <Col xs={24}>
                            <Card className="setting-card time-settings-card">
                              <Row gutter={16}>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    label={
                                      <Space>
                                        {t('companySettings.fixedShiftStartTime')}
                                        {renderTooltip(t('companySettings.fixedShiftStartTimeHelp'))}
                                      </Space>
                                    }
                                    name="fixedShiftStartTime"
                                    rules={[{ required: true, message: t('companySettings.fixedShiftStartTimeError') }]}
                                  >
                                    <TimePicker 
                                      format="HH:mm"
                                      minuteStep={5}
                                      use12Hours={false}
                                      placeholder={t('companySettings.fixedShiftStartTime')}
                                      style={{ width: '100%' }}
                                    />
                                  </Form.Item>
                                </Col>

                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    label={
                                      <Space>
                                        {t('companySettings.fixedShiftEndTime')}
                                        {renderTooltip(t('companySettings.fixedShiftEndTimeHelp'))}
                                      </Space>
                                    }
                                    name="fixedShiftEndTime"
                                    rules={[{ required: true, message: t('companySettings.fixedShiftEndTimeError') }]}
                                  >
                                    <TimePicker 
                                      format="HH:mm"
                                      minuteStep={5}
                                      use12Hours={false}
                                      placeholder={t('companySettings.fixedShiftEndTime')}
                                      style={{ width: '100%' }}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Card>
                          </Col>

                          <Col xs={24}>
                            <Card className="setting-card shift-days-card">
                              <Form.Item
                                label={
                                  <Space>
                                    {t('companySettings.fixedShiftDays')}
                                    {renderTooltip(t('companySettings.fixedShiftDaysHelp'))}
                                  </Space>
                                }
                                name="fixedShiftDays"
                                rules={[{ required: true, message: t('companySettings.fixedShiftDaysError') }]}
                              >
                                <Checkbox.Group
                                  style={{ width: '100%' }}
                                  onChange={(checkedValues) => setFixedShiftDays(checkedValues as string[])}
                                >
                                  <Row gutter={[16, 16]} className="days-checkbox-group">
                                    {[
                                      { value: 'Monday', label: t('companySettings.days.monday') },
                                      { value: 'Tuesday', label: t('companySettings.days.tuesday') },
                                      { value: 'Wednesday', label: t('companySettings.days.wednesday') },
                                      { value: 'Thursday', label: t('companySettings.days.thursday') },
                                      { value: 'Friday', label: t('companySettings.days.friday') },
                                      { value: 'Saturday', label: t('companySettings.days.saturday') },
                                      { value: 'Sunday', label: t('companySettings.days.sunday') }
                                    ].map(day => (
                                      <Col xs={12} sm={8} md={6} key={day.value}>
                                        <Checkbox value={day.value} className="day-checkbox">
                                          {day.label}
                                        </Checkbox>
                                      </Col>
                                    ))}
                                  </Row>
                                </Checkbox.Group>
                              </Form.Item>
                            </Card>
                          </Col>
                        </>
                      )}
                    </Row>
                  </div>
                </div>
              </TabPane>

              <TabPane 
                tab={
                  <Space>
                    <UserOutlined />
                    {t('companySettings.attendanceControlSettings')}
                  </Space>
                } 
                key="attendance-control"
              >
                <div className="tab-content">
                  <Title level={4} className="section-title">
                    <UserOutlined /> {t('companySettings.attendanceControlSettings')}
                  </Title>
                  <Text type="secondary" className="section-description">
                    {t('companySettings.attendanceControlSettingsDescription')}
                  </Text>
                  
                  <div className="settings-section">
                    <Row gutter={[24, 24]} className="mt-4">
                      <Col xs={24} sm={12} md={8}>
                        <Card className="setting-card">
                          <Form.Item
                            label={
                              <Space>
                                {t('companySettings.lateTolerance')}
                                {renderTooltip(t('companySettings.lateToleranceHelp'))}
                              </Space>
                            }
                            name="lateTolerance"
                            valuePropName="checked"
                          >
                            <Switch onChange={(checked) => setLateTolerance(checked)} />
                          </Form.Item>
                        </Card>
                      </Col>

                      {lateTolerance && (
                        <Col xs={24} sm={12} md={8}>
                          <Card className="setting-card">
                            <Form.Item
                              label={
                                <Space>
                                  {t('companySettings.lateTolerationMinutes')}
                                  {renderTooltip(t('companySettings.lateTolerationMinutesHelp'))}
                                </Space>
                              }
                              name="lateTolerationMinutes"
                              rules={[{ required: true, message: t('companySettings.lateTolerationMinutesError') }]}
                            >
                              <InputNumber min={0} max={60} style={{ width: '100%' }} />
                            </Form.Item>
                          </Card>
                        </Col>
                      )}

                      <Col xs={24} sm={12} md={8}>
                        <Card className="setting-card">
                            <Form.Item
                              label={
                                <Space>
                                  {t('companySettings.ipAddresses')}
                                  {renderTooltip(t('companySettings.ipAddressesHelp'))}
                                </Space>
                              }
                              name="ipControl"
                              valuePropName="checked"
                            >
                              <Switch/>
                            </Form.Item>
                          </Card>
                        </Col>
                    </Row>
                  </div>
                </div>
              </TabPane>

              <TabPane 
                tab={
                  <Space>
                    <CalendarOutlined />
                    {t('companySettings.leaveSettings')}
                  </Space>
                } 
                key="leave-settings"
              >
                <div className="tab-content">
                  <Title level={4} className="section-title">
                    <CalendarOutlined /> {t('companySettings.leaveSettings')}
                  </Title>
                  <Text type="secondary" className="section-description">
                    {t('companySettings.leaveSettingsDescription')}
                  </Text>
                  
                  <div className="settings-section">
                    <Row gutter={[24, 24]} className="mt-4">
                      <Col xs={24} sm={12} md={8}>
                        <Card className="setting-card">
                          <Form.Item
                            label={
                              <Space>
                                {t('companySettings.annualLeaveLimit')}
                                {renderTooltip(t('companySettings.annualLeaveLimitHelp'))}
                              </Space>
                            }
                            name="annualLeaveLimit"
                            rules={[{ required: true, message: t('companySettings.annualLeaveLimitError') }]}
                          >
                            <InputNumber min={0} max={365} style={{ width: '100%' }} />
                          </Form.Item>
                        </Card>
                      </Col>

                      {/* <Col xs={24} sm={12} md={8}>
                        <Card className="setting-card">
                          <Form.Item
                            label={
                              <Space>
                                {t('companySettings.includeWeekendsInLeave')}
                                {renderTooltip(t('companySettings.includeWeekendsInLeaveHelp'))}
                              </Space>
                            }
                            name="includeWeekendsInAnnualLeave"
                            valuePropName="checked"
                          >
                            <Switch />
                          </Form.Item>
                        </Card>
                      </Col> */}

                      {/* <Col xs={24} sm={12} md={8}>
                        <Card className="setting-card">
                          <Form.Item
                            label={
                              <Space>
                                {t('companySettings.preApprovalForLeave')}
                                {renderTooltip(t('companySettings.preApprovalForLeaveHelp'))}
                              </Space>
                            }
                            name="requirePreApprovalForLeave"
                            valuePropName="checked"
                          >
                            <Switch />
                          </Form.Item>
                        </Card>
                      </Col> */}
                    </Row>
                  </div>
                </div>
              </TabPane>

              <TabPane 
                tab={
                  <Space>
                    <BellOutlined />
                    {t('companySettings.notificationSettings')}
                  </Space>
                } 
                key="notification-settings"
              >
                <div className="tab-content">
                  <Title level={4} className="section-title">
                    <BellOutlined /> {t('companySettings.notificationSettings')}
                  </Title>
                  <Text type="secondary" className="section-description">
                    {t('companySettings.notificationSettingsDescription')}
                  </Text>
                  
                  <div className="settings-section">
                    <Row gutter={[24, 24]} className="mt-4">
                      <Col xs={24} sm={12} md={8}>
                        <Card className="setting-card">
                          <Form.Item
                            label={
                              <Space>
                                {t('companySettings.leaveRequestEmails')}
                                {renderTooltip(t('companySettings.leaveRequestEmailsHelp'))}
                              </Space>
                            }
                            name="sendMailLeave"
                            valuePropName="checked"
                          >
                            <Switch />
                          </Form.Item>
                        </Card>
                      </Col>
                      <Col xs={24} sm={12} md={8}>
                        <Card className="setting-card">
                          <Form.Item
                            label={
                              <Space>
                                {t('companySettings.ticketRequestEmails')}
                                {renderTooltip(t('companySettings.ticketRequestEmailsHelp'))}
                              </Space>
                            }
                            name="sendMailTicket"
                            valuePropName="checked"
                          >
                            <Switch />
                          </Form.Item>
                        </Card>
                      </Col>
                      <Col xs={24} sm={12} md={8}>
                        <Card className="setting-card">
                          <Form.Item
                            label={
                              <Space>
                                {t('companySettings.excuseEmails')}
                                {renderTooltip(t('companySettings.excuseEmailsHelp'))}
                              </Space>
                            }
                            name="sendMailExcuse"
                            valuePropName="checked"
                          >
                            <Switch />
                          </Form.Item>
                        </Card>
                      </Col>

                      {/* <Col xs={24} sm={12} md={8}>
                        <Card className="setting-card">
                          <Form.Item
                            label={
                              <Space>
                                {t('companySettings.includeManagerInReports')}
                                {renderTooltip(t('companySettings.includeManagerInReportsHelp'))}
                              </Space>
                            }
                            name="includeManagerInReports"
                            valuePropName="checked"
                          >
                            <Switch />
                          </Form.Item>
                        </Card>
                      </Col> */}
                    </Row>
                  </div>
                </div>
              </TabPane>
            </Tabs>

            {renderFormActions()}
          </Form>
        </Card>
      </div>
      <div className="footer d-sm-flex align-items-center justify-content-between border-top bg-white p-3">
            <FooterSection />
        </div>
    </div>
  );
};

export default CompanySettingsPage; 