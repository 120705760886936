import React from 'react';
import Table from '../../../shared/dataTable/index';
import { LeaveDto } from '../../../../types/leaves.types';
import { LEAVE_TYPES, LEAVE_STATUSES } from '../../../../constants/leaveConstants';
import { Popconfirm } from 'antd';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import LoadingSpinner from '../../../shared/uiInterface/base-ui/LoadingSpinner';
import { getFileUrl } from '../../../../utils/file.utils';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
interface EmployeeLeaveTableProps {
  leaves: LeaveDto[];
  loading: boolean;
  onEdit: (leave: LeaveDto) => void;
  onDelete: (id: number) => void;
}

export const EmployeeLeaveTable: React.FC<EmployeeLeaveTableProps> = ({
  leaves,
  loading,
  onEdit,
  onDelete
}) => {
  const { t } = useTranslation();
  // Verileri updatedAt'e göre sırala (en son güncellenen en üstte)
  const sortedLeaves = [...leaves].sort((a, b) => {
    const dateA = a.updatedAt ? new Date(a.updatedAt).getTime() : 0;
    const dateB = b.updatedAt ? new Date(b.updatedAt).getTime() : 0;
    return dateB - dateA; // Azalan sırada sıralama (en yeni en üstte)
  });

  const columns = [
    {
      title: t('leaveEmployee.leaveType'),
      dataIndex: "type",
      render: (_: string, record: LeaveDto) => (
        <div className="d-flex align-items-center">
          <p className="fs-14 fw-medium mb-0">
            {t(`leaveTypes.${record.type}`)}
          </p>
          {record.reason && (
            <span
              className="ms-2"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              title={record.reason}
            >
              <i className="ti ti-info-circle text-info" />
            </span>
          )}
        </div>
      ),
    },
    {
      title: t('leaveEmployee.date'),
      dataIndex: "startDate",
      render: (_: string, record: LeaveDto) => (
        record.type === 'hourly' ? (
          <p className="fs-14 mb-0">
            {dayjs(record.startDate).format('DD.MM.YYYY')}
            <br />
            <small className="text-muted">
              {record.startTime} - {record.endTime}
            </small>
          </p>
        ) : (
          <p className="fs-14 mb-0">
            {dayjs(record.startDate).format('DD.MM.YYYY')} -
            <br />
            {dayjs(record.endDate).format('DD.MM.YYYY')}
          </p>
        )
      ),
    },
    {
      title: t('leaveEmployee.statusInfo'),
      dataIndex: "status",
      render: (_: string, record: LeaveDto) => (
        <div className="d-inline-flex align-items-center">
          <span className={`rounded-circle ${record.status === 'approved' ? 'bg-transparent-success' : record.status === 'pending' ? 'bg-transparent-purple' : 'bg-transparent-danger'} d-flex justify-content-center align-items-center me-2`}>
            <i className={`ti ti-point-filled ${record.status === 'approved' ? 'text-success' : record.status === 'pending' ? 'text-purple' : 'text-danger'}`} />
          </span>
          {record.status === 'approved' ? t('leaves.statusOptions.approved') : record.status === 'pending' ? t('leaves.statusOptions.pending') : t('leaves.statusOptions.rejected')}
          {/* {record.approvedBy && (
            <small className="text-muted ms-2">({record.approvedBy})</small>
          )} */}
        </div>
      ),
    },
    {
      title: t('leaveEmployee.description'),
      dataIndex: "reason",
      render: (reason: string) => (
        reason.length > 30 ? (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip title={reason} placement="top">{reason}</Tooltip>}
        >
          <p 
            className="fs-14 mb-0" 
            style={{ 
              maxWidth: "200px", 
              whiteSpace: "nowrap", 
              overflow: "auto", 
              scrollbarWidth: "none",
              position: "relative"
            }}
          >
            {reason}
            {reason.length > 30 && (
              <button 
                type="button" 
                className="btn btn-primary" 
                style={{ 
                  position: "absolute", 
                  right: 0, 
                  top: 0, 
                  width: "20px",
                  height: "100%"
                }}
              />
            )}
          </p>
        </OverlayTrigger>

      ) : (
        <p className="fs-14 mb-0">{reason}</p>
      )
      )
    },
    {
      title: t('leaveEmployee.lastUpdate'),
      dataIndex: "updatedAt",
      render: (_: string, record: LeaveDto) => (
        <p className="fs-14 mb-0">
          {record.updatedAt ? dayjs(record.updatedAt).format('DD.MM.YYYY HH:mm') : '-'}
        </p>
      ),
      sorter: (a: LeaveDto, b: LeaveDto) => {
        const dateA = a.updatedAt ? new Date(a.updatedAt).getTime() : 0;
        const dateB = b.updatedAt ? new Date(b.updatedAt).getTime() : 0;
        return dateA - dateB;
      },
      defaultSortOrder: 'descend',
    },
    {
      title: t('leaveEmployee.file'),
      dataIndex: "file",
      render: (_: string, record: LeaveDto) => (
        <p className="fs-14 mb-0">
          {record.documentUrl ?  
          <a 
            className="dropdown-item" 
            href={getFileUrl(record.documentUrl)} 
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('leaveEmployee.view')}
          </a> : '-'}
        </p>
      ),
    },
    
    {
      title: "",
      key: "actions",
      render: (_: string, record: LeaveDto) => (
        record.status === 'pending' && (
          <div className="d-flex gap-2">
            <button
              className="btn btn-sm btn-primary"
              onClick={() => onEdit(record)}
            >
              <i className="ti ti-edit" />
            </button>
            {/* {onDelete && (
              <Popconfirm
                title="İzin talebini silmek istediğinize emin misiniz?"
                onConfirm={() => onDelete(record.id)}
                okText="Evet"
                cancelText="Hayır"
              >
                <button className="btn btn-sm btn-danger">
                  <i className="ti ti-trash" />
                </button>
              </Popconfirm>
            )} */}
          </div>
        )
      ),
    },
  ];

  if (loading) {
    return (
      <LoadingSpinner size="lg" color="primary" centered />
    );
  }

  return (
    <Table
      dataSource={sortedLeaves}
      columns={columns}
      Selection={false}
    />
  );
};

export default EmployeeLeaveTable;

