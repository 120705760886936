import React, { useState } from 'react';
import { Form, Input, Select, Switch } from 'antd';
import { FormInstance } from 'antd/lib/form';
import CommonSelect from '../../../../../core/common/commonSelect';
import { UserRole } from '../../../../../types/user.types';
import { useTranslation } from 'react-i18next';
interface SecurityStepProps {
  form: FormInstance;
  isEdit?: boolean;
}

const SecurityStep: React.FC<SecurityStepProps> = ({ form, isEdit }) => {
  const { t } = useTranslation();
  const [passwordVisible, setPasswordVisible] = useState(false);

  const passwordRules = [
    { required: !isEdit, message: t('employees.validationErrors.password') },
    { min: 6, message: t('employees.validationErrors.passwordError') }
  ];
  // const renderSecurityTab = () => {
    return (
      <div className="row">
        
        {!isEdit&&(
          <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">{t('employees.modals.password')}</label>
            <Form.Item
              name="password"
              rules={passwordRules}
            >
              <Input.Password
                visibilityToggle={passwordVisible}
                onChange={(e) => form.setFieldValue('password', e.target.value)}
              />
            </Form.Item>
          </div>
        </div>
        )}
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">{t('employees.modals.role')}</label>
            <Form.Item
              name="role"
              rules={[{ required: true, message: t('employees.validationErrors.role') }]}
            >
            <Select
              placeholder={t('employees.modals.role')}
              options={[
                { value: UserRole.ADMIN, label: t('employees.modals.admin') },
                { value: UserRole.USER, label: t('employees.modals.employee') },
                { value: UserRole.MANAGER, label: t('employees.modals.manager') }
              ]}
            />
          </Form.Item>
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">{t('employees.modals.title')}</label>
              <Form.Item
              name="title"
              rules={[{ required: true, message: t('employees.validationErrors.title') }]}
            >
              <Input />
            </Form.Item>
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">{t('employees.modals.isVerified')}</label>
              <Form.Item
              name="isVerified"
            >
              <Switch />
            </Form.Item>
          </div>
        </div>
      </div>
    );
  };
// };

export default SecurityStep; 