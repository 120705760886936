import { Company, CreateCompanyDto, UpdateCompanyDto, CompanySettings, AnnualLeaveLimitResponse, AboutUs } from "../../types/company.types";
import apiService from "../api/api.service";
// API rotaları

class CompanyService {
    private static instance: CompanyService;
    
    public static getInstance(): CompanyService {
        if (!CompanyService.instance) {
            CompanyService.instance = new CompanyService();
        }
        return CompanyService.instance;
    }
    
    // Private constructor
    private constructor() {}

    public async getCompanySettings(companyId: number): Promise<CompanySettings> {
        const response = await apiService.get<CompanySettings>(`/companies/${companyId}/settings`);
        return response;
    }

    public async updateCompanySettings(companyId: number, settings: CompanySettings): Promise<CompanySettings> {
        const response = await apiService.put<CompanySettings>(`/companies/${companyId}/settings`, settings);
        return response;
    }
    
    public async getAnnualLimit(companyId: number): Promise<AnnualLeaveLimitResponse> {
        const response = await apiService.get<AnnualLeaveLimitResponse>(`/companies/annual-leave-limit/${companyId}`);
        return response;
    }

    /**
     * Tüm şirketleri getirir
     * @returns Şirket listesi
     */
    public async getAllCompanies(): Promise<Company[]> {
        try {
            const response = await apiService.get<Company[]>(`/companies`);
            return response;
        } catch (error) {
            console.error("Şirketler alınırken hata oluştu:", error);
            throw error;
        }
    }

    /**
     * Belirli bir şirketi ID'ye göre getirir
     * @param id Şirket ID
     * @returns Şirket nesnesi
     */
    public async getCompanyById(id: number): Promise<Company> {
        try {
            const response = await apiService.get<Company>(`/companies/${id}`);
            return response;
        } catch (error) {
            console.error(`${id} ID'li şirket alınırken hata oluştu:`, error);
            throw error;
        }
    }

    /**
     * Yeni bir şirket oluşturur
     * @param companyData Şirket bilgileri
     * @returns Oluşturulan şirket nesnesi
     */
    public async createCompany(companyData: CreateCompanyDto): Promise<Company> {
        try {
            const response = await apiService.post<Company>(`/companies`, companyData);
            return response;
        } catch (error) {
            console.error("Şirket oluşturulurken hata oluştu:", error);
            throw error;
        }
    }

    /**
     * Mevcut bir şirketi günceller
     * @param id Şirket ID
     * @param companyData Güncellenecek şirket bilgileri
     * @returns Güncellenen şirket nesnesi
     */
    public async updateCompany(id: number, companyData: UpdateCompanyDto): Promise<Company> {
        try {
            const response = await apiService.put<Company>(`/companies/${id}`, companyData);
            return response;
        } catch (error) {
            console.error(`${id} ID'li şirket güncellenirken hata oluştu:`, error);
            throw error;
        }
    }

    /**
     * Bir şirketi siler
     * @param id Silinecek şirket ID
     * @returns İşlem sonucu
     */
    public async deleteCompany(id: number): Promise<any> {
        try {
            const response = await apiService.delete<any>(`/companies/${id}`);
            return response;
        } catch (error) {
            console.error(`${id} ID'li şirket silinirken hata oluştu:`, error);
            throw error;
        }
    }

    /**
     * Şirketin durumunu değiştirir (aktif/pasif/askıya alınmış)
     * @param id Şirket ID
     * @param status Yeni durum değeri
     * @returns Güncellenen şirket nesnesi
     */
    public async changeCompanyStatus(id: number, status: string): Promise<Company> {
        try {
            const response = await apiService.patch<Company>(`/companies/${id}/status`, { status });
            return response;
        } catch (error) {
            console.error(`${id} ID'li şirket durumu değiştirilirken hata oluştu:`, error);
            throw error;
        }
    }

    public async getAboutUs(): Promise<AboutUs> {
        const response = await apiService.get<AboutUs>(`/settings/about`);
        return response;
    }

    public async updateAboutUs(aboutUs: AboutUs): Promise<AboutUs> {
        const response = await apiService.put<AboutUs>(`/settings/about`, aboutUs);
        return response;
    }
}

export default CompanyService.getInstance();