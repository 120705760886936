import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import {
  Card,
  Row,
  Col,
  Button,
  Tabs,
  Badge,
  Descriptions,
  Statistic,
  Divider,
  Tag,
  Tooltip,
  Space,
  Spin,
  Alert,
  Modal,
  message,
  Empty,
  Table,
  Form,
  Input,
  Select,
  Avatar,
  DatePicker
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  BuildOutlined,
  UserOutlined,
  TeamOutlined,
  HomeOutlined,
  BankOutlined,
  CalendarOutlined,
  PhoneOutlined,
  MailOutlined,
  EnvironmentOutlined,
  SettingOutlined,
  ClockCircleOutlined,
  ExclamationCircleOutlined,
  ArrowLeftOutlined,
  ApartmentOutlined
} from "@ant-design/icons";
import companyService from "../../../services/company/company.service";
import { Company, CompanyStatus } from "../../../types/company.types";
import CollapseHeader from "../../../core/common/collapse-header/collapse-header";
import moment from "moment";
import "./styles.css";
import { handleApiError } from "../../../utils/errorHandler";
import { useTranslation } from "react-i18next";
import FooterSection from "../../shared/components/footerSection";
import { UserCreateDTO, UserRole } from "../../../types/user.types";
import userService from "../../../services/user/user.service";
const { TabPane } = Tabs;
const { confirm } = Modal;

const CompanyDetails = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [company, setCompany] = useState<Company | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [isAddAdminModalVisible, setIsAddAdminModalVisible] = useState<boolean>(false);
  const [addingAdmin, setAddingAdmin] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { t } = useTranslation(); 
  const [selectedAdmin, setSelectedAdmin] = useState<any>(null);
  const [adminDetailModalVisible, setAdminDetailModalVisible] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [userDetailModalVisible, setUserDetailModalVisible] = useState<boolean>(false);

  useEffect(() => {
    fetchCompany();
  }, [id]);

  const fetchCompany = async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await companyService.getCompanyById(Number(id));
      setCompany(data);
    } catch (error: any) {
      handleApiError(error,t);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = () => {
    confirm({
      title: "Bu şirketi silmek istediğinize emin misiniz?",
      icon: <ExclamationCircleOutlined />,
      content: "Bu işlem geri alınamaz!",
      okText: "Evet",
      okType: "danger",
      cancelText: "Hayır",
      onOk: async () => {
        try {
          await companyService.deleteCompany(Number(id));
          message.success("Şirket başarıyla silindi.");
          navigate(all_routes.superAdminCompanies);
        } catch (error: any) {
          handleApiError(error,t);
        }
      },
    });
  };

  const getStatusBadge = (status: CompanyStatus) => {
    switch (status) {
      case CompanyStatus.ACTIVE:
        return <Badge status="success" text="Aktif" />;
      case CompanyStatus.INACTIVE:
        return <Badge status="default" text="Pasif" />;
      case CompanyStatus.SUSPENDED:
        return <Badge status="error" text="Askıya Alınmış" />;
      default:
        return <Badge status="processing" text="Bilinmiyor" />;
    }
  };

  // Admin kullanıcılarını filtrele
  const adminUsers = company?.users?.filter(user => user.role === UserRole.ADMIN) || [];
  const hasAdminUsers = adminUsers.length > 0;

  // Yeni admin kullanıcısı ekleme
  const handleAddAdmin = async (values: any) => {
    try {
      setAddingAdmin(true);
      const createData: UserCreateDTO = {
        ...values,
        password: values.password,
        role: UserRole.ADMIN,
        companyId: Number(id),
        department: values.department || null, 
        branch: values.branch || null,
        shift: values.shift || null,
        annualLeaveLimit: values.annualLeaveLimit || null,
        hireDate: values.hireDate || new Date()
      };

      console.log(createData);
      

  
      await userService.createUser(createData);
      message.success("Admin kullanıcısı başarıyla eklendi.");
      setIsAddAdminModalVisible(false);
      form.resetFields();
      fetchCompany(); // Şirket verilerini yeniden yükle
    } catch (error: any) {
      handleApiError(error, t);
    } finally {
      setAddingAdmin(false);
    }
  };

  // Kullanıcı iznini Türkçe olarak gösterme
  const getPermissionName = (permission: string) => {
    const permissionMap: {[key: string]: string} = {
      'control_panel_admin': 'Kontrol Paneli Erişimi',
      'view_users': 'Kullanıcıları Görüntüleme',
      'edit_users': 'Kullanıcıları Düzenleme',
      'view_departments': 'Departmanları Görüntüleme',
      'edit_departments': 'Departmanları Düzenleme',
      'view_branches': 'Şubeleri Görüntüleme',
      'edit_branches': 'Şubeleri Düzenleme',
      'export': 'Veri Dışa Aktarma',
      'edit_leave': 'İzinleri Düzenleme',
      'view_leave': 'İzinleri Görüntüleme',
      'view_reports': 'Raporları Görüntüleme',
      'manage_shifts': 'Vardiyaları Yönetme',
      'view_salaries': 'Maaşları Görüntüleme',
      'company_settings': 'Şirket Ayarları',
      'create_notifications': 'Bildirim Oluşturma',
      'view_tickets_admin': 'Destek Taleplerini Görüntüleme',
      'edit_tickets': 'Destek Taleplerini Düzenleme'
    };
    
    return permissionMap[permission] || permission;
  };

  // Admin detaylarını göster
  const showAdminDetail = (admin: any) => {
    setSelectedAdmin(admin);
    setAdminDetailModalVisible(true);
  };

  // Kullanıcı detaylarını göster
  const showUserDetail = (user: any) => {
    setSelectedUser(user);
    setUserDetailModalVisible(true);
  };

  // Admin kullanıcı izinlerini göster
  const renderPermissions = (permissions: string[]) => {
    if (!permissions || permissions.length === 0) {
      return <Empty description="İzin bulunamadı" image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    }

    return (
      <div className="permission-tags">
        {permissions.map(permission => (
          <Tag color="blue" key={permission} className="mb-1 me-1">
            {getPermissionName(permission)}
          </Tag>
        ))}
      </div>
    );
  };

  const adminColumns = [
    {
      title: '',
      dataIndex: 'profilePicture',
      key: 'profilePicture',
      width: 60,
      render: (profilePic: string, record: any) => (
        <Avatar 
          src={profilePic ? `/uploads/${profilePic}` : null} 
          icon={!profilePic ? <UserOutlined /> : null}
        />
      ),
    },
    {
      title: 'Ad Soyad',
      key: 'fullName',
      render: (text: string, record: any) => (
        <div>
          <div className="fw-bold">{record.firstName} {record.lastName}</div>
          <div className="text-muted small">{record.title || '-'}</div>
        </div>
      ),
    },
    {
      title: 'İletişim Bilgileri',
      key: 'contact',
      render: (text: string, record: any) => (
        <div>
          <div><MailOutlined className="me-1" /> {record.email}</div>
          <div><PhoneOutlined className="me-1" /> {record.phone || '-'}</div>
        </div>
      ),
    },
    {
      title: 'Durum',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => {
        let badgeStatus: any = 'default';
        let text = 'Bilinmiyor';
        
        if (status === 'active') {
          badgeStatus = 'success';
          text = 'Aktif';
        } else if (status === 'inactive') {
          badgeStatus = 'default';
          text = 'Pasif';
        } else if (status === 'suspended') {
          badgeStatus = 'error';
          text = 'Askıya Alınmış';
        }
        
        return <Badge status={badgeStatus} text={text} />;
      },
    },
    {
      title: 'İşe Başlama',
      dataIndex: 'hireDate',
      key: 'hireDate',
      render: (date: string) => date ? moment(date).format('DD.MM.YYYY') : '-',
    },
    {
      title: 'İşlemler',
      key: 'actions',
      render: (text: string, record: any) => (
        <Space size="small">
          <Button type="primary" size="small" onClick={() => showAdminDetail(record)}>
            Detay
          </Button>
        </Space>
      ),
    }
  ];

  const usersColumns = [
    {
      title: '',
      dataIndex: 'profilePicture',
      key: 'profilePicture',
      width: 60,
      render: (profilePic: string, record: any) => (
        <Avatar 
          src={profilePic ? `/uploads/${profilePic}` : null} 
          icon={!profilePic ? <UserOutlined /> : null}
        />
      ),
    },
    {
      title: 'Ad Soyad',
      key: 'fullName',
      render: (text: string, record: any) => (
        <div>
          <div className="fw-bold">{record.firstName} {record.lastName}</div>
          <div className="text-muted small">{record.title || '-'}</div>
        </div>
      ),
    },
    {
      title: 'İletişim Bilgileri',
      key: 'contact',
      render: (text: string, record: any) => (
        <div>
          <div><MailOutlined className="me-1" /> {record.email}</div>
          <div><PhoneOutlined className="me-1" /> {record.phone || '-'}</div>
        </div>
      ),
    },
    {
      title: 'Rol',
      dataIndex: 'role',
      key: 'role',
      render: (role: string) => {
        const roleMap: { [key: string]: { color: string, text: string } } = {
          'admin': { color: 'red', text: 'Admin' },
          'manager': { color: 'orange', text: 'Yönetici' },
          'supervisor': { color: 'blue', text: 'Sorumlu' },
          'employee': { color: 'green', text: 'Çalışan' }
        };
        
        const roleInfo = roleMap[role?.toLowerCase()] || { color: 'default', text: role || 'Bilinmiyor' };
        
        return <Tag color={roleInfo.color}>{roleInfo.text}</Tag>;
      }
    },
    {
      title: 'Durum',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => {
        let badgeStatus: any = 'default';
        let text = 'Bilinmiyor';
        
        if (status === 'active') {
          badgeStatus = 'success';
          text = 'Aktif';
        } else if (status === 'inactive') {
          badgeStatus = 'default';
          text = 'Pasif';
        } else if (status === 'suspended') {
          badgeStatus = 'error';
          text = 'Askıya Alınmış';
        }
        
        return <Badge status={badgeStatus} text={text} />;
      },
    },
    {
      title: 'İşlemler',
      key: 'actions',
      render: (text: string, record: any) => (
        <Space size="small">
          <Button type="primary" size="small" onClick={() => showUserDetail(record)}>
            Detay
          </Button>
        </Space>
      ),
    }
  ];

  const branchesColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 60,
    },
    {
      title: 'Şube Adı',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Adres',
      dataIndex: 'address',
      key: 'address',
    },
  ];

  const departmentsColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 60,
    },
    {
      title: 'Departman Adı',
      dataIndex: 'name',
      key: 'name',
    },
  ];

  if (loading) {
    return (
      <div className="page-wrapper vh-100 d-flex flex-column justify-content-center align-items-center">
        <Spin size="large" />
        <p className="mt-3">Şirket bilgileri yükleniyor...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="page-wrapper vh-100 d-flex flex-column justify-content-center align-items-center">
        <Alert
          message="Hata"
          description={error}
          type="error"
          showIcon
          action={
            <Button type="primary" onClick={() => navigate(all_routes.superAdminCompanies)}>
              Şirketlere Dön
            </Button>
          }
        />
      </div>
    );
  }

  if (!company) {
    return (
      <div className="page-wrapper vh-100 d-flex flex-column justify-content-center align-items-center">
        <Empty
          description="Şirket bulunamadı"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
        <Button
          type="primary"
          onClick={() => navigate(all_routes.superAdminCompanies)}
          style={{ marginTop: 16 }}
        >
          Şirketlere Dön
        </Button>
      </div>
    );
  }

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper vh-100 d-flex flex-column justify-content-between">
        <div className="content flex-fill h-100">
          {/* Breadcrumb */}
          <div className="d-md-flex d-block align-items-center justify-content-between page-breadcrumb mb-3">
            <div className="my-auto mb-2">
              <h2 className="mb-1">Şirket Detayları</h2>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={all_routes.superAdminDashboard}>
                      <i className="ti ti-smart-home" />
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={all_routes.superAdminCompanies}>Şirketler</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {company.name}
                  </li>
                </ol>
              </nav>
            </div>
            <div className="ms-2">
              <Link
                to={all_routes.superAdminCompanies}
                className="btn btn-outline-primary me-2"
              >
                <ArrowLeftOutlined /> Geri
              </Link>
              <Link
                to={`${all_routes.superAdminCompanyEdit.replace(':id', id?.toString() || '')}`}
                className="btn btn-primary me-2"
              >
                <EditOutlined /> Düzenle
              </Link>
              <Button
                type="primary"
                danger
                icon={<DeleteOutlined />}
                onClick={handleDelete}
              >
                Sil
              </Button>
            </div>
          </div>
          {/* /Breadcrumb */}

          {/* Şirket Bilgileri Kartı */}
          <Card className="company-details-card mb-4">
            <div className="details-header">
              <div className="d-flex">
                <div className="company-logo d-flex align-items-center justify-content-center bg-light">
                  <BuildOutlined style={{ fontSize: 36 }} />
                </div>
                <div className="company-info">
                  <h2 className="company-name">
                    {company.name} <span>{getStatusBadge(company.status)}</span>
                  </h2>
                  <div className="company-meta">
                    <span>
                      <BankOutlined className="me-1" /> Vergi No: {company.taxNumber}
                    </span>
                    <span className="ms-3">
                      <BankOutlined className="me-1" /> Vergi Dairesi: {company.taxOffice}
                    </span>
                  </div>
                  <div className="company-meta">
                    <span>
                      <CalendarOutlined className="me-1" /> Kayıt Tarihi:{" "}
                      {moment(company.createdAt).format("DD.MM.YYYY")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            
            <Row gutter={[16, 16]}>
              <Col xs={24} lg={8}>
                <Card className="company-stats-card">
                  <Statistic
                    title="Çalışan Sayısı"
                    value={company.users?.length || 0}
                    prefix={<TeamOutlined className="stats-icon bg-primary-light text-primary" />}
                  />
                </Card>
              </Col>
              <Col xs={24} lg={8}>
                <Card className="company-stats-card">
                  <Statistic
                    title="Şube Sayısı"
                    value={company.branches?.length || 0}
                    prefix={<HomeOutlined className="stats-icon bg-success-light text-success" />}
                  />
                </Card>
              </Col>
              <Col xs={24} lg={8}>
                <Card className="company-stats-card">
                  <Statistic
                    title="Departman Sayısı"
                    value={company.departments?.length || 0}
                    prefix={<ApartmentOutlined className="stats-icon bg-warning-light text-warning" />}
                  />
                </Card>
              </Col>
            </Row>
          </Card>

          {/* Sekme Sistemi */}
          <Card className="company-details-card">
            <Tabs defaultActiveKey="info">
              <TabPane tab={<span><UserOutlined /> Genel Bilgiler</span>} key="info">
                <Descriptions bordered column={{ xs: 1, sm: 2, md: 3 }} layout="vertical">
                  <Descriptions.Item label="Şirket Adı" span={3}>
                    {company.name}
                  </Descriptions.Item>
                  
                  <Descriptions.Item label="E-posta">
                    {company.email || "-"}
                  </Descriptions.Item>
                  
                  <Descriptions.Item label="Telefon">
                    {company.phone || "-"}
                  </Descriptions.Item>
                  
                  <Descriptions.Item label="Durum">
                    {getStatusBadge(company.status)}
                  </Descriptions.Item>
                  
                  <Descriptions.Item label="Adres" span={3}>
                    {company.address || "-"}
                  </Descriptions.Item>
                  
                  <Descriptions.Item label="Vergi No">
                    {company.taxNumber}
                  </Descriptions.Item>
                  
                  <Descriptions.Item label="Vergi Dairesi">
                    {company.taxOffice}
                  </Descriptions.Item>
                  
                  <Descriptions.Item label="Varsayılan Mola Süresi">
                    {company.defaultBreakDuration} dakika
                  </Descriptions.Item>
                  
                  <Descriptions.Item label="Kayıt Tarihi">
                    {moment(company.createdAt).format("DD.MM.YYYY HH:mm")}
                  </Descriptions.Item>
                  
                  <Descriptions.Item label="Son Güncelleme">
                    {moment(company.updatedAt).format("DD.MM.YYYY HH:mm")}
                  </Descriptions.Item>
                </Descriptions>
                
                <Divider orientation="left">Şirket Ayarları</Divider>
                
                <Descriptions bordered column={{ xs: 1, sm: 2, md: 3 }} layout="vertical">
                  <Descriptions.Item label="Resmi Tatillerde Çalışma">
                    {company.settings?.workOnHolidays ? (
                      <Badge status="success" text="Evet" />
                    ) : (
                      <Badge status="default" text="Hayır" />
                    )}
                  </Descriptions.Item>
                  
                  <Descriptions.Item label="Hafta Sonları Çalışma">
                    {company.settings?.workOnWeekends ? (
                      <Badge status="success" text="Evet" />
                    ) : (
                      <Badge status="default" text="Hayır" />
                    )}
                  </Descriptions.Item>
                  
                  <Descriptions.Item label="Geç Kalma Toleransı">
                    {company.settings?.lateTolerance ? (
                      <Badge status="success" text={`Evet (${company.settings.lateTolerationMinutes} dakika)`} />
                    ) : (
                      <Badge status="default" text="Hayır" />
                    )}
                  </Descriptions.Item>
                  
                  <Descriptions.Item label="İzin Talepleri için E-posta">
                    {company.settings?.sendLeaveRequestEmails ? (
                      <Badge status="success" text="Evet" />
                    ) : (
                      <Badge status="default" text="Hayır" />
                    )}
                  </Descriptions.Item>
                  
                  <Descriptions.Item label="IP Kontrolü">
                    {company.settings?.ipControl ? (
                      <Badge status="success" text="Evet" />
                    ) : (
                      <Badge status="default" text="Hayır" />
                    )}
                  </Descriptions.Item>
                  
                  <Descriptions.Item label="Yıllık İzin Limiti">
                    {company.settings?.annualLeaveLimit || 20} gün
                  </Descriptions.Item>
                </Descriptions>
                
                <Divider orientation="left">Vardiya Ayarları</Divider>
                
                <Descriptions bordered column={{ xs: 1, sm: 2 }} layout="vertical">
                  <Descriptions.Item label="Vardiya Modu">
                    {company.settings?.shiftMode === "assignment" && "Atama Bazlı"}
                    {company.settings?.shiftMode === "fixed" && "Sabit"}
                    {company.settings?.shiftMode === "both" && "Her İkisi"}
                  </Descriptions.Item>
                  
                  <Descriptions.Item label="Sabit Vardiya Kullanımı">
                    {company.settings?.useFixedShift ? (
                      <Badge status="success" text="Evet" />
                    ) : (
                      <Badge status="default" text="Hayır" />
                    )}
                  </Descriptions.Item>
                  
                  {company.settings?.useFixedShift && (
                    <>
                      <Descriptions.Item label="Sabit Vardiya Günleri" span={2}>
                        {company.settings?.fixedShiftDays?.map((day) => (
                          <Tag key={day} color="blue">
                            {day === "Monday" && "Pazartesi"}
                            {day === "Tuesday" && "Salı"}
                            {day === "Wednesday" && "Çarşamba"}
                            {day === "Thursday" && "Perşembe"}
                            {day === "Friday" && "Cuma"}
                            {day === "Saturday" && "Cumartesi"}
                            {day === "Sunday" && "Pazar"}
                          </Tag>
                        ))}
                      </Descriptions.Item>
                      
                      <Descriptions.Item label="Sabit Vardiya Başlangıç Saati">
                        {company.settings?.fixedShiftStartTime || "-"}
                      </Descriptions.Item>
                      
                      <Descriptions.Item label="Sabit Vardiya Bitiş Saati">
                        {company.settings?.fixedShiftEndTime || "-"}
                      </Descriptions.Item>
                    </>
                  )}
                </Descriptions>
              </TabPane>
              
              <TabPane tab={<span><TeamOutlined /> Admin Kullanıcılar ({adminUsers.length})</span>} key="admins">
                <div className="d-flex justify-content-end mb-3">
                  <Button 
                    type="primary" 
                    onClick={() => setIsAddAdminModalVisible(true)}
                    disabled={hasAdminUsers} // Eğer zaten admin kullanıcı varsa buton devre dışı
                  >
                    {hasAdminUsers ? 'Admin Kullanıcı Mevcut' : 'Admin Kullanıcı Ekle'}
                  </Button>
                </div>
                
                {adminUsers.length > 0 ? (
                  <>
                    <Alert 
                      message="Admin Kullanıcılar" 
                      description="Bu şirkete atanmış admin kullanıcıları aşağıda listelenmektedir. Admin kullanıcılar şirket için tam yetkiye sahiptir."
                      type="info" 
                      showIcon 
                      className="mb-3"
                    />
                    <Table
                      dataSource={adminUsers}
                      columns={adminColumns}
                      rowKey="id"
                      pagination={false}
                    />
                  </>
                ) : (
                  <Empty description="Bu şirkete atanmış admin kullanıcısı bulunmuyor" />
                )}
              </TabPane>
              
              <TabPane tab={<span><TeamOutlined /> Çalışanlar ({company.users?.length || 0})</span>} key="users">
                {company.users && company.users.length > 0 ? (
                  <Table
                    dataSource={company.users}
                    columns={usersColumns}
                    rowKey="id"
                    pagination={{
                      defaultPageSize: 10,
                    }}
                  />
                ) : (
                  <Empty description="Bu şirkete kayıtlı çalışan bulunmuyor" />
                )}
              </TabPane>
              
              <TabPane tab={<span><HomeOutlined /> Şubeler ({company.branches?.length || 0})</span>} key="branches">
                {company.branches && company.branches.length > 0 ? (
                  <Table
                    dataSource={company.branches}
                    columns={branchesColumns}
                    rowKey="id"
                    pagination={{
                      defaultPageSize: 10,
                    }}
                  />
                ) : (
                  <Empty description="Bu şirkete kayıtlı şube bulunmuyor" />
                )}
              </TabPane>
              
              <TabPane tab={<span><ApartmentOutlined /> Departmanlar ({company.departments?.length || 0})</span>} key="departments">
                {company.departments && company.departments.length > 0 ? (
                  <Table
                    dataSource={company.departments}
                    columns={departmentsColumns}
                    rowKey="id"
                    pagination={{
                      defaultPageSize: 10,
                    }}
                  />
                ) : (
                  <Empty description="Bu şirkete kayıtlı departman bulunmuyor" />
                )}
              </TabPane>
            </Tabs>
          </Card>
        </div>
        <div className="footer d-sm-flex align-items-center justify-content-between border-top bg-white p-3">
            <FooterSection />
        </div>

        {/* Admin Kullanıcı Ekleme Modal */}
        <Modal
          title="Admin Kullanıcı Ekle"
          visible={isAddAdminModalVisible}
          onCancel={() => setIsAddAdminModalVisible(false)}
          footer={[
            <Button key="cancel" onClick={() => setIsAddAdminModalVisible(false)}>
              İptal
            </Button>,
            <Button 
              key="submit" 
              type="primary" 
              loading={addingAdmin} 
              onClick={() => form.submit()}
            >
              Ekle
            </Button>,
          ]}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={handleAddAdmin}
          >
            <Form.Item
              name="firstName"
              label="Ad"
              rules={[{ required: true, message: 'Lütfen kullanıcı adını girin' }]}
            >
              <Input placeholder="Kullanıcı adı" />
            </Form.Item>
            
            <Form.Item
              name="lastName"
              label="Soyad"
              rules={[{ required: true, message: 'Lütfen kullanıcı soyadını girin' }]}
            >
              <Input placeholder="Kullanıcı soyadı" />
            </Form.Item>
            
            <Form.Item
              name="email"
              label="E-posta"
              rules={[
                { required: true, message: 'Lütfen e-posta adresini girin' },
                { type: 'email', message: 'Geçerli bir e-posta adresi girin' }
              ]}
            >
              <Input placeholder="E-posta adresi" />
            </Form.Item>
            
            <Form.Item
              name="phone"
              label="Telefon"
            >
              <Input placeholder="Telefon numarası" />
            </Form.Item>
            
            <Form.Item
              name="password"
              label="Şifre"
              rules={[
                { required: true, message: 'Lütfen şifre girin' },
                { min: 6, message: 'Şifre en az 6 karakter olmalıdır' }
              ]}
            >
              <Input.Password placeholder="Şifre" />
            </Form.Item>
            
            <Form.Item
              name="confirmPassword"
              label="Şifre Tekrar"
              dependencies={['password']}
              rules={[
                { required: true, message: 'Lütfen şifreyi tekrar girin' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Şifreler eşleşmiyor!'));
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Şifre tekrar" />
            </Form.Item>

            <Form.Item name="hireDate" label="İşe Başlama Tarihi" rules={[{ required: true }]}>
              <DatePicker format="DD.MM.YYYY" />
            </Form.Item>
          </Form>
        </Modal>

        {/* Admin Kullanıcı Detay Modal */}
        <Modal
          title={selectedAdmin ? `${selectedAdmin.firstName} ${selectedAdmin.lastName} - Admin Detayları` : 'Admin Detayları'}
          visible={adminDetailModalVisible}
          onCancel={() => setAdminDetailModalVisible(false)}
          width={900}
          footer={[
            <Button key="close" onClick={() => setAdminDetailModalVisible(false)}>
              Kapat
            </Button>
          ]}
        >
          {selectedAdmin && (
            <div className="admin-details">
              <Row gutter={[16, 24]}>
                <Col xs={24} sm={6} className="text-center">
                  <Avatar 
                    size={100} 
                    src={selectedAdmin.profilePicture ? `/uploads/${selectedAdmin.profilePicture}` : null}
                    icon={!selectedAdmin.profilePicture ? <UserOutlined /> : null}
                  />
                  <div className="mt-2 fw-bold">{selectedAdmin.firstName} {selectedAdmin.lastName}</div>
                  <div className="text-muted">{selectedAdmin.title || '-'}</div>
                  <Tag color={selectedAdmin.status === 'active' ? 'success' : 'default'} className="mt-2">
                    {selectedAdmin.status === 'active' ? 'Aktif' : 'Pasif'}
                  </Tag>
                </Col>
                
                <Col xs={24} sm={18}>
                  <Descriptions title="Kişisel Bilgiler" column={{ xs: 1, sm: 2 }} size="small" bordered>
                    <Descriptions.Item label="E-posta">{selectedAdmin.email}</Descriptions.Item>
                    <Descriptions.Item label="Telefon">{selectedAdmin.phone || '-'}</Descriptions.Item>
                    <Descriptions.Item label="Acil Durum İletişim">{selectedAdmin.emergencyContact || '-'}</Descriptions.Item>
                    <Descriptions.Item label="Doğum Tarihi">
                      {selectedAdmin.birthDate ? moment(selectedAdmin.birthDate).format('DD.MM.YYYY') : '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Medeni Durumu">
                      {selectedAdmin.maritalStatus === 'married' ? 'Evli' : 
                       selectedAdmin.maritalStatus === 'single' ? 'Bekar' : '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Cinsiyet">
                      {selectedAdmin.gender === 'male' ? 'Erkek' : 
                       selectedAdmin.gender === 'female' ? 'Kadın' : '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Eğitim Durumu">
                      {selectedAdmin.educationLevel === 'bachelor' ? 'Lisans' : 
                       selectedAdmin.educationLevel === 'master' ? 'Yüksek Lisans' : 
                       selectedAdmin.educationLevel === 'doctorate' ? 'Doktora' : 
                       selectedAdmin.educationLevel === 'highschool' ? 'Lise' : '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="TC Kimlik No">{selectedAdmin.nationalId || '-'}</Descriptions.Item>
                  </Descriptions>
                  
                  <Descriptions title="Adres Bilgileri" column={1} size="small" bordered className="mt-3">
                    <Descriptions.Item label="Adres">{selectedAdmin.address || '-'}</Descriptions.Item>
                    <Descriptions.Item label="Şehir">{selectedAdmin.city || '-'}</Descriptions.Item>
                  </Descriptions>
                  
                  <Descriptions title="İş Bilgileri" column={{ xs: 1, sm: 2 }} size="small" bordered className="mt-3">
                    <Descriptions.Item label="İşe Başlama Tarihi">
                      {selectedAdmin.hireDate ? moment(selectedAdmin.hireDate).format('DD.MM.YYYY') : '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Çalışma Süresi">
                      {selectedAdmin.workDuration ? `${selectedAdmin.workDuration} saat` : '0 saat'}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                
                <Col span={24}>
                  <Divider orientation="left">Yetkiler ve İzinler</Divider>
                  {renderPermissions(selectedAdmin.permissions)}
                </Col>
              </Row>
            </div>
          )}
        </Modal>

        {/* Kullanıcı Detay Modal */}
        <Modal
          title={selectedUser ? `${selectedUser.firstName} ${selectedUser.lastName} - Kullanıcı Detayları` : 'Kullanıcı Detayları'}
          visible={userDetailModalVisible}
          onCancel={() => setUserDetailModalVisible(false)}
          width={900}
          footer={[
            <Button key="close" onClick={() => setUserDetailModalVisible(false)}>
              Kapat
            </Button>
          ]}
        >
          {selectedUser && (
            <div className="user-details">
              <Row gutter={[16, 24]}>
                <Col xs={24} sm={6} className="text-center">
                  <Avatar 
                    size={100} 
                    src={selectedUser.profilePicture ? `/uploads/${selectedUser.profilePicture}` : null}
                    icon={!selectedUser.profilePicture ? <UserOutlined /> : null}
                  />
                  <div className="mt-2 fw-bold">{selectedUser.firstName} {selectedUser.lastName}</div>
                  <div className="text-muted">{selectedUser.title || '-'}</div>
                  <div className="mt-2">
                    <Tag color={selectedUser.role?.toLowerCase() === 'admin' ? 'red' : 
                              selectedUser.role?.toLowerCase() === 'manager' ? 'orange' : 
                              selectedUser.role?.toLowerCase() === 'supervisor' ? 'blue' : 'green'}>
                      {selectedUser.role?.toLowerCase() === 'admin' ? 'Admin' : 
                       selectedUser.role?.toLowerCase() === 'manager' ? 'Yönetici' : 
                       selectedUser.role?.toLowerCase() === 'supervisor' ? 'Sorumlu' : 'Çalışan'}
                    </Tag>
                    <Tag color={selectedUser.status === 'active' ? 'success' : 'default'} className="ms-1">
                      {selectedUser.status === 'active' ? 'Aktif' : 'Pasif'}
                    </Tag>
                  </div>
                </Col>
                
                <Col xs={24} sm={18}>
                  <Descriptions title="Kişisel Bilgiler" column={{ xs: 1, sm: 2 }} size="small" bordered>
                    <Descriptions.Item label="E-posta">{selectedUser.email}</Descriptions.Item>
                    <Descriptions.Item label="Telefon">{selectedUser.phone || '-'}</Descriptions.Item>
                    <Descriptions.Item label="Acil Durum İletişim">{selectedUser.emergencyContact || '-'}</Descriptions.Item>
                    <Descriptions.Item label="Doğum Tarihi">
                      {selectedUser.birthDate ? moment(selectedUser.birthDate).format('DD.MM.YYYY') : '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Medeni Durumu">
                      {selectedUser.maritalStatus === 'married' ? 'Evli' : 
                       selectedUser.maritalStatus === 'single' ? 'Bekar' : '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Cinsiyet">
                      {selectedUser.gender === 'male' ? 'Erkek' : 
                       selectedUser.gender === 'female' ? 'Kadın' : '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Eğitim Durumu">
                      {selectedUser.educationLevel === 'bachelor' ? 'Lisans' : 
                       selectedUser.educationLevel === 'master' ? 'Yüksek Lisans' : 
                       selectedUser.educationLevel === 'doctorate' ? 'Doktora' : 
                       selectedUser.educationLevel === 'highschool' ? 'Lise' : '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="TC Kimlik No">{selectedUser.nationalId || '-'}</Descriptions.Item>
                  </Descriptions>
                  
                  <Descriptions title="Adres Bilgileri" column={1} size="small" bordered className="mt-3">
                    <Descriptions.Item label="Adres">{selectedUser.address || '-'}</Descriptions.Item>
                    <Descriptions.Item label="Şehir">{selectedUser.city || '-'}</Descriptions.Item>
                  </Descriptions>
                  
                  <Descriptions title="İş Bilgileri" column={{ xs: 1, sm: 2 }} size="small" bordered className="mt-3">
                    <Descriptions.Item label="İşe Başlama Tarihi">
                      {selectedUser.hireDate ? moment(selectedUser.hireDate).format('DD.MM.YYYY') : '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Çalışma Süresi">
                      {selectedUser.workDuration ? `${selectedUser.workDuration} saat` : '0 saat'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Yıllık İzin Limiti">
                      {selectedUser.annualLeaveLimit ? `${selectedUser.annualLeaveLimit} gün` : '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Kalan Yıllık İzin">
                      {selectedUser.remainingAnnualLeaveLimit ? `${selectedUser.remainingAnnualLeaveLimit} gün` : '-'}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                
                {selectedUser.permissions && selectedUser.permissions.length > 0 && (
                  <Col span={24}>
                    <Divider orientation="left">Yetkiler ve İzinler</Divider>
                    {renderPermissions(selectedUser.permissions)}
                  </Col>
                )}
              </Row>
            </div>
          )}
        </Modal>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default CompanyDetails;
