import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import CommonSelect from "../../../core/common/commonSelect";
import CollapseHeader from "../../../core/common/collapse-header/collapse-header";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import userService from "../../../services/user/user.service";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { User, UserCreateDTO, UserUpdateDTO } from "../../../types/user.types";
import { DEFAULT_USER_CREATE_DTO, mapUserToUpdateDTO } from "../../../constants/userConstants";
import Swal from 'sweetalert2';
import FileUpload from '../../../components/shared/components/upload/FileUpload';
import { FileDto, FileType } from '../../../types/file.types';
import FileService from '../../../services/file/file.service';
import { getFileUrl } from '../../../utils/file.utils';
import authService from '../../../services/auth/auth.service';
import { logout } from "../../../store/slices/auth.slice";
import { AppDispatch } from "../../../store/store";
import { Modal, Input, Tooltip } from 'antd';
import { message } from 'antd';
import { handleApiError } from '../../../utils/errorHandler';
import { useTranslation } from 'react-i18next';
import FooterSection from "../../shared/components/footerSection";
type PasswordField =
  | "oldPassword"
  | "newPassword"
  | "confirmPassword"
  | "currentPassword";

const Profile = () => {
  const {user} = useSelector((state: RootState) => state.auth);
  const {getUserById, updateUser} = userService;
  const [userData, setUserData] = useState<User | null>(null);  
  const [formState, setFormState] = useState<UserUpdateDTO>(DEFAULT_USER_CREATE_DTO);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const fileService = FileService.getInstance();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const {t} = useTranslation();
  // Şifre değiştirme state'leri
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordLoading, setPasswordLoading] = useState(false);
  const routes = all_routes;
  const handleInputChange = (field: keyof (UserUpdateDTO), value: any) => {
    setFormState((prev) => ({ ...prev, [field]: value }));
  };
  
  const fetchUser = async () => {
    if(!user?.id) return;
    try {
      setLoading(true);
      const userData = await getUserById(user.id);
      setUserData(userData);
      setFormState(mapUserToUpdateDTO(userData));
      setLoading(false);
    } catch (error: any) {
      handleApiError(error,t);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUser();
  }, [user?.id]);

  const handleProfilePhotoUpload = async (file: File | null) => {
    setSelectedFile(file);
  };

  const handleSave = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!user?.id) return;

    setLoading(true);
    try {
      let profilePictureUrl = userData?.profilePicture || null;

      // Profil fotoğrafı yükleme
      if (selectedFile) {
        console.log(selectedFile);
        
        try {
          const uploadResponse = await fileService.uploadFile(selectedFile, FileType.PROFILE_PICTURE);
          if (uploadResponse) {
            profilePictureUrl = uploadResponse.filePath.path;
          }
        } catch (error) {
          handleApiError(error,t);
        }
      }

      // Kullanıcı bilgilerini güncelleme
      const updatedFormState = {
        ...formState,
        profilePicture: profilePictureUrl,
      };

      await updateUser(user.id, updatedFormState);
      
      Swal.fire({
        title: 'Başarılı!',
        text: 'Profil bilgileriniz güncellendi.',
        icon: 'success',
        confirmButtonText: 'Tamam'
      });
      
      // Güncellenen kullanıcı bilgilerini getir
      await fetchUser();
    } catch (error) {
      handleApiError(error,t);
      console.error(error);
    } finally {
      setSelectedFile(null);
      setLoading(false);
    }
  };
  

  // Şifre değiştirme fonksiyonu
  const handleChangePassword = async () => {
    // Validasyon
    if (!currentPassword) {
      Swal.fire({
        title: 'Hata!',
        text: 'Mevcut şifrenizi girmelisiniz.',
        icon: 'error',
        confirmButtonText: 'Tamam'
      });
      return;
    }
    
    if (!newPassword) {
      Swal.fire({
        title: 'Hata!',
        text: 'Yeni şifrenizi girmelisiniz.',
        icon: 'error',
        confirmButtonText: 'Tamam'
      });
      return;
    }
    
    if (newPassword !== confirmPassword) {
      Swal.fire({
        title: 'Hata!',
        text: 'Yeni şifre ve onay şifresi eşleşmiyor.',
        icon: 'error',
        confirmButtonText: 'Tamam'
      });
      return;
    }
    
    if (newPassword.length < 6) {
      Swal.fire({
        title: 'Hata!',
        text: 'Şifre en az 6 karakter olmalıdır.',
        icon: 'error',
        confirmButtonText: 'Tamam'
      });
      return;
    }
    
    try {
      setPasswordLoading(true);
      await authService.changePassword(currentPassword, newPassword);
      
      // Başarılı mesajı göster
      Swal.fire({
        title: 'Başarılı!',
        text: 'Şifreniz başarıyla değiştirildi. Lütfen tekrar giriş yapınız.',
        icon: 'success',
        confirmButtonText: 'Tamam'
      }).then(() => {
        // Kullanıcıyı logout yap ve login sayfasına yönlendir
       dispatch(logout());
        navigate(routes.login2);
      });
      
      // Şifre alanlarını temizle
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      handleApiError(error,t);
    } finally {
      setPasswordLoading(false);
    }
  };
  
  const route = all_routes;
  const [passwordVisibility, setPasswordVisibility] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
    currentPassword: false,
  });

  const togglePasswordVisibility = (field: PasswordField) => {
    setPasswordVisibility((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const [documents, setDocuments] = useState<FileDto[]>([]);
  const [isDocumentModalVisible, setIsDocumentModalVisible] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<FileDto | null>(null);
  const [documentTitle, setDocumentTitle] = useState('');
  const [documentFile, setDocumentFile] = useState<File | null>(null);
  const [documentLoading, setDocumentLoading] = useState(false);

  // Belge yükleme fonksiyonu
  const handleDocumentUpload = async (file: File | null) => {
    setDocumentFile(file);
  };

  // Yeni belge ekleme modalını aç
  const showAddDocumentModal = () => {
    setSelectedDocument(null);
    setDocumentTitle('');
    setDocumentFile(null);
    setIsDocumentModalVisible(true);
  };

  // Belge düzenleme modalını aç
  const showEditDocumentModal = (document: FileDto) => {
    setSelectedDocument(document);
    setDocumentTitle(document.name);
    setIsDocumentModalVisible(true);
  };

  const fetchDocuments = async () => {
    if(!user?.id) return;
    const documents = await fileService.getFileByUserDocuments(FileType.DOCUMENT,user?.id);
    setDocuments(documents);
  };
  useEffect(() => {
    fetchDocuments();
  }, []);

  // Belge silme fonksiyonu
  const handleDeleteDocument = async (document: FileDto) => {
    try {
      Swal.fire({
        title: 'Emin misiniz?',
        text: "Bu belgeyi silmek istediğinize emin misiniz?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Evet, sil',
        cancelButtonText: 'İptal'
      }).then(async (result) => {
        if (result.isConfirmed) {
          setDocumentLoading(true);
          await fileService.deleteFile(document.id);
          message.success('Belge başarıyla silindi');
          fetchDocuments();
        }
      });
    } catch (error) {
      handleApiError(error,t);
    } finally {
      setDocumentLoading(false);
    }
  };

  // Belge kaydetme fonksiyonu
  const handleSaveDocument = async () => {
    if (!documentTitle.trim()) {
      message.error('Lütfen belge başlığı giriniz');
      return;
    }

    try {
      setDocumentLoading(true);
      
      if (selectedDocument) {
        // Düzenleme işlemi
        if(documentFile){
          console.log(documentFile);
          
          await fileService.updateFileByUserDocuments(selectedDocument.id, FileType.DOCUMENT, documentTitle,documentFile);
          message.success('Belge başarıyla güncellendi');
        }else{
          await fileService.updateFileByUserDocuments(selectedDocument.id, FileType.DOCUMENT, documentTitle);
          message.success('Belge başarıyla güncellendi');
        }
      } else {
        // Yeni belge ekleme
        if (!documentFile) {
          message.error('Lütfen bir belge seçiniz');
          return;
        }
        await fileService.uploadFile(documentFile, FileType.DOCUMENT, documentTitle);
        message.success('Belge başarıyla eklendi');
      }

      setIsDocumentModalVisible(false);
      setDocumentTitle('');
      setDocumentFile(null);
      setSelectedDocument(null);
      fetchDocuments();
    } catch (error) {
      handleApiError(error,t);
    } finally {
      setDocumentLoading(false);
    }
  };

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          {/* Breadcrumb */}
          <div className="d-md-flex d-block align-items-center justify-content-between page-breadcrumb mb-3">
            <div className="my-auto mb-2">
              <h2 className="mb-1">Profile </h2>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={'/'}>
                      <i className="ti ti-smart-home" />
                    </Link>
                  </li>
                  <li className="breadcrumb-item">Pages</li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Profile{" "}
                  </li>
                </ol>
              </nav>
            </div>
            <div className="head-icons ms-2">
              <CollapseHeader />
            </div>
          </div>
          {/* /Breadcrumb */}
          <div className="card">
            <div className="card-body">
              <div className="border-bottom mb-3 pb-3">
                <h4>Profile </h4>
              </div>
              <form onSubmit={handleSave} className="border-bottom mb-3 pb-3">
                <div className="">
                  <div className="row">
                    <div className="col-md-12">
                      <div>
                        <h6 className="mb-3">Basic Information</h6>
                        <div className="d-flex align-items-center flex-wrap row-gap-3 bg-light w-100 rounded p-3 mb-4">
                          <div className="d-flex align-items-center justify-content-center avatar avatar-xxl rounded-circle border border-dashed me-2 flex-shrink-0 text-dark frames">
                            {userData?.profilePicture ? (
                              <img 
                                src={getFileUrl(userData.profilePicture)} 
                                alt="Profile" 
                                className="w-100 h-100 rounded-circle object-fit-cover"
                              />
                            ) : (
                              <i className="ti ti-photo text-gray-3 fs-16" />
                            )}
                          </div>
                          <div className="profile-upload">
                            <div className="mb-2">
                              <h6 className="mb-1">Profile Photo</h6>
                              <p className="fs-12">
                                Recommended image size is 40px x 40px
                              </p>
                            </div>
                            <div className="profile-uploader d-flex align-items-center">
                              <FileUpload
                                fileType={FileType.PROFILE_PICTURE}
                                onChange={handleProfilePhotoUpload}
                                buttonText="Fotoğraf Yükle"
                                accept=".jpg,.jpeg,.png"
                                maxCount={1}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row align-items-center mb-3">
                        <div className="col-md-4">
                          <label className="form-label mb-md-0">First Name</label>
                        </div>
                        <div className="col-md-8">
                          <input 
                          type="text" 
                          value={formState.firstName} 
                          className="form-control" 
                          onChange={(e) => handleInputChange("firstName", e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row align-items-center mb-3">
                        <div className="col-md-4">
                          <label className="form-label mb-md-0">Last Name</label>
                        </div>
                        <div className="col-md-8">
                          <input 
                          type="text" 
                          value={formState.lastName} 
                          className="form-control" 
                          onChange={(e) => handleInputChange("lastName", e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row align-items-center mb-3">
                        <div className="col-md-4">
                          <label className="form-label mb-md-0">Email</label>
                        </div>
                        <div className="col-md-8">
                          <input 
                          type="text" 
                          value={formState.email} 
                          className="form-control" 
                          onChange={(e) => handleInputChange("email", e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row align-items-center mb-3">
                        <div className="col-md-4">
                          <label className="form-label mb-md-0">Phone</label>
                        </div>
                        <div className="col-md-8">
                          <input 
                          type="text" 
                          value={formState.phone} 
                          className="form-control" 
                          onChange={(e) => handleInputChange("phone", e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row align-items-center mb-3">
                        <div className="col-md-4">
                          <label className="form-label mb-md-0">Birth Date</label>
                        </div>
                        <div className="col-md-8">
                          <DatePicker
                            value={formState.birthDate ? dayjs(formState.birthDate) : null}
                            onChange={(date) => handleInputChange("birthDate", date ? date.toDate() : undefined)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row align-items-center mb-3">
                        <div className="col-md-4">  
                          <label className="form-label mb-md-0">Tc No</label>
                        </div>
                        <div className="col-md-8">
                          <input disabled type="text" value={formState.nationalId} className="form-control" onChange={(e) => handleInputChange("nationalId", e.target.value)} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row align-items-center mb-3">
                        <div className="col-md-2">
                          <label className="form-label mb-md-0">Address</label>
                        </div>
                        <div className="col-md-10">
                          <input type="text" value={formState.city} className="form-control" onChange={(e) => handleInputChange("city", e.target.value)} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              
                <div className="d-flex align-items-center justify-content-end">
                  <button
                    type="button"
                    className="btn btn-outline-light border me-3"
                  >
                    Cancel
                  </button>
                  <button 
                    type="submit" 
                    className="btn btn-primary"
                    disabled={loading}
                  >
                    {loading ? "Kaydediliyor..." : "Save"}
                  </button>
                </div>
              </form>
              <div className="border-bottom mb-3 mt-4 pt-3">
                  <h6 className="mb-3">Change Password</h6>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="row align-items-center mb-3">
                        <div className="col-md-5">
                          <label className="form-label mb-md-0">
                            Current Password
                          </label>
                        </div>
                        <div className="col-md-7">
                          <div className="pass-group">
                            <input
                              type={
                                passwordVisibility.currentPassword
                                  ? "text"
                                  : "password"
                              }
                              className="pass-input form-control"
                              value={currentPassword}
                              onChange={(e) => setCurrentPassword(e.target.value)}
                            />
                            <span
                              className={`ti toggle-passwords ${passwordVisibility.currentPassword
                                ? "ti-eye"
                                : "ti-eye-off"
                                }`}
                              onClick={() =>
                                togglePasswordVisibility("currentPassword")
                              }
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="row align-items-center mb-3">
                        <div className="col-md-5">
                          <label className="form-label mb-md-0">New Password</label>
                        </div>
                        <div className="col-md-7">
                          <div className="pass-group">
                            <input
                              type={
                                passwordVisibility.newPassword
                                  ? "text"
                                  : "password"
                              }
                              className="pass-input form-control"
                              value={newPassword}
                              onChange={(e) => setNewPassword(e.target.value)}
                            />
                            <span
                              className={`ti toggle-passwords ${passwordVisibility.newPassword
                                ? "ti-eye"
                                : "ti-eye-off"
                                }`}
                              onClick={() =>
                                togglePasswordVisibility("newPassword")
                              }
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="row align-items-center mb-3">
                        <div className="col-md-5">
                          <label className="form-label mb-md-0">
                            Confirm Password
                          </label>
                        </div>
                        <div className="col-md-7">
                          <div className="pass-group">
                            <input
                              type={
                                passwordVisibility.confirmPassword
                                  ? "text"
                                  : "password"
                              }
                              className="pass-input form-control"
                              value={confirmPassword}
                              onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                            <span
                              className={`ti toggle-passwords ${passwordVisibility.confirmPassword
                                ? "ti-eye"
                                : "ti-eye-off"
                                }`}
                              onClick={() =>
                                togglePasswordVisibility("confirmPassword")
                              }
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="d-flex justify-content-end mt-3 mb-3">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleChangePassword}
                          disabled={passwordLoading}
                        >
                          {passwordLoading ? "Şifre Değiştiriliyor..." : "Şifreyi Değiştir"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

             {/* Belgeler Bölümü */}
      <div className="border-bottom mb-3 mt-4 pt-3">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h6 className="mb-0">Belgeler</h6>
          <button 
            type="button" 
            className="btn btn-primary btn-sm"
            onClick={showAddDocumentModal}
          >
            <i className="ti ti-plus me-1"></i>
            Yeni Belge Ekle
          </button>
        </div>
        
        <div className="row">
          {documents.length > 0 ? (
            documents.map((document) => (
              <div key={document.id} className="col-md-4 mb-3">
                <div className="card h-100">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <h6 className="mb-0">{document.name}</h6>
                      <div className="dropdown">
                        <button className="btn btn-light btn-sm" type="button" data-bs-toggle="dropdown">
                          <i className="ti ti-dots-vertical"></i>
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <a 
                              className="dropdown-item" 
                              href={getFileUrl(document.path)} 
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="ti ti-eye me-2"></i>
                              Görüntüle
                            </a>
                          </li>
                          <li>
                            <button 
                              className="dropdown-item" 
                              onClick={() => {
                                showEditDocumentModal(document);
                                setSelectedDocument(document);
                              }}
                            >
                              <i className="ti ti-pencil me-2"></i>
                              Düzenle
                            </button>
                          </li>
                          <li>
                            <button 
                              className="dropdown-item text-danger" 
                              onClick={() => handleDeleteDocument(document)}
                            >
                              <i className="ti ti-trash me-2"></i>
                              Sil
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <p className="text-muted small mb-0">
                      Yüklenme Tarihi: {new Date(document.createdAt).toLocaleDateString('tr-TR')}
                    </p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="col-12">
              <div className="text-center py-4">
                <i className="ti ti-file-off fs-2 text-muted mb-2"></i>
                <p className="text-muted">Henüz belge eklenmemiş</p>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Belge Ekleme/Düzenleme Modalı */}
      <Modal
        title={selectedDocument ? 'Belge Düzenle' : 'Yeni Belge Ekle'}
        open={isDocumentModalVisible}
        onCancel={() => setIsDocumentModalVisible(false)}
        footer={[
          <button
            key="cancel"
            type="button"
            className="btn btn-light"
            onClick={() => setIsDocumentModalVisible(false)}
            disabled={documentLoading}
          >
            İptal
          </button>,
          <button
            key="submit"
            type="button"
            className="btn btn-primary ms-2"
            onClick={handleSaveDocument}
            disabled={documentLoading}
          >
            {documentLoading ? (
              <>
                <span className="spinner-border spinner-border-sm me-1" />
                Kaydediliyor...
              </>
            ) : 'Kaydet'}
          </button>
        ]}
      >
        <div className="mb-3">
          <label className="form-label">Belge Başlığı</label>
          <Input
            placeholder="Örn: Ehliyet, Kimlik, Diploma"
            value={documentTitle}
            onChange={(e) => setDocumentTitle(e.target.value)}
          />
        </div>
        
        <div>
          <label className="form-label">Belge</label>
          <FileUpload
            fileType={FileType.DOCUMENT}
            onChange={handleDocumentUpload}
            maxCount={1}
            buttonText={selectedDocument ? "Belgeyi Değiştir" : "Belge Yükle"}
            accept=".pdf,.jpg,.jpeg,.png"
            disabled={documentLoading}
          />
          {selectedDocument && !documentFile && (
            <div className="mt-2">
              <small className="text-muted">
                Mevcut belge: {selectedDocument.name + " (" + selectedDocument.path.split('/').pop() + ")"}
              </small>
            </div>
          )}
        </div>
      </Modal>
      </div>
      </div>

      </div>

      <div className="footer d-sm-flex align-items-center justify-content-between border-top bg-white p-3">
            <FooterSection />
        </div>
      </div>
      {/* /Page Wrapper */}

      </>
  );
};

export default Profile;
