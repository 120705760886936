import React, { useEffect, useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { DatePicker, TimePicker } from "antd";
import CommonSelect from '../../../core/common/commonSelect';
import dayjs from "dayjs";
import { EmploymentType,UserCreateDTO,User, UserUpdateDTO, UserRole, YearlyDetailsForEmployeeResponseDto } from '../../../types/user.types';
import userService from '../../../services/user/user.service';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import { 
  DEFAULT_USER_CREATE_DTO, 
  ROLES, 
  GENDERS 
} from '../../../constants/userConstants';
import ReactApexChart from 'react-apexcharts';
import { Salary } from '../../../types/salary.types';
import { ProfileCard } from './components/employeeDetails/ProfileCard';
import LeaveHistory from './components/employeeDetails/LeaveHistory';
import { UserYearlyLeaveSummary } from '../../../types/leaves.types';
import leaveService from '../../../services/leave/leave.service';
import ticketService from '../../../services/ticket/ticket.service';
import { TicketDto } from '../../../types/ticket.types';
import { TicketCards } from '../tickets/ticketsAdmin';
import { FileDto, FileType } from '../../../types/file.types';
import FileService from '../../../services/file/file.service';
import EmployeesDocuments from './components/employeeDetails/EmployeesDocuments';
import AdminLeaveModal from '../../features/leaves/components/AdminLeaveModal';
import { useLeaves } from '../../../hooks/useLeaves';
import { UserRecordDto } from '../../../types/attendanceDto.types';
import dailyRecordService from '../../../services/dailyRecord/dailyRecord.service';
import { handleApiError } from '../../../utils/errorHandler';
import { Trans, useTranslation } from 'react-i18next';
import { useEmployee } from '../../../hooks/useEmployee';
import PersonalInfoStep from './components/form-steps/PersonalInfoStep';
import ContactInfoStep from './components/form-steps/ContactInfoStep';
import WorkInfoStep from './components/form-steps/WorkInfoStep';
import SecurityStep from './components/form-steps/SecurityStep';
import { AnnualLeaveLimitResponse } from '../../../types/company.types';

import { Form, Modal, message, Button } from 'antd';
import companyService from '../../../services/company/company.service';
import FooterSection from '../../shared/components/footerSection';

type PasswordField = "password" | "confirmPassword";

const EmployeeDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [formState, setFormState] = useState<UserCreateDTO | UserUpdateDTO>(DEFAULT_USER_CREATE_DTO);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [activeTab, setActiveTab] = useState<'personal' | 'contact' | 'work' | 'security'>('personal');
    const [userData, setUserData] = useState<User | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [annualLimit, setAnnualLimit] = useState<AnnualLeaveLimitResponse | null>(null);
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const {getTicketsByUser} = ticketService;
    const [yearlyDetails, setYearlyDetails] = useState<YearlyDetailsForEmployeeResponseDto | null>(null);
    const [leaveSummary, setLeaveSummary] = useState<UserYearlyLeaveSummary | null>(null);
    const [passwordVisibility, setPasswordVisibility] = useState({
        password: false,
        confirmPassword: false,
    });
    const [salaries, setSalaries] = useState<Salary[]>([]);
    const [tickets, setTickets] = useState<TicketDto[]>([]);
    const [documents, setDocuments] = useState<FileDto[]>([]);
    const fileService = FileService.getInstance();
    const [isUpdateSalaryModalVisible, setIsUpdateSalaryModalVisible] = useState(false);
    const [newSalaryAmount, setNewSalaryAmount] = useState<number | null>(null);
    const [selectedCurrency, setSelectedCurrency] = useState<string>("TRY");
    const [isUpdatingSalary, setIsUpdatingSalary] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [isLeaveModalVisible, setIsLeaveModalVisible] = useState(false);
    const { createLeave } = useLeaves();
    const [userRecords, setUserRecords] = useState<UserRecordDto>();
    const { getSalaryByUserId, getYearlyDetails } = userService;
    const { getUserYearlyLeaveDetails } = leaveService;
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [isUpdatingRecord, setIsUpdatingRecord] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState<any>(null);
    const [editedRecord, setEditedRecord] = useState<any>(null);
    const {t} = useTranslation();
    const currencies = [
        { value: "TRY", label: "₺ TL", symbol: "₺" },
        { value: "USD", label: "$ USD", symbol: "$" },
        { value: "EUR", label: "€ EUR", symbol: "€" }
    ];
    const { companyId } = useSelector((state: RootState) => state.auth);
    const {    
        departments,
        shops,
        fetchDepartmentsAndShops,
        updateEmployee,
    } = useEmployee();
    useEffect(() => {
        const fetchAnnualLimit = async () => {
          if (companyId) {
            const response = await companyService.getAnnualLimit(Number(companyId));
            setAnnualLimit(response);
          }
        };
        fetchAnnualLimit();
      }, [companyId]);
      
      // Component mount olduğunda verileri çek
      const fetchData = async () => {
        await Promise.all([
          fetchDepartmentsAndShops()
        ]);
      };
    
      // İlk veri yüklemesi
      useEffect(() => {
        fetchData();
      }, []);
      const handleEdit = (user: User) => {
    
        form.resetFields();
        setSelectedUser(user);
        
        // Form değerlerini doğru şekilde set et
        const formValues = {
          ...user,
          departmentId: user.department?.id, // department ID'sini set et
          branchId: user.branch?.id, // branch ID'sini set et
          birthDate: user.birthDate ? dayjs(user.birthDate) : null,
          hireDate: user.hireDate ? dayjs(user.hireDate) : null,
          role: user.role || UserRole.USER,
        };
    
        form.setFieldsValue(formValues);
        
        setIsModalOpen(true);
      };
    const fetchUserRecords = async () => {
      const startDate = dayjs().startOf('month').format('YYYY-MM-DD');
      const endDate = dayjs().endOf('month').format('YYYY-MM-DD');
      const userRecords = await dailyRecordService.getUserRecords(Number(id), startDate, endDate);
      setUserRecords(userRecords);
    };
    const showModal = () => {
        resetForm();
        setIsModalOpen(true);
      };
    
      const handleCancel = () => {
        setIsModalOpen(false);
        resetForm();
      };
    useEffect(() => {
      fetchUserRecords();
    }, [id]);

    const fetchTickets = async () => {
      try {
        const data = await getTicketsByUser(Number(id));
        setTickets(data);
        setLoading(false);
      } catch (err: any) {
        handleApiError(err,t);
        setLoading(false);
      }
    };  
    useEffect(() => {
      fetchTickets();
    }, [id]);
    const fetchSalaries = async () => {
        const salaries = await getSalaryByUserId(Number(id));
        setSalaries(salaries);
    };
    useEffect(() => {
        fetchSalaries();
    }, [id]);

    const fetchDocuments = async () => {
        const documents = await fileService.getFileByUserDocuments(FileType.DOCUMENT,Number(id));
        setDocuments(documents);
    };
    useEffect(() => {
        fetchDocuments();
    }, [id]);

    const fetchYearlyDetails = async () => {
        const yearlyDetails = await getYearlyDetails(Number(id));
        setYearlyDetails(yearlyDetails);
    };
    useEffect(() => {
        fetchYearlyDetails();
      }, [id]);

    const fetchLeaveSummary = async () => {
        const leaveSummary = await getUserYearlyLeaveDetails(Number(id));
        setLeaveSummary(leaveSummary);
    };
    useEffect(() => {
        fetchLeaveSummary();
    }, [id]);

    const handleInputChange = (field: keyof (UserCreateDTO | UserUpdateDTO), value: any) => {
        setFormState((prev) => ({ ...prev, [field]: value }));
      };

    const renderModalContent = () => {
        return (
          <Form 
            form={form} 
            layout="vertical" 
            initialValues={selectedUser || {}}
          >
            <div className="contact-grids-tab">
              <ul className="nav nav-underline" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${activeTab === 'personal' ? 'active' : ''}`}
                    onClick={() => setActiveTab('personal')}
                    type="button"
                    role="tab"
                  >
                    {t('employees.modals.personal')}
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${activeTab === 'contact' ? 'active' : ''}`}
                    onClick={() => setActiveTab('contact')}
                    type="button"
                    role="tab"
                  >
                    {t('employees.modals.contact')}
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${activeTab === 'work' ? 'active' : ''}`}
                    onClick={() => setActiveTab('work')}
                    type="button"
                  >
                    {t('employees.modals.work')}
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${activeTab === 'security' ? 'active' : ''}`}
                    onClick={() => setActiveTab('security')}
                    type="button"
                    role="tab"
                  >
                    {t('employees.modals.security')}
                  </button>
                </li>
              </ul>
            </div>
            <div className="modal-body">
              {/* Tüm form alanlarını her zaman render et, sadece görünürlüğü değiştir */}
              <div style={{ display: activeTab === 'personal' ? 'block' : 'none' }}>
                <PersonalInfoStep form={form} />
              </div>
              <div style={{ display: activeTab === 'contact' ? 'block' : 'none' }}>
                <ContactInfoStep form={form} />
              </div>
              <div style={{ display: activeTab === 'work' ? 'block' : 'none' }}>
                <WorkInfoStep form={form} departments={departments} shops={shops} annualLimit={annualLimit?.annualLeaveLimit || 0} />
              </div>
              <div style={{ display: activeTab === 'security' ? 'block' : 'none' }}>
                <SecurityStep form={form} isEdit={!!selectedUser} />
              </div>
            </div>
          </Form>
        );
      };
    const sLineArea = {
        chart: {
            height: 350,
            type: "line" as const,
            toolbar: {
                show: false,
                tools: {
                    download: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                    selection: false
                }
            }
        },
        colors: ['#4361ee'],
        dataLabels: {
            enabled: true,
            formatter: function(val: number) {
                return currencies.find(c => c.value === selectedCurrency)?.symbol + val.toLocaleString('tr-TR')
            },
            style: {
                fontSize: '14px',
                fontWeight: 600
            },
            background: {
                enabled: true,
                foreColor: '#333',
                padding: 4,
                borderRadius: 4,
                borderWidth: 1,
                borderColor: '#eee',
                opacity: 0.9
            }
        },
        stroke: {
            curve: 'smooth' as const,
            width: 3
        },
        series: [
            {
                name: t('employeesDetail.salary'),
                data: [...salaries]
                    .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
                    .map(salary => salary.amount)
            }
        ],
        grid: {
            borderColor: '#f1f1f1',
            strokeDashArray: 4,
            xaxis: {
                lines: {
                    show: true
                }
            },
            yaxis: {
                lines: {
                    show: true
                }
            }
        },
        xaxis: {
            categories: [...salaries]
                .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
                .map(salary => {
                    const date = new Date(salary.createdAt);
                    return date.toLocaleDateString('tr-TR', {
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric',
                    });
                }),
            labels: {
                style: {
                    fontSize: '12px',
                    fontFamily: 'inherit',
                    fontWeight: 500
                },
                rotate: -45
            }
        },
        yaxis: {
            labels: {
                formatter: function(val: number) {
                    return currencies.find(c => c.value === selectedCurrency)?.symbol + val.toLocaleString('tr-TR')
                },
                style: {
                    fontSize: '12px',
                    fontFamily: 'inherit',
                    fontWeight: 500
                }
            },
            title: {
                text: t('employeesDetail.salaryAmount'),
                style: {
                    fontSize: '13px',
                    fontWeight: 600,
                    fontFamily: 'inherit'
                }
            }
        },
        markers: {
            size: 6,
            strokeWidth: 2,
            fillOpacity: 1,
            strokeOpacity: 1,
            hover: {
                size: 8
            }
        },
        title: {
            text: t('employeesDetail.salaryChangeReport'),
            align: 'left' as const,
            style: {
                fontSize: '16px',
                fontWeight: 600,
                fontFamily: 'inherit',
                color: '#333'
            }
        }
    }

    const { getUserById } = userService;

    // Tarih formatlamak için yardımcı fonksiyon
    const formatDate = (dateString: string | Date | undefined) => {
        if (!dateString) return '-'; // Boş değer için tire işareti
        try {
            const date = new Date(dateString);
            return date.toLocaleDateString();
        } catch (error) {
            return '-';
        }
    };

    const fetchUserData = async () => {
      // ID kontrolü
      const userId = Number(id);
      if (isNaN(userId)) {
        navigate('/');
        return;
      }
      
      setLoading(true);
      setError(null);
      
      try {
        const response = await getUserById(userId);
        setUserData(response);
      } catch (error: any) {
        handleApiError(error,t);
      } finally {
        setLoading(false);
      }
    };
    


    useEffect(() => {
        fetchUserData();
    }, [id, navigate]);
const handleFormSubmit = async () => {
    try {
      const values = await form.validateFields();
      
      const formData: UserCreateDTO | UserUpdateDTO = {
        ...values,
        department: values.departmentId,
        branch: values.branchId,
        birthDate: values.birthDate ? dayjs(values.birthDate).format('YYYY-MM-DD') : undefined,
        hireDate: values.hireDate ? dayjs(values.hireDate).format('YYYY-MM-DD') : new Date().toISOString(),
        role: UserRole.USER,
      };

      await updateEmployee(Number(id), formData);
      setIsModalOpen(false);
      resetForm();
      await fetchUserData();
    } catch (error: any) {
      handleApiError(error,t);
      // Hangi alanların validasyondan geçemediğini görmek için
      if (error instanceof Error && 'errorFields' in error) {
        console.log(t('employees.validationErrors.errors'), error.errorFields);
      }
    }
  };
  const resetForm = () => {
    setSelectedUser(null);
    setActiveTab('personal');
    form.resetFields();
    form.setFieldsValue({
      firstName: '',
      lastName: '',
      birthDate: null,
      nationalId: '',
      gender: undefined,
      maritalStatus: undefined,
      educationLevel: undefined,
      email: '',
      phone: '',
      address: '',
      city: '',
      title: '',
      emergencyContact: '',
      departmentId: undefined,
      branchId: undefined,
      position: '',
      username: '',
      password: '',
    });
  };
  
    const handleUpdateSalary = async () => {
        if (!newSalaryAmount || newSalaryAmount <= 0) {
            message.error(t('employeesDetail.enterValidSalary'));
            return;
        }

        setIsConfirmModalVisible(true);
    };

    const handleConfirmUpdate = async () => {
        try {
            setIsUpdatingSalary(true);
            await userService.updateSalary(Number(id), newSalaryAmount!);
            await fetchSalaries();
            setIsUpdateSalaryModalVisible(false);
            setIsConfirmModalVisible(false);
            message.success(t('employeesDetail.salaryUpdatedSuccessfully'));
            setNewSalaryAmount(null);
            setSelectedCurrency("TRY");
            await fetchUserData();
        } catch (error: any) {
            handleApiError(error,t);
        } finally {
            setIsUpdatingSalary(false);
        }
    };

    const calculateSalaryChange = () => {
        if (!newSalaryAmount || salaries.length === 0) return null;
        const currentSalary = salaries[salaries.length - 1].amount;
        const change = ((newSalaryAmount - currentSalary) / currentSalary) * 100;
        return {
            percentage: change.toFixed(2),
            isIncrease: change > 0,
            difference: Math.abs(newSalaryAmount - currentSalary).toLocaleString('tr-TR')
        };
    };

    const handleLeaveSubmit = async (values: any) => {
        try {
            await createLeave({
                ...values,
                employeeId: Number(id) // Seçili kullanıcının ID'si
            });
            message.success(t('employeesDetail.leaveCreatedSuccessfully'));
            setIsLeaveModalVisible(false);
            fetchUserData(); // Kullanıcı verilerini yenile
        } catch (error: any) {
            handleApiError(error,t);
        }
    };

    const handleEditRecord = (record: any) => {
        setSelectedRecord(record);
        setEditedRecord({ ...record });
        setIsEditModalVisible(true);
    };

    const handleUpdateRecord = async () => {
        try {
            setIsUpdatingRecord(true);
            message.success(t('employeesDetail.recordUpdatedSuccessfully'));
            setIsEditModalVisible(false);
            fetchUserRecords(); // Kayıtları yenile
        } catch (error: any) {
            handleApiError(error,t);
        } finally {
            setIsUpdatingRecord(false);
        }
    };

    return (
        <div className="page-wrapper">
            <div className="content container-fluid">
                {loading && (
                    <div className="text-center p-4">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">{t('employeesDetail.loading')}</span>
                        </div>
                    </div>
                )}

                {error && (
                    <div className="alert alert-danger mx-4 my-4" role="alert">
                        <div className="d-flex align-items-center">
                            <i className="ti ti-alert-circle me-2"></i>
                            {error}
                        </div>
                    </div>
                )}

                {!loading && !error && userData && (
                    <>
                        <div className="row">
                            <div className="col-xl-4 theiaStickySidebar">
                                <ProfileCard userData={userData} formatDate={formatDate} onEdit={handleEdit}/>
                            </div>
                            
                            <div className="col-xl-8">
                                <div>
                                    <div className="tab-content custom-accordion-items">
                                        <div
                                            className="tab-pane active show"
                                            id="bottom-justified-tab1"
                                            role="tabpanel"
                                        >
                                            <div
                                                className="accordion accordions-items-seperate"
                                                id="accordionExample"
                                            >
                                                <div className="col-xl-12 col-lg-8 d-flex">
                        <div className="row flex-fill d-flex justify-content-between">
                          {/* Toplam Çalışma Günü */}
                          <div className="col-xl-4 col-md-4">
                            <div className="card">
                              <div className="card-body text-center p-3">
                                <h2 className="mb-2 d-flex align-items-center justify-content-center">
                                  <span className="avatar avatar-xs bg-primary me-2">
                                    <i className="ti ti-calendar" />
                                  </span>
                                  {yearlyDetails?.workDays}
                                </h2>
                                <p className="fw-medium mb-0">{t('employeesDetail.totalWorkingDays')}</p>
                              </div>
                            </div>
                          </div>
                          {/* Toplam Geç Kalınan Gün */}
                          <div className="col-xl-4 col-md-4">
                            <div className="card">
                              <div className="card-body text-center p-3">
                                <h2 className="mb-2 d-flex align-items-center justify-content-center">
                                  <span className="avatar avatar-xs bg-danger me-2">
                                    <i className="ti ti-alarm" />
                                  </span>
                                  {yearlyDetails?.lateDays}
                                </h2>
                                <p className="fw-medium mb-0">{t('employeesDetail.totalLateDays')}</p>
                              </div>
                            </div>
                          </div>
                          {/* Toplam İzin Günü */}
                          <div className="col-xl-4 col-md-4">
                            <div className="card">
                              <div className="card-body text-center p-3">
                                <h2 className="mb-2 d-flex align-items-center justify-content-center">
                                  <span className="avatar avatar-xs bg-info me-2">
                                    <i className="ti ti-user-off" />
                                  </span>
                                  {yearlyDetails?.leaveDays}
                                </h2>
                                <p className="fw-medium mb-0">{t('employeesDetail.totalLeaveDays')}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <EmployeesDocuments documents={documents} />
                                               {/* <EmployeesDocuments documents={ userData?.documents || []} /> */}
                                                <LeaveHistory 
                                                    leaves={userData?.leaves?.sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime()) || []} 
                                                    formatDate={formatDate} 
                                                    leaveSummary={leaveSummary as UserYearlyLeaveSummary}
                                                    onCreateLeave={() => setIsLeaveModalVisible(true)}
                                                    userData={userData}
                                                />
                                                <div className="accordion-item">
                                                    <div className="accordion-header" id="headingThree">
                                                        <div className="accordion-button">
                                                            <div className="d-flex align-items-center justify-content-between flex-fill">
                                                                <h5>{t('employeesDetail.attendanceStatus')}</h5>
                                                                <div className="d-flex">
                                                                    <Link
                                                                        to="#"
                                                                        className="d-flex align-items-center collapsed collapse-arrow"
                                                                        data-bs-toggle="collapse"
                                                                        data-bs-target="#primaryBorderThree"
                                                                        aria-expanded="false"
                                                                        aria-controls="primaryBorderThree"
                                                                    >
                                                                        <i className="ti ti-chevron-down fs-18" />
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        id="primaryBorderThree"
                                                        className="accordion-collapse collapse border-top"
                                                        aria-labelledby="headingThree"
                                                        data-bs-parent="#accordionExample"
                                                    >
                                                        <div className="accordion-body">
                                                            <div className="row">
                                                                <div className="col-xl-12">
                                                                    {/* Özet Kartları */}
                                                                    <div className="row mb-4">
                                                                        <div className="col-xl-3 col-md-6">
                                                                            <div className="card h-100 border-0 shadow-sm hover-shadow">
                                                                                <div className="card-body text-center p-4">
                                                                                    <div className="mb-4">
                                                                                        <div className="avatar avatar-xl bg-primary-subtle rounded-circle d-inline-flex align-items-center justify-content-center">
                                                                                            <span className="fs-2">⏰</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <h2 className="mb-2 text-primary">
                                                                                        {userRecords?.totalWorkMinutes ? 
                                                                                            `${Math.floor(userRecords.totalWorkMinutes / 60)} ${t('employeesDetail.minutes')}` : 
                                                                                            '0s 0dk'}
                                                                                    </h2>
                                                                                    <p className="fw-medium mb-0 text-muted">{t('employeesDetail.totalWorking')}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-3 col-md-6">
                                                                            <div className="card h-100 border-0 shadow-sm hover-shadow">
                                                                                <div className="card-body text-center p-4">
                                                                                    <div className="mb-4">
                                                                                        <div className="avatar avatar-xl bg-danger-subtle rounded-circle d-inline-flex align-items-center justify-content-center">
                                                                                            <span className="fs-2">⚠️</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <h2 className="mb-2 text-danger">{userRecords?.lateDaysCount}</h2>
                                                                                    <p className="fw-medium mb-0 text-muted">{t('employeesDetail.lateDaysCount')}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-3 col-md-6">
                                                                            <div className="card h-100 border-0 shadow-sm hover-shadow">
                                                                                <div className="card-body text-center p-4">
                                                                                    <div className="mb-4">
                                                                                        <div className="avatar avatar-xl bg-warning-subtle rounded-circle d-inline-flex align-items-center justify-content-center">
                                                                                            <span className="fs-2">🏃</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <h2 className="mb-2 text-warning">{userRecords?.earlyLeaveDaysCount}</h2>
                                                                                    <p className="fw-medium mb-0 text-muted">{t('employeesDetail.earlyLeaveDaysCount')}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-3 col-md-6">
                                                                            <div className="card h-100 border-0 shadow-sm hover-shadow">
                                                                                <div className="card-body text-center p-4">
                                                                                    <div className="mb-4">
                                                                                        <div className="avatar avatar-xl bg-success-subtle rounded-circle d-inline-flex align-items-center justify-content-center">
                                                                                            <span className="fs-2">📅</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <h2 className="mb-2 text-success">{userRecords?.totalRecords}</h2>
                                                                                    <p className="fw-medium mb-0 text-muted">{t('employeesDetail.totalRecords')}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {/* Devam Tablosu */}
                                                                    <div className="card">
                                                                        <div className="card-header">
                                                                            <h5 className="card-title mb-0">{t('employeesDetail.attendanceRecords')}</h5>
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="table-responsive">
                                                                                <table className="table table-hover">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>{t('employeesDetail.date')}</th>
                                                                                            <th>{t('employeesDetail.entryTime')}</th>
                                                                                            <th>{t('employeesDetail.exitTime')}</th>
                                                                                            <th>{t('employeesDetail.status')}</th>
                                                                                            <th>{t('employeesDetail.lateDays')}</th>
                                                                                            <th>{t('employeesDetail.workMinutes')}</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {userRecords?.records.map((record, index) => (
                                                                                            <tr 
                                                                                                key={index}
                                                                                                className="cursor-pointer"
                                                                                                onClick={() => handleEditRecord(record)}
                                                                                                style={{ cursor: 'pointer' }}
                                                                                            >
                                                                                                <td>{new Date(record.entryTime).toLocaleDateString('tr-TR')}</td>
                                                                                                <td>{new Date(record.entryTime).toLocaleTimeString('tr-TR', { hour: '2-digit', minute: '2-digit' })}</td>
                                                                                                <td>{new Date(record.exitTime).toLocaleTimeString('tr-TR', { hour: '2-digit', minute: '2-digit' })}</td>
                                                                                                <td>
                                                                                                    <span className={`badge ${record.isLate ? 'bg-danger-subtle text-danger' : 'bg-success-subtle text-success'}`}>
                                                                                                        {record.isLate ? t('employeesDetail.lateDays') : t('employeesDetail.onTime')}
                                                                                                    </span>
                                                                                                </td>
                                                                                                <td>{record.isLate ? record.lateDuration : '' }</td>
                                                                                                <td>{Math.floor(record.workMinutes / 60)} {t('employeesDetail.hours')} {record.workMinutes % 60} {t('employeesDetail.minutes')}</td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {/* Güncelleme Modalı */}
                                                                    <Modal
                                                                        title={
                                                                            <div className="d-flex align-items-center">
                                                                                <i className="ti ti-edit fs-4 me-2 text-primary"></i>
                                                                                <span>{t('employeesDetail.updateAttendanceRecord')}</span>
                                                                            </div>
                                                                        }
                                                                        open={isEditModalVisible}
                                                                        onCancel={() => setIsEditModalVisible(false)}
                                                                        footer={[
                                                                            <Button 
                                                                                key="cancel" 
                                                                                onClick={() => setIsEditModalVisible(false)}
                                                                            >
                                                                                {t('employeesDetail.cancel')}
                                                                            </Button>,
                                                                            <Button 
                                                                                key="submit" 
                                                                                type="primary" 
                                                                                loading={isUpdatingRecord}
                                                                                onClick={handleUpdateRecord}
                                                                            >
                                                                                {t('employeesDetail.update')}
                                                                            </Button>
                                                                        ]}
                                                                        width={500}
                                                                    >
                                                                        <div className="edit-record-form">
                                                                            <div className="mb-4">
                                                                                <h6 className="text-muted mb-3">{t('employeesDetail.date')}</h6>
                                                                                <div className="p-3 bg-light rounded-3 border">
                                                                                    <p className="mb-0 fw-medium">
                                                                                        {selectedRecord ? new Date(selectedRecord.entryTime).toLocaleDateString('tr-TR', {
                                                                                            weekday: 'long',
                                                                                            year: 'numeric',
                                                                                            month: 'long',
                                                                                            day: 'numeric'
                                                                                        }) : ''}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="time-picker-container p-4 bg-light rounded-3 border">
                                                                                <div className="d-flex align-items-center mb-4">
                                                                                    <div className="avatar avatar-sm bg-primary-subtle rounded-circle d-inline-flex align-items-center justify-content-center me-2">
                                                                                        <i className="ti ti-clock text-primary"></i>
                                                                                    </div>
                                                                                    <h6 className="mb-0">{t('employeesDetail.workHours')}</h6>
                                                                                </div>
                                                                                <div className="time-display">
                                                                                    <div className="row g-4">
                                                                                        <div className="col-6">
                                                                                            <div className="time-card p-4 bg-white rounded-3 border text-center">
                                                                                                <div className="time-header mb-4">
                                                                                                    <div className="time-icon mb-3">
                                                                                                        <div className="avatar avatar-lg bg-primary-subtle rounded-circle d-inline-flex align-items-center justify-content-center">
                                                                                                            <i className="ti ti-login text-primary fs-4"></i>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <h6 className="text-primary mb-0">{t('employeesDetail.entryTime')}</h6>
                                                                                                </div>
                                                                                                <TimePicker
                                                                                                    className="time-picker-input"
                                                                                                    format="HH:mm"
                                                                                                    value={editedRecord ? dayjs(editedRecord.entryTime) : null}
                                                                                                    onChange={(time) => {
                                                                                                        if (time) {
                                                                                                            const currentDate = new Date(editedRecord.entryTime);
                                                                                                            const newTime = time.toDate();
                                                                                                            currentDate.setHours(newTime.getHours());
                                                                                                            currentDate.setMinutes(newTime.getMinutes());
                                                                                                            setEditedRecord({
                                                                                                                ...editedRecord,
                                                                                                                entryTime: currentDate.toISOString()
                                                                                                            });
                                                                                                        }
                                                                                                    }}
                                                                                                    minuteStep={1}
                                                                                                    showNow={false}
                                                                                                    allowClear={false}
                                                                                                    placeholder={t('employeesDetail.selectTime')}
                                                                                                    popupClassName="time-picker-popup"
                                                                                                    changeOnBlur={false}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-6">
                                                                                            <div className="time-card p-4 bg-white rounded-3 border text-center">
                                                                                                <div className="time-header mb-4">
                                                                                                    <div className="time-icon mb-3">
                                                                                                        <div className="avatar avatar-lg bg-danger-subtle rounded-circle d-inline-flex align-items-center justify-content-center">
                                                                                                            <i className="ti ti-logout text-danger fs-4"></i>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <h6 className="text-danger mb-0">{t('employeesDetail.exitTime')}</h6>
                                                                                                </div>
                                                                                                <TimePicker
                                                                                                    className="time-picker-input"
                                                                                                    format="HH:mm"
                                                                                                    value={editedRecord ? dayjs(editedRecord.exitTime) : null}
                                                                                                    onChange={(time) => {
                                                                                                        if (time) {
                                                                                                            const currentDate = new Date(editedRecord.exitTime);
                                                                                                            const newTime = time.toDate();
                                                                                                            currentDate.setHours(newTime.getHours());
                                                                                                            currentDate.setMinutes(newTime.getMinutes());
                                                                                                            setEditedRecord({
                                                                                                                ...editedRecord,
                                                                                                                exitTime: currentDate.toISOString()
                                                                                                            });
                                                                                                        }
                                                                                                    }}
                                                                                                    minuteStep={1}
                                                                                                    showNow={false}
                                                                                                    allowClear={false}
                                                                                                    placeholder={t('employeesDetail.selectTime')}
                                                                                                    popupClassName="time-picker-popup"
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <style>
                                                                                {`
                                                                                    .time-picker-container {
                                                                                        transition: all 0.3s;
                                                                                        background: linear-gradient(145deg, #f8f9fa, #ffffff);
                                                                                    }
                                                                                    .time-picker-container:hover {
                                                                                        transform: translateY(-2px);
                                                                                        box-shadow: 0 8px 24px rgba(0,0,0,0.08);
                                                                                    }
                                                                                    .time-card {
                                                                                        transition: all 0.3s;
                                                                                        height: 100%;
                                                                                        background: #ffffff;
                                                                                        border: 1px solid rgba(0,0,0,0.05);
                                                                                    }
                                                                                    .time-card:hover {
                                                                                        transform: translateY(-2px);
                                                                                        box-shadow: 0 4px 16px rgba(0,0,0,0.06);
                                                                                        border-color: rgba(67, 97, 238, 0.2);
                                                                                    }
                                                                                    .time-header {
                                                                                        transition: all 0.3s;
                                                                                    }
                                                                                    .time-icon {
                                                                                        transition: all 0.3s;
                                                                                    }
                                                                                    .time-card:hover .time-icon {
                                                                                        transform: scale(1.05);
                                                                                    }
                                                                                    .time-picker-input {
                                                                                        width: 100%;
                                                                                        height: 48px;
                                                                                        font-size: 1.1rem;
                                                                                        border-radius: 8px;
                                                                                        border: 2px solid #e9ecef;
                                                                                        transition: all 0.3s;
                                                                                    }
                                                                                    .time-picker-input:hover {
                                                                                        border-color: #4361ee;
                                                                                    }
                                                                                    .time-picker-input:focus {
                                                                                        border-color: #4361ee;
                                                                                        box-shadow: 0 0 0 4px rgba(67, 97, 238, 0.1);
                                                                                    }
                                                                                    .time-picker-popup .ant-picker-panel {
                                                                                        border-radius: 8px;
                                                                                    }
                                                                                    .time-picker-popup .ant-picker-time-panel-column {
                                                                                        scrollbar-width: thin;
                                                                                        scrollbar-color: #4361ee #f8f9fa;
                                                                                    }
                                                                                    .time-picker-popup .ant-picker-time-panel-column::-webkit-scrollbar {
                                                                                        width: 6px;
                                                                                    }
                                                                                    .time-picker-popup .ant-picker-time-panel-column::-webkit-scrollbar-track {
                                                                                        background: #f8f9fa;
                                                                                        border-radius: 3px;
                                                                                    }
                                                                                    .time-picker-popup .ant-picker-time-panel-column::-webkit-scrollbar-thumb {
                                                                                        background-color: #4361ee;
                                                                                        border-radius: 3px;
                                                                                    }
                                                                                    .ant-picker-ok {
                                                                                        margin-top: 8px;
                                                                                    }
                                                                                    .ant-picker-ok button {
                                                                                        background-color: #4361ee;
                                                                                        border-color: #4361ee;
                                                                                    }
                                                                                    .ant-picker-ok button:hover {
                                                                                        background-color: #3651d4;
                                                                                        border-color: #3651d4;
                                                                                    }
                                                                                `}
                                                                            </style>
                                                                        </div>
                                                                    </Modal>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="text-center" style={{ marginBottom: "20px" }}>
                                                            <Link to={`/user-attendance?user=${userData.id}`} className="">
                                                                {t('employeesDetail.viewAll')}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <div className="row">
                                                        <div className="accordion-header" id="headingFour">
                                                            <div className="accordion-button">
                                                                <div className="d-flex align-items-center justify-content-between flex-fill">
                                                                    <h5>{t('employeesDetail.requests')}</h5>
                                                                    <div className="d-flex">
                                                                        
                                                                        <Link
                                                                            to="#"
                                                                            className="d-flex align-items-center collapsed collapse-arrow"
                                                                            data-bs-toggle="collapse"
                                                                            data-bs-target="#primaryBorderFour"
                                                                            aria-expanded="false"
                                                                            aria-controls="primaryBorderFour"
                                                                        >
                                                                            <i className="ti ti-chevron-down fs-18" />
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            id="primaryBorderFour"
                                                            className="accordion-collapse collapse border-top"
                                                            aria-labelledby="headingFour"
                                                            data-bs-parent="#accordionExample"
                                                        >
                                                            <div className="accordion-body">
                                                                <div className="row">
                                                                    {tickets.map((ticket) => (

                                                                      <TicketCards
                                                                          key={ticket.id}
                                                                          ticketid={ticket.id}
                                                                          ticketmaintitle={ticket.title}
                                                                          ticketdescription={ticket.description}
                                                                          ticketstatus={ticket.status}
                                                                          ticketdate={ticket.createdAt}
                                                                          ticketuser={userData}
                                                                          onTicketUpdate={fetchTickets}
                                                                      />
                                                                    ))}
                                                                </div>
                                                                <div className="text-center">
                                                                    <Link to={`/user-tickets?user=${userData.id}`} className="">
                                                                        {t('employeesDetail.viewAll')}
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <div className="row">
                                                        <div className="accordion-header" id="headingFive">
                                                            <div className="accordion-button collapsed">
                                                                <div className="d-flex align-items-center justify-content-between flex-fill">
                                                                    <h5>{t('employeesDetail.salaryChangeReport')}</h5>
                                                                    <div className="d-flex">
                                                                        <Link
                                                                            to="#"
                                                                            className="btn btn-icon btn-sm"
                                                                            onClick={() => setIsUpdateSalaryModalVisible(true)}
                                                                        >
                                                                            <i className="ti ti-edit" />
                                                                        </Link>
                                                                        <Link
                                                                            to="#"
                                                                            className="d-flex align-items-center collapsed collapse-arrow"
                                                                            data-bs-toggle="collapse"
                                                                            data-bs-target="#primaryBorderFive"
                                                                            aria-expanded="false"
                                                                            aria-controls="primaryBorderFive"
                                                                        >
                                                                            <i className="ti ti-chevron-down fs-18" />
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            id="primaryBorderFive"
                                                            className="accordion-collapse collapse border-top"
                                                            aria-labelledby="headingFive"
                                                            data-bs-parent="#accordionExample"
                                                        >
                                                            <div className="accordion-body">
                                                                <ReactApexChart
                                                                    options={sLineArea}
                                                                    series={sLineArea.series}
                                                                    type="line"
                                                                    height={350}
                                                                />


                                                                {/* <div>
                                                                    <p>Work in Progress...</p>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="footer d-sm-flex align-items-center justify-content-between border-top bg-white p-3">
                            <FooterSection />
                        </div>

                  

                        {/* Maaş Güncelleme Modalı */}
                        <Modal
                            title={
                                <div className="d-flex align-items-center">
                                    <i className="ti ti-currency-dollar fs-4 me-2 text-primary"></i>
                                    <span>{t('employeesDetail.updateSalary')}</span>
                                </div>
                            }
                            open={isUpdateSalaryModalVisible}
                            onCancel={() => {
                                setIsUpdateSalaryModalVisible(false);
                                setNewSalaryAmount(null);
                            }}
                            footer={[
                                <Button 
                                    key="cancel" 
                                    onClick={() => {
                                        setIsUpdateSalaryModalVisible(false);
                                        setNewSalaryAmount(null);
                                    }}
                                >
                                    {t('employeesDetail.cancel')}
                                </Button>,
                                <Button 
                                    key="submit" 
                                    type="primary" 
                                    loading={isUpdatingSalary}
                                    onClick={handleUpdateSalary}
                                    disabled={!newSalaryAmount || newSalaryAmount <= 0}
                                >
                                    {t('employeesDetail.update')}
                                </Button>
                            ]}
                            width={500}
                        >
                            <div className="salary-update-form">
                                <div className="current-salary mb-4 p-4 bg-light rounded-3 border">
                                    <h6 className="text-muted mb-3">{t('employeesDetail.currentSalary')}</h6>
                                    <h3 className="d-flex align-items-center mb-0">
                                        <span className="currency-symbol me-2">{currencies.find(c => c.value === selectedCurrency)?.symbol}</span>
                                        <span className="fw-bold">
                                            {salaries.length > 0 ? salaries.find(salary => salary.isActive)?.amount.toLocaleString('tr-TR') : '0'}
                                        </span>
                                    </h3>
                                </div>

                                <div className="new-salary">
                                    <h6 className="text-muted mb-3">{t('employeesDetail.newSalary')}</h6>
                                    <div className="row g-3">
                                        <div className="col-md-4">
                                            <label className="form-label small text-muted">{t('employeesDetail.currency')}</label>
                                            <select 
                                                className="form-select"
                                                value={selectedCurrency}
                                                onChange={(e) => setSelectedCurrency(e.target.value)}
                                            >
                                                {currencies.map(currency => (
                                                    <option key={currency.value} value={currency.value}>
                                                        {currency.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-md-8">
                                            <label className="form-label small text-muted">{t('employeesDetail.newSalaryAmount')}</label>
                                            <div className="input-group">
                                                <span className="input-group-text">
                                                    {currencies.find(c => c.value === selectedCurrency)?.symbol}
                                                </span>
                                                <input
                                                    type="number"
                                                    className="form-control form-control-lg"
                                                    placeholder="0.00"
                                                    value={newSalaryAmount || ''}
                                                    onChange={(e) => setNewSalaryAmount(Number(e.target.value))}
                                                    min="0"
                                                    step="0.01"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>

                        {/* Onay Modalı */}
                        <Modal
                            title={
                                <div className="d-flex align-items-center">
                                    <i className="ti ti-alert-triangle fs-4 me-2 text-warning"></i>
                                    <span>{t('employeesDetail.salaryChangeConfirmation')}</span>
                                </div>
                            }
                            open={isConfirmModalVisible}
                            onCancel={() => setIsConfirmModalVisible(false)}
                            footer={[
                                <Button 
                                    key="cancel" 
                                    onClick={() => setIsConfirmModalVisible(false)}
                                >
                                    {t('employeesDetail.noSalaryChange')}
                                </Button>,
                                <Button 
                                    key="submit" 
                                    type="primary" 
                                    loading={isUpdatingSalary}
                                    onClick={handleConfirmUpdate}
                                >
                                    {t('employeesDetail.yesSalaryChange')}
                                </Button>
                            ]}
                            width={500}
                        >
                            {calculateSalaryChange() && (
                                <div className="confirmation-content">
                                    <div className="alert alert-warning">
                                        <div className="d-flex align-items-center mb-3">
                                            <i className="ti ti-info-circle fs-4 me-2"></i>
                                            <span className="fw-medium">{t('employeesDetail.salaryChangeConfirmation')}</span>
                                        </div>
                                        <p className="mb-2">
                                            {t('employeesDetail.salaryIs')}
                                            <strong>{currencies.find(c => c.value === selectedCurrency)?.symbol}{salaries[salaries.length - 1].amount.toLocaleString('tr-TR')}</strong>{' '}
                                            {t('employeesDetail.to')}
                                            <strong>{currencies.find(c => c.value === selectedCurrency)?.symbol}{newSalaryAmount?.toLocaleString('tr-TR')}</strong>{' '}
                                            {calculateSalaryChange()?.isIncrease ? t('employeesDetail.increase') : t('employeesDetail.decrease')} {t('employeesDetail.areYouSure')}
                                        </p>
                                        <div className="d-flex align-items-center mt-3">
                                            <span className={`badge p-2 ${
                                                calculateSalaryChange()?.isIncrease 
                                                ? 'bg-success-subtle text-success' 
                                                : 'bg-danger-subtle text-danger'
                                            }`}>
                                                <i className={`ti ti-trending-${calculateSalaryChange()?.isIncrease ? 'up' : 'down'} me-1`}></i>
                                                {calculateSalaryChange()?.isIncrease ? '+' : '-'}
                                                {Math.abs(Number(calculateSalaryChange()?.percentage))}%
                                            </span>
                                            <span className="mx-2 text-muted">•</span>
                                            <span className="text-muted">
                                                {t('employeesDetail.difference')}: {currencies.find(c => c.value === selectedCurrency)?.symbol}{calculateSalaryChange()?.difference}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Modal>

                        {/* AdminLeaveModal */}
                        <AdminLeaveModal
                            open={isLeaveModalVisible}
                            onClose={() => setIsLeaveModalVisible(false)}
                            onSubmit={handleLeaveSubmit}
                            selectedEmployeeId={Number(id)}
                        />
                    </>
                )}
            </div>
            <Modal
                title={
                <div className="d-flex align-items-center">
                    <h4 className="modal-title me-2 mb-0">
                        {t('employeesDetail.editEmployee')}
                    </h4>
                </div>
                }
                open={isModalOpen}
                onCancel={handleCancel}
                width={800}
                footer={[
                <button
                    key="cancel"
                    type="button"
                    className="btn btn-outline-light border me-2"
                    onClick={handleCancel}
                >
                    {t('employeesDetail.cancel')}
                </button>,
                <button
                    key="submit"
                    type="button"
                    onClick={handleFormSubmit}
                    className="btn btn-primary"
                >
                    {t('employeesDetail.save')}
                </button>
                ]}
            >
                {renderModalContent()}
            </Modal>
        </div>
    );
};

export default EmployeeDetails

export const TicketCard = ({ ticketid, ticketmaintitle, tickettitle, ticketstatus, ticketpriority, ticketdate }:
    { ticketid: number, ticketmaintitle: string, tickettitle: string, ticketstatus: string, ticketpriority: string, ticketdate: string }) => {

    const getStatusBadgeClass = (status: string) => {
        switch (status) {
            case "Açık":
                return "badge bg-outline-pink"; // Pembe
            case "Beklemede":
                return "badge bg-outline-warning"; // Sarı
            case "Çözümlendi":
                return "badge bg-outline-purple"; // Mor
            default:
                return "badge bg-outline-secondary"; // Gri
        }
    };

    const getPriorityBadgeClass = (priority: string) => {
        switch (priority) {
            case "Yüksek":
                return "badge bg-danger"; // Kırmızı
            case "Orta":
                return "badge bg-warning"; // Sarı
            case "Düşük":
                return "badge bg-success"; // Yeşil
            default:
                return "badge bg-secondary"; // Gri
        }
    };

    return (
        <div className="card p-3">
            <div className="d-flex justify-content-between align-items-center">
                <h6 className="text-info fw-medium mb-0">{ticketmaintitle}</h6>
                <span className={`badge ${getPriorityBadgeClass(ticketpriority)} d-inline-flex align-items-center`}>
                    <i className="ti ti-circle-filled fs-6 me-1" />
                    {ticketpriority}
                </span>
            </div>

            <div className="d-flex justify-content-between align-items-center mt-2">
                <Link to={`/tickets/ticket-details/${ticketid}`} className="fw-semibold text-dark">
                    {tickettitle}
                </Link>
                <span className={`badge ${getStatusBadgeClass(ticketstatus)} d-inline-flex align-items-center`}>
                    <i className="ti ti-circle-filled fs-6 me-1" /> {ticketstatus}
                </span>
            </div>

            <div className="d-flex justify-content-between align-items-center text-muted mt-2">
                <span className="badge badge-info rounded-pill">BIL - {ticketid}</span>
                <small>
                    <i className="ti ti-calendar-bolt me-1" /> {ticketdate}
                </small>
            </div>
        </div>
    );
};
